import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  ChangeEvent,
} from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Bubble, Item } from 'src/lib/types'
import { toast } from 'react-toastify'
import itemsService from 'src/services/Items/items'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { Separator } from '../ui/separator'
import { List, ListItem } from '../ui/list'
import { Edit, Plus } from 'lucide-react'
import { iconAndTextStyling } from 'src/constants'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useAppContext } from 'src/context/AppProvider'
import { useConfirmDialogUnStableTarget } from 'src/components/ui/confirmDialog'
import {
  validationSchemaAddItem,
  validationSchemaUpdateItem,
} from 'src/lib/validationSchemas'

interface Props {
  bubble?: Bubble
  setItems: Dispatch<SetStateAction<Item[]>>
  item?: Item
  mapLocationId?: string
}

interface FormValues {
  name: string
  amount: number
  unit: string
  category: string
}

const AddOrUpdateItemDialog = ({
  bubble,
  setItems,
  item,
  mapLocationId,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { currentProject } = useAppContext()
  const projectId = currentProject.id
  const { prompt, result, reset } = useConfirmDialogUnStableTarget()
  const [pendingDeletion, setPendingDeletion] = useState<FormValues | null>(
    null
  )

  useEffect(() => {
    if (result && pendingDeletion) {
      handleDeleteItem(pendingDeletion)
      reset()
    }
  }, [result, pendingDeletion, reset])

  const handleDeleteItem = async (formValues: FormValues) => {
    try {
      const deletedItemId = await itemsService.markItemOutOfStockById(
        item!.id,
        projectId!
      )
      if (deletedItemId) {
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) => oldItems.filter((i) => i.id !== deletedItemId))
        setDialogOpen(false)
        setPendingDeletion(null)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleItemSubmit = async (values: FormValues, actions: any) => {
    if (!projectId) {
      toast.error(i18n.t('noProjectDefined'))
      return
    }
    try {
      if (item) {
        // update the item
        if (values.amount === 0) {
          setPendingDeletion(values)
          prompt({
            title: i18n.t('confirmDeleteItemAsAmountIsZeroTitle'),
            text: i18n.t('confirmDeleteItemAsAmountIsZero'),
          })
        } else {
          const itemLocation = item.location_title // save location
          const updatedItem = await itemsService.updateItemById(
            {
              ...item,
              name: values.name,
              amount: values.amount,
              category:
                values.category === 'null' || values.category === ''
                  ? undefined
                  : values.category,
              unit: values.unit,
            },
            projectId
          )
          if (updatedItem) {
            toast.success(i18n.t('successGeneric'))
            updatedItem.location_title = itemLocation
            setItems((oldItems) =>
              oldItems.map((o) => (o.id === updatedItem.id ? updatedItem : o))
            )
            setNameQuery('')
            setUnitQuery('')
            setCategoryQuery('')
            setDialogOpen(false)
          }
        }
      } else {
        // Add new item
        if (!mapLocationId) {
          toast.error(i18n.t('errorNoItemLocationProvided'))
          return
        }
        let newItem
        if (bubble) {
          // add item to bubble
          newItem = await itemsService.addNewItem(
            {
              ...values,
              category:
                values.category === 'null' || values.category === ''
                  ? undefined
                  : values.category,
              bubble_id: bubble.id,
            },
            projectId,
            mapLocationId
          )
        } else {
          // Add item to map location
          newItem = await itemsService.addNewItem(
            {
              ...values,
              category:
                values.category === 'null' || values.category === ''
                  ? undefined
                  : values.category,
            },
            projectId,
            mapLocationId
          )
        }
        if (newItem) {
          toast.success(i18n.t('successGeneric'))
          setItems((oldItems) => oldItems.concat(newItem))
          actions.resetForm()
          setNameQuery('')
          setUnitQuery('')
          setCategoryQuery('')
          setDialogOpen(false)
        }
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])
  const [selectableItemNames, setSelectableItemNames] = useState<string[]>([])

  const [nameQuery, setNameQuery] = useState('')
  const [filteredNames, setFilteredNames] = useState<string[]>([])
  const [isNameDropdownOpen, setIsNameDropdownOpen] = useState(false)

  const [unitQuery, setUnitQuery] = useState('')
  const [filteredUnits, setFilteredUnits] = useState<string[]>([])
  const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false)

  const [categoryQuery, setCategoryQuery] = useState('')
  const [filteredCategories, setFilteredCategories] = useState<string[]>([])
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)

  const [itemReservedAmount, setItemReservedAmount] = useState<number>(0)
  const [itemAvailableAmount, setItemAvailableAmount] = useState<number>(0)

  const fetchItemUtils = async () => {
    try {
      const [categories, units, itemNames] = await Promise.all([
        itemsService.getCategoriesByProjectId(projectId),
        itemsService.getUnitsByProjectId(projectId),
        itemsService.getItemNamesByProjectId(projectId),
      ])
      if (item) {
        const responseItemAmounts = await itemsService.getItemAmountInfoById(
          item.id,
          projectId
        )
        if (responseItemAmounts.status === 200) {
          const { reserved_amount, available_amount } = responseItemAmounts.data
          setItemReservedAmount(reserved_amount)
          setItemAvailableAmount(available_amount)
        }
      }
      setSelectableCategories(categories)
      setSelectableUnits(units)
      setSelectableItemNames(itemNames)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // Initial values for fields when an existing item is modfied
    if (item?.name) {
      setNameQuery(item.name)
    }
    if (item?.unit) {
      setUnitQuery(item.unit)
    }
    if (item?.category) {
      setCategoryQuery(item.category)
    }
  }, [])

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setNameQuery(query)
    const filtered = selectableItemNames.filter((name) =>
      name.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredNames(filtered)
    setIsNameDropdownOpen(filtered.length > 0)
  }

  const handleUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setUnitQuery(query)
    const filtered = selectableUnits.filter((unit) =>
      unit.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredUnits(filtered)
    setIsUnitDropdownOpen(filtered.length > 0)
  }

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setCategoryQuery(query)
    const filtered = selectableCategories.filter((category) =>
      category.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredCategories(filtered)
    setIsCategoryDropdownOpen(filtered.length > 0)
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
          await fetchItemUtils()
        } else {
          setDialogOpen(false)
          setNameQuery('')
          setCategoryQuery('')
          setUnitQuery('')
        }
      }}
    >
      <DialogTrigger asChild>
        <Button className={iconAndTextStyling}>
          {item ? (
            <Edit />
          ) : (
            <>
              <Plus /> {i18n.t('addNewItem')}
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {item
              ? `${i18n.t('modifyItem')} ${item.name}`
              : `${i18n.t('addNewItem')} ${bubble?.title ?? ''}`}
          </DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={{
            name: item?.name ?? '',
            amount: item?.amount ?? 0,
            unit: item?.unit ?? '',
            category: item?.category ?? '',
          }}
          validationSchema={
            item
              ? () => validationSchemaUpdateItem(itemReservedAmount)
              : validationSchemaAddItem
          }
          onSubmit={handleItemSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col gap-3 py-4">
              <div className="relative">
                <Label htmlFor="name">{i18n.t('itemName')}</Label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  autoComplete="off"
                  value={nameQuery}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleNameChange(e)
                    setFieldValue('name', e.target.value)
                  }}
                  onBlur={() => setIsNameDropdownOpen(false)}
                  placeholder={i18n.t('itemName')}
                  as={Input}
                />
                {isNameDropdownOpen && (
                  <List>
                    {filteredNames.map((name) => (
                      <ListItem
                        key={name}
                        onMouseDown={() => {
                          setNameQuery(name)
                          setFieldValue('name', name)
                          setIsNameDropdownOpen(false)
                        }}
                      >
                        {name}
                      </ListItem>
                    ))}
                  </List>
                )}
                <FormErrorMessage name="name" />
              </div>
              <div className="relative">
                <Label htmlFor="amount">{i18n.t('amount')} </Label>
                {item && (
                  <div className="text-gray-500">
                    <p>
                      {i18n.t('availableItems')}: {itemAvailableAmount}
                    </p>
                    <p>
                      {i18n.t('reservedItems')}: {itemReservedAmount}
                    </p>
                  </div>
                )}
                <Field
                  type="number"
                  id="amount"
                  name="amount"
                  min={item ? itemReservedAmount : 0}
                  step="any"
                  autoComplete="off"
                  placeholder={i18n.t('amount')}
                  as={Input}
                />
                <FormErrorMessage name="amount" />
              </div>
              <div className="relative">
                <Label htmlFor="unit">{i18n.t('unit')}</Label>
                <Field
                  type="text"
                  id="unit"
                  name="unit"
                  autoComplete="off"
                  value={unitQuery}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleUnitChange(e)
                    setFieldValue('unit', e.target.value)
                  }}
                  onBlur={() => setIsUnitDropdownOpen(false)}
                  placeholder={i18n.t('unit')}
                  as={Input}
                />
                {isUnitDropdownOpen && (
                  <List>
                    {filteredUnits.map((unit) => (
                      <ListItem
                        key={unit}
                        onMouseDown={() => {
                          setUnitQuery(unit)
                          setFieldValue('unit', unit)
                          setIsUnitDropdownOpen(false)
                        }}
                      >
                        {unit}
                      </ListItem>
                    ))}
                  </List>
                )}
                <FormErrorMessage name="unit" />
              </div>

              <div className="relative">
                <Label htmlFor="category">{i18n.t('category')}</Label>
                <Field
                  type="text"
                  id="category"
                  name="category"
                  autoComplete="off"
                  value={categoryQuery}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleCategoryChange(e)
                    setFieldValue('category', e.target.value)
                  }}
                  onBlur={() => setIsCategoryDropdownOpen(false)}
                  placeholder={i18n.t('category')}
                  as={Input}
                />
                {isCategoryDropdownOpen && (
                  <List>
                    {filteredCategories.map((category) => (
                      <ListItem
                        key={category}
                        onMouseDown={() => {
                          setCategoryQuery(category)
                          setFieldValue('category', category)
                          setIsCategoryDropdownOpen(false)
                        }}
                      >
                        {category}
                      </ListItem>
                    ))}
                  </List>
                )}
                <FormErrorMessage name="category" />
              </div>
              <Separator className="my-2" />
              <PendingSubmitButton
                buttonText={i18n.t('save')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateItemDialog
