import axios from 'axios'
import { clsx, type ClassValue } from 'clsx'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import { twMerge } from 'tailwind-merge'
import { errorMessageHandler } from './errorMessageHandler'
import {
  CurrentUserState,
  ForeignUserData,
  Project,
  UserCustomershipRoles,
  UserProjectRoles,
} from './types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const generatePagination = (currentPage: number, totalPages: number) => {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, '...', totalPages - 1, totalPages]
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages]
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [
    1,
    '...',
    currentPage - 1,
    currentPage,
    currentPage + 1,
    '...',
    totalPages,
  ]
}

export const displayResponseErrorMessage = (error: any) => {
  if (axios.isAxiosError(error) && error.response) {
    const apiErrorResponse = error.response.data
    const errorMessage = errorMessageHandler(apiErrorResponse.message)
    toast.error(errorMessage)
  } else {
    toast.error(i18n.t('errorGeneric'))
  }
}

export const currentUserDataIsDefined = (
  currentUser: CurrentUserState
): currentUser is CurrentUserState & {
  userData: { email: string; language: string }
} => {
  return (
    !!currentUser.userData &&
    typeof currentUser.userData.email === 'string' &&
    typeof currentUser.userData.language === 'string'
  )
}

export const currentUserDataWithProjectRoleIsDefined = (
  currentUser: CurrentUserState
): currentUser is CurrentUserState & { project_role: UserProjectRoles } => {
  return (
    currentUserDataIsDefined(currentUser) &&
    currentUser.project_role !== undefined &&
    Object.values(UserProjectRoles).includes(currentUser.project_role)
  )
}

export const currentUserDataWithCustomershipRoleIsDefined = (
  currentUser: CurrentUserState
): currentUser is CurrentUserState & {
  customership_role: UserCustomershipRoles
} => {
  return (
    currentUserDataIsDefined(currentUser) &&
    currentUser.customership_role !== undefined &&
    Object.values(UserCustomershipRoles).includes(currentUser.customership_role)
  )
}

export const hasProjectMemberRights = (currentUser: CurrentUserState) => {
  const role = currentUser.project_role
  return (
    role === UserProjectRoles.MEMBER ||
    role === UserProjectRoles.MODERATOR ||
    role === UserProjectRoles.ADMIN
  )
}

export const hasProjectModeratorRights = (currentUser: CurrentUserState) => {
  const role = currentUser.project_role
  return role === UserProjectRoles.ADMIN || role === UserProjectRoles.MODERATOR
}

export const hasProjectAdminRights = (currentUser: CurrentUserState) => {
  const role = currentUser.project_role
  return role === UserProjectRoles.ADMIN
}

export const hasCustomershipMemberRights = (currentUser: CurrentUserState) => {
  const role = currentUser.customership_role
  return (
    role === UserCustomershipRoles.MEMBER ||
    role === UserCustomershipRoles.ADMIN
  )
}

export const hasCustomershipAdminRights = (currentUser: CurrentUserState) => {
  const role = currentUser.customership_role
  return role === UserCustomershipRoles.ADMIN
}

export const getUserCustomershipRoleAsText = (user: ForeignUserData) => {
  switch (user.customership_role) {
    case UserCustomershipRoles.ADMIN:
      return i18n.t('userLevels.adminUser')
    case UserCustomershipRoles.MEMBER:
      return i18n.t('userLevels.memberUser')
    default:
      return i18n.t('userLevels.memberUser')
  }
}

export const isProjectActive = (project: Project) => {
  // Get the current date as UTC time
  const currentUtcDate = dayjs().utc().startOf('day')
  // Note: project.project_end_date is already in UTC timezone in the db so no need to .utc() here
  const projectEndDate = project.project_end_date
    ? dayjs(project.project_end_date).startOf('day')
    : null

  if (!projectEndDate || projectEndDate.diff(currentUtcDate, 'day') >= 0) {
    return true
  } else {
    return false
  }
}

export const formatDate = (dateInput: Date): string => {
  const date = new Date(dateInput)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
}

export const formatDateLocaleExact = (
  dateInput: string,
  currentUser: CurrentUserState
): string => {
  let userLanguage = currentUser?.userData?.language || 'en-US'

  // Convert the locale string to the correct format
  if (userLanguage === 'en_us') userLanguage = 'en-US'
  if (userLanguage === 'fi_fi') userLanguage = 'fi-FI'

  const clock = userLanguage === 'fi-FI' ? 'h23' : 'h12'

  const date = new Date(dateInput + 'Z')

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: clock, // 12-hour format if English, 24-hour format otherwise
  }

  const formattedDate = date.toLocaleString(userLanguage, options)

  return formattedDate
}

export const formatDateAsFinnishLocale = (dateInput: Date): string => {
  const date = new Date(dateInput.toString() + 'Z')

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  }

  const formattedDate = date.toLocaleString('fi-FI', options)

  return formattedDate
}

export const formatDateLocale = (dateInput: Date): string => {
  const date = new Date(dateInput)
  return date.toLocaleDateString()
}

export const allowProjectEnd = (project: Project): boolean => {
  if (!project.project_end_date) {
    // project has not been ended yet
    return true
  } else if (isProjectActive(project)) {
    // project is still active but it has been terminated
    return true
  } else {
    return false
  }
}
