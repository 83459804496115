import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button } from 'src/components/ui/button'
import { toast } from 'react-toastify'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table'
import i18n from 'src/i18n'
import { Invitation, Project } from 'src/lib/types'
import { formatDateLocaleExact } from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import invitationsService from 'src/services/ProjectInvitations/projectInvitations'
import projectsService from 'src/services/Projects/projects'
import {
  Check,
  MailIcon,
  SquareArrowUpRight,
  UserRoundCog,
  X,
} from 'lucide-react'
import { iconAndTextStyling } from 'src/constants'
import ProjectCard from 'src/components/Projects/ProjectCard'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { useAppContext } from 'src/context/AppProvider'
import SearchProjects from 'src/components/Projects/SearchProjects'
import Pagination from 'src/components/Pagination'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import { Separator } from 'src/components/ui/separator'

const MainScreenView = () => {
  const [projects, setProjects] = useState<Project[]>([])
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const { currentUser } = useAppContext()
  const confirmDeletion = useConfirmaDialogStableTarget()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [totalResults, setTotalResults] = useState<number>(0)

  const resultsPerPage = 3

  const fetchData = async () => {
    try {
      // Fetch projects
      setLoadingProjects(true)
      const page = searchParams.get('page')
      const searchQuery = searchParams.get('search') ?? ''
      const response = await projectsService.searchUsersProjects({
        search: searchQuery,
        page: page ? parseInt(page) : 1,
        results_per_page: resultsPerPage,
      })

      if (response.status === 200) {
        const responseProjects = response.data.projects
        setProjects(responseProjects)
        setTotalResults(response.data.totalResults)
      }

      // Ensure invitation updates occur before fetching invitations
      const updated = await invitationsService.updateUserInvitations()

      if (updated) {
        // Only fetch pending invitations after successful update
        const pendingInvitations =
          await invitationsService.getPendingInvitations()
        setInvitations(pendingInvitations)
      } else {
        console.error('Failed to update invitations before fetching')
      }
    } catch (error) {
      console.error('Error fetching projects or invitations:', error)
    } finally {
      setLoadingProjects(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [searchParams])

  const handleAccept = async (invitationId: string) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('invitation.actions.confirmAccept'),
    })
    if (!confirmed) {
      return
    }
    const success = await invitationsService.acceptInvitation(invitationId) // Implement this function to call your API
    if (success) {
      setInvitations(
        invitations.filter((invitation) => invitation.id !== invitationId)
      )
      await fetchData()
      toast.success(i18n.t('invitation.responses.accepted'))
    } else {
      toast.error(i18n.t('invitation.responses.acceptedError'))
    }
  }

  const handleDecline = async (invitationId: string) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('invitation.actions.confirmDecline'),
    })
    if (!confirmed) {
      return
    }
    const success = await invitationsService.declineInvitation(invitationId) // Implement this function to call your API
    if (success) {
      setInvitations(
        invitations.filter((invitation) => invitation.id !== invitationId)
      )
      toast.success(i18n.t('invitation.responses.declined'))
    } else {
      toast.error(i18n.t('invitation.responses.declinedError'))
    }
  }

  return (
    <div
      className="mx-2 my-3 flex flex-col sm:max-h-[85vh]"
      data-testid="mainscreen-view"
    >
      <div className="flex justify-center">
        <div className="items-center w-full max-w-6xl">
          <div className="flex flex-row flex-wrap gap-2 justify-between w-full">
            <div>
              <h1>{i18n.t('projects')}</h1>
            </div>
            <Button
              data-testid="customer-portal-link-button"
              variant={'secondary'}
            >
              <Link
                to={i18n.t('paths.customershipPortal')}
                className={iconAndTextStyling}
              >
                <UserRoundCog /> {i18n.t('customershipPortal')}
              </Link>
            </Button>
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <button className="underline">
                {i18n.t('howToAddProject.title')}
              </button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{i18n.t('howToAddProject.title')}</DialogTitle>
                <Separator className="my-2" />
                <DialogDescription>
                  {i18n.t('howToAddProject.description')}
                </DialogDescription>
              </DialogHeader>
              <div className="flex flex-col gap-3 items-center">
                <a
                  href="https://www.gobosoft.fi/yhteystiedot"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-fit"
                >
                  <Button className={iconAndTextStyling}>
                    {i18n.t('howToAddProject.websiteContactForm')}
                    {<SquareArrowUpRight />}
                  </Button>
                </a>
                <div className="flex items-center my-4 w-full max-w-sm">
                  <div className="border-t border-gray-300 flex-grow"></div>
                  <span className="px-4">{i18n.t('OR')}</span>
                  <div className="border-t border-gray-300 flex-grow"></div>
                </div>
                <a
                  href={`mailto:${i18n.t('gobosoftSalesEmail')}`}
                  className="w-fit"
                >
                  <Button variant={'link'} className={iconAndTextStyling}>
                    {i18n.t('gobosoftSalesEmail')}
                    {<MailIcon />}
                  </Button>
                </a>
              </div>
            </DialogContent>
          </Dialog>
          <div className="my-3">
            <SearchProjects />
          </div>
          {loadingProjects ? (
            <SmallLoadingCircleOnly />
          ) : projects?.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {projects.map((project) => (
                  <div key={project.id}>
                    <ProjectCard project={project} />
                  </div>
                ))}
              </div>
              <div className="text-center mt-6 mb-2">
                <Pagination
                  totalPages={Math.ceil(totalResults / resultsPerPage)}
                />
              </div>
            </div>
          ) : (
            <div className="my-3">
              <p>{i18n.t('noProjectsFound')}</p>
            </div>
          )}
          <div className="mt-4 mb-2">
            <h1>{i18n.t('pendingProjectInvitations')}</h1>
            {invitations && invitations?.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[200px]">
                      {i18n.t('invitation.tableHeaders.project')}
                    </TableHead>
                    <TableHead className="w-[100px]">
                      {i18n.t('invitation.tableHeaders.sent')}
                    </TableHead>
                    <TableHead className="w-[100px]">
                      {i18n.t('invitation.tableHeaders.actions')}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {invitations.map((invitation, index) => (
                    <TableRow
                      key={index}
                      data-testid={`project-invitations-table-row-${invitation.project_name}`}
                    >
                      <TableCell>
                        <p>{invitation.project_name}</p>
                      </TableCell>
                      <TableCell>
                        <p>
                          {formatDateLocaleExact(invitation.sent, currentUser)}
                        </p>
                      </TableCell>
                      <TableCell className="flex gap-1">
                        <Button
                          data-testid={`accept-project-invitation-${invitation.project_name}`}
                          onClick={() => handleAccept(invitation.id)}
                          title={i18n.t('invitation.actions.accept')}
                        >
                          <Check />
                        </Button>
                        <Button
                          data-testid={`decline-project-invitation-${invitation.project_name}`}
                          onClick={() => handleDecline(invitation.id)}
                          title={i18n.t('invitation.actions.decline')}
                        >
                          <X />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="my-3">
                <p>{i18n.t('noPendingInvitations')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainScreenView
