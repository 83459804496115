import SearchItems from 'src/components/Items/SearchItems'
import { useAppContext } from 'src/context/AppProvider'
import MapViewer from 'src/components/MapViewer/MapViewer'
import { useEffect, useState } from 'react'
import { MapLocation } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'

const SingleProjectView = () => {
  const { currentUser, currentProject } = useAppContext()

  const [locations, setLocations] = useState<MapLocation[]>([])
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(
    null
  )

  const currentUserRole = currentUser.project_role

  if (!currentUserRole) {
    return <div>Error: No user</div>
  }

  useEffect(() => {
    const fetchMapLocations = async () => {
      try {
        const mapLocationsResponse =
          await mapLocationsService.getAllMapLocationsByProjectId(
            currentProject.id
          )
        if (mapLocationsResponse.status === 200) {
          setLocations(mapLocationsResponse.data)
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      }
    }

    fetchMapLocations()
  }, [])

  return (
    <div
      className="md:h-[85vh] mx-2 flex flex-col"
      data-testid="single-project-main-view"
    >
      <div
        className="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2 w-full mt-1"
        data-testid="single-project-main-info-container"
      >
        <div className="sm:col-span-1 flex flex-row gap-2 flex-wrap">
          <div>
            <h2>{currentProject.name}</h2>
          </div>
        </div>
        <div className="sm:col-span-1">
          <SearchItems />
        </div>
      </div>
      <MapViewer
        locations={locations}
        setLocations={setLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        allowConnectingLocations
        displayMapMoreButton
      />
    </div>
  )
}

export default SingleProjectView
