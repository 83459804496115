import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/location-connections'

export interface AddNewLocationData {
  items: {
    [key: string]: number
  }
  description: string
  from_location_id: string
  to_location_id: string
  date_and_time?: Date
}

const addNewLocationConnection = async (
  locationData: AddNewLocationData,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/projects/${project_id}/create-connection`,
    locationData
  )
  return response
}

const getAllMapLocationConnectionsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/projects/${project_id}/get-all-location-connections`
  )
  return response
}

const deleteLocationConnectionById = async (
  location_connection_id: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/${location_connection_id}/projects/${project_id}`
  )
  return response
}

const getLocationConnectionDataById = async (
  project_id: string,
  location_connection_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/${location_connection_id}/projects/${project_id}`
  )
  return response
}

const getLocationConnectionItemsByLocationConnectionId = async (
  project_id: string,
  location_connection_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/items`
  )
  return response
}

const markConnectionStatusComplete = async (
  location_connection_id: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/complete`
  )
  return response
}

const deleteLocationConnectionItems = async (
  location_connection_id: string,
  project_id: string,
  connection_item_ids: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/delete-location-connections`,
    {
      connection_item_ids,
    }
  )
  return response
}

const connectItemsToLocationConnection = async (
  location_connection_id: string,
  project_id: string,
  items: {
    [key: string]: number
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/connect-items`,
    {
      items,
    }
  )
  return response
}

interface UpdateLocationConnectionData {
  date_and_time?: Date
  description: string
}

const updateLocationConnectionDateAndDescription = async (
  location_connection_id: string,
  project_id: string,
  data: UpdateLocationConnectionData
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/update`,
    data
  )
  return response
}

const markItemsArrived = async (
  location_connection_id: string,
  project_id: string,
  connection_items: {
    [key: string]: number
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/items-arrived`,
    {
      connection_items,
    }
  )
  return response
}

const updateConnectionItem = async (
  location_connection_id: string,
  project_id: string,
  data: { connection_item_id: string; amount: number }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${location_connection_id}/projects/${project_id}/update-connection-item`,
    data
  )
  return response
}

export default {
  addNewLocationConnection,
  getAllMapLocationConnectionsByProjectId,
  deleteLocationConnectionById,
  getLocationConnectionDataById,
  getLocationConnectionItemsByLocationConnectionId,
  markConnectionStatusComplete,
  deleteLocationConnectionItems,
  connectItemsToLocationConnection,
  updateLocationConnectionDateAndDescription,
  markItemsArrived,
  updateConnectionItem,
}
