import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import ReactECharts from 'echarts-for-react'
import { useDebounce } from 'use-debounce'
import { InventoryFlowData } from 'src/lib/types'
import chartService from 'src/services/Data/chartService'
import itemService from 'src/services/Items/items'
import { Label } from '../ui/label'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { useAppContext } from 'src/context/AppProvider'
import { Input } from '../ui/input'
import { List, ListItem } from '../ui/list'
import { Checkbox } from 'src/components/ui/checkbox'

const getFormattedDate = (date: Date) => date.toISOString().split('T')[0]

const generateColors = (count: number) => {
  const baseColors = [
    '#3498DB',
    '#E74C3C',
    '#2ECC71',
    '#F39C12',
    '#9B59B6',
    '#1ABC9C',
  ]
  return Array.from(
    { length: count },
    (_, i) => baseColors[i % baseColors.length]
  )
}

const shiftTimestampIfFinnish = (dateStr: string, currentUser: any): string => {
  const isFinnish = currentUser?.userData?.language === 'fi_fi'
  const date = new Date(dateStr)
  if (isNaN(date.getTime())) {
    throw new Error(
      `Invalid date passed to shiftTimestampIfFinnish: ${dateStr}`
    )
  }
  if (isFinnish) {
    date.setHours(date.getHours() + 3)
  }
  return date.toISOString()
}

const getEndOfDayISOString = (dateStr: string): string => {
  if (!dateStr)
    throw new Error('Invalid endDate passed to getEndOfDayISOString')

  const [year, month, day] = dateStr.split('-').map(Number)
  if (!year || !month || !day)
    throw new Error('Invalid date format for getEndOfDayISOString')

  const date = new Date(Date.UTC(year, month - 1, day, 23, 59, 59, 999))
  return date.toISOString()
}

const InventoryFlowChart: React.FC = () => {
  const { currentProject, currentUser } = useAppContext()
  const isUTCPlus3 = currentUser?.userData?.language === 'fi_fi'

  const [data, setData] = useState<InventoryFlowData[]>([])
  const [loading, setLoading] = useState(false)
  const [wholeProject, setWholeProject] = useState(false)
  const [startDate, setStartDate] = useState(
    getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 7)))
  )
  const [endDate, setEndDate] = useState(getFormattedDate(new Date()))
  const [tempStartDate, setTempStartDate] = useState(startDate)
  const [tempEndDate, setTempEndDate] = useState(endDate)
  const [debouncedStartDate] = useDebounce(tempStartDate, 500)
  const [debouncedEndDate] = useDebounce(tempEndDate, 500)
  const [itemName, setItemName] = useState<string | null>(null)
  const [nameQuery, setNameQuery] = useState('')
  const [filteredNames, setFilteredNames] = useState<string[]>([])
  const [allNames, setAllNames] = useState<string[]>([])
  const [isNameDropdownOpen, setIsNameDropdownOpen] = useState(false)
  const [chartKey, setChartKey] = useState(0)

  useEffect(() => {
    if (wholeProject && currentProject?.created_at) {
      setStartDate(getFormattedDate(new Date(currentProject.created_at)))
    } else {
      setStartDate(tempStartDate)
    }
  }, [wholeProject])

  useEffect(() => {
    setStartDate(debouncedStartDate)
    setEndDate(debouncedEndDate)
  }, [debouncedStartDate, debouncedEndDate])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const result = await chartService.getInventoryFlowData(
          startDate,
          endDate,
          currentProject.id
        )
        setData(result || [])
        if (result?.length > 0 && !itemName) {
          setItemName(result[0].name)
          setNameQuery(result[0].name)
        }
      } catch {
        toast.error(i18n.t('errorGeneric'))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [startDate, endDate])

  useEffect(() => {
    if (!currentProject?.id) return
    const fetchNames = async () => {
      try {
        const names = await itemService.getItemNamesByProjectId(
          currentProject.id
        )
        setAllNames(names || [])
        setFilteredNames(names || [])
      } catch {
        toast.error(i18n.t('errorGeneric'))
      }
    }
    fetchNames()
  }, [])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setNameQuery(query)
    const filtered = allNames.filter((name) =>
      name.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredNames(filtered)
    setIsNameDropdownOpen(filtered.length > 0)
  }

  const filteredData = useMemo(() => {
    return data.filter((item) => item.name === itemName)
  }, [data, itemName])

  const groupedData = useMemo(() => {
    const map: Record<string, [string, number][]> = {}

    const startTs = new Date(
      shiftTimestampIfFinnish(startDate, currentUser)
    ).getTime()
    const endTs = new Date(
      shiftTimestampIfFinnish(endDate, currentUser)
    ).setHours(23, 59, 59, 999)

    filteredData.forEach((item) => {
      if (!map[item.unit]) map[item.unit] = []
      const shifted = shiftTimestampIfFinnish(item.timestamp, currentUser)
      map[item.unit].push([shifted, item.inventory_level])
    })

    const extendedMap: Record<string, [string, number][]> = {}

    Object.entries(map).forEach(([unit, points]) => {
      const sorted = [...points].sort(
        (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
      )
      const extended: [string, number][] = []
      const localStartISO = shiftTimestampIfFinnish(startDate, currentUser)
      const localEndISO = shiftTimestampIfFinnish(
        getEndOfDayISOString(endDate),
        currentUser
      )
      const firstTime = new Date(sorted[0][0]).getTime()
      const firstVal = sorted[0][1]
      const lastVal = sorted[sorted.length - 1][1]
      if (firstTime > startTs) {
        extended.push([localStartISO, firstVal])
      }
      extended.push(...sorted)
      const hasPointAtOrAfterEnd = sorted.some(
        ([timestamp]) => new Date(timestamp).getTime() >= endTs
      )
      if (!hasPointAtOrAfterEnd) {
        extended.push([localEndISO, lastVal])
      }
      extendedMap[unit] = extended
    })

    return extendedMap
  }, [filteredData, startDate, endDate, currentUser])

  const uniqueUnits = Object.keys(groupedData)
  const hasTwoUnits = uniqueUnits.length === 2
  const colorPalette = generateColors(uniqueUnits.length)
  const legendLabels: string[] = []
  const seriesData = uniqueUnits.map((unit, index) => {
    legendLabels.push(unit)
    return {
      name: unit,
      type: 'line',
      yAxisIndex: hasTwoUnits ? index : 0,
      data: groupedData[unit],
      lineStyle: { width: 2, color: colorPalette[index] },
      itemStyle: { color: colorPalette[index] },
    }
  })

  useEffect(() => {
    if (itemName) {
      setChartKey((prev) => prev + 1)
    }
  }, [itemName])

  return (
    <div className="p-4 w-full">
      <div className="flex flex-wrap gap-4 mb-4 items-center">
        <Label className="flex items-center w-full sm:w-auto">
          <Checkbox
            checked={wholeProject}
            onCheckedChange={(checked) => setWholeProject(checked === true)}
          />
          <span className="ml-2">{i18n.t('data.wholeProject')}</span>
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.startDate')}
          </span>
          <input
            type="date"
            value={tempStartDate}
            max={
              tempEndDate
                ? getFormattedDate(
                    new Date(
                      new Date(tempEndDate).setDate(
                        new Date(tempEndDate).getDate() - 1
                      )
                    )
                  )
                : getFormattedDate(new Date())
            }
            onChange={(e) => setTempStartDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-[150px]"
          />
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.endDate')}
          </span>
          <input
            type="date"
            value={tempEndDate}
            min={
              tempStartDate
                ? getFormattedDate(
                    new Date(
                      new Date(tempStartDate).setDate(
                        new Date(tempStartDate).getDate() + 1
                      )
                    )
                  )
                : ''
            }
            max={getFormattedDate(new Date())}
            onChange={(e) => setTempEndDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-[150px]"
          />
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">{i18n.t('itemName')}</span>
          <div className="relative w-full sm:w-[200px] ml-2">
            <Input
              type="text"
              value={nameQuery}
              placeholder={i18n.t('itemName')}
              onChange={handleNameChange}
              onBlur={() => setTimeout(() => setIsNameDropdownOpen(false), 100)}
              className="border rounded p-2 w-full"
            />
            {isNameDropdownOpen && (
              <List className="absolute z-10 top-full left-0 bg-white border w-full shadow-lg max-h-60 overflow-y-auto rounded">
                {filteredNames.map((name) => (
                  <ListItem
                    key={name}
                    onMouseDown={() => {
                      setNameQuery(name)
                      setItemName(name)
                      setIsNameDropdownOpen(false)
                    }}
                    className="cursor-pointer hover:bg-gray-100 px-4 py-2"
                  >
                    {name}
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </Label>
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : !itemName ? (
        <p className="text-center text-gray-600">{i18n.t('data.selectItem')}</p>
      ) : seriesData.length === 0 ? (
        <p className="text-center text-gray-600">
          {i18n.t('data.noData')} "{itemName}".
        </p>
      ) : (
        <ReactECharts
          key={chartKey}
          option={{
            title: {
              text: `${i18n.t('data.flowChartTitle')} - ${itemName}`,
              left: 'center',
              textStyle: {
                fontSize: window.innerWidth < 600 ? 14 : 16,
                fontWeight: 'bold',
                overflow: 'breakWord',
                width: '80%',
              },
            },
            tooltip: { trigger: 'axis' },
            legend: { data: legendLabels, bottom: 0 },
            xAxis: {
              type: 'time',
              min: new Date(
                new Date(startDate).getTime() + (isUTCPlus3 ? 180 : 0) * 60000
              ).getTime(),
              max: new Date(
                new Date(endDate).setHours(23, 59, 59, 999) +
                  (isUTCPlus3 ? 180 : 0) * 60000
              ).getTime(),
            },
            yAxis: hasTwoUnits
              ? uniqueUnits.map((unit, i) => ({
                  type: 'value',
                  name: unit,
                  position: i === 0 ? 'left' : 'right',
                  alignTicks: true,
                }))
              : { type: 'value', name: uniqueUnits[0] },
            series: seriesData,
          }}
          style={{ width: '100%', minHeight: '500px' }}
        />
      )}
    </div>
  )
}

export default InventoryFlowChart
