export type Project = {
  id: string
  name: string
  temporary_contract_end_date: Date
  project_end_date?: Date
  extra?: string
  description?: string
  info_section?: string
  price: number
  max_blueprints: number
  created_at: Date
  latest_notification_check_at?: Date
}

export type ProjectDetails = {
  id: string
  customership_id: string
  max_blueprints: number
  created_at: Date
  updated_at: Date
}

export type Invitation = {
  project_name: string
  sent: string
  id: string
}

export type CustomershipInvitation = {
  company_name: string
  business_id: string
  sent: string
  id: string
}

export type Blueprint = {
  id: string
  file_key: string
  title: string
  project_id: string
  rotation: number
  map_location_id: string
}

export type Bubble = {
  id: string
  blueprint_id: string
  x: number
  y: number
  title: string
}

export type User = {
  id: string
  email: string
  phone?: string
  organization?: string
  language: string
  created_at?: Date
  updated_at?: Date
}

export type Item = {
  id: string
  bubble_id?: string
  map_location_id?: string
  name: string
  amount: number
  unit: string
  latest_editor_id?: string
  latest_editor_email?: string
  last_update_type: ItemLastUpdateType
  last_update_amount?: number
  location_title?: string
  updated_at: Date
  category?: string
  tags?: Tag[]
  reserved_amount?: number
  available_amount?: number
  additional_info_fields?: AdditionalInfoField[]
}

export interface AdditionalInfoField {
  id: string
  key: string
  value: string
  type: AdditionalInfoFieldType
}

export enum AdditionalInfoFieldType {
  STRING = 'string',
  LINK = 'link',
  MULTILINE_STRING = 'multiline_string',
}

export type Tag = {
  id: string
  key: string
  value?: string
}

export type OutOfStockItem = {
  id: string
  bubble_id?: string
  map_location_id?: string
  name: string
  amount: number
  unit: string
  latest_editor_id?: string
  latest_editor_email?: string
  location_title?: string
  updated_at: Date
  category?: string
}

export type WasteItem = {
  id: string
  name: string
  amount: number
  unit: string
  last_update_amount: number
  updated_at: Date
  project_id: string
  category?: string
  latest_editor_id?: string
  latest_editor_email?: string
}

export enum ItemLastUpdateType {
  CREATION = 'creation',
  INCREASE_AMOUNT = 'increase_amount',
  DECREASE_AMOUNT = 'decrease_amount',
  CHANGE_NAME = 'change_name',
  CHANGE_CATEGORY = 'change_category',
  CHANGE_UNIT = 'change_unit',
  ADD_WASTE = 'add_waste',
  MOVE = 'move',
}

export type SearchItemUtils = {
  categories: string[]
  units: string[]
  locations: string[]
  tag_keys: string[]
  tag_values: string[]
}

export type SearchItemResults = {
  items: Item[]
  totalResults: number
}

export type SearchWasteItemResults = {
  items: WasteItem[]
  totalResults: number
}

export type SearchOutOfStockItemResults = {
  items: OutOfStockItem[]
  totalResults: number
}

export interface CurrentUserState {
  userData: {
    email: string
    phone?: string
    language: string
  }
  project_role?: UserProjectRoles
  customership_role?: UserCustomershipRoles
}

export enum UserProjectRoles {
  MEMBER = 'member',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
}

export enum UserCustomershipRoles {
  MEMBER = 'member',
  ADMIN = 'admin',
}

export interface ForeignUserData {
  userData: {
    id: string
    email: string
    phone?: string
    organization?: string
  }
  project_role?: UserProjectRoles
  customership_role?: UserCustomershipRoles
}

export interface Customership {
  id: string
  company_name: string
  business_id: string
  einvoice_address: string
  operator_id: string
  postal_address: string
  email: string
  phone: string
  contact_person: string
  is_active: boolean
  reference_number?: string
  created_at?: Date
  updated_at?: Date
}

export interface InvitationStatus {
  email: string
  sent: string
  status: string
}

export interface InvitationStatusResponse {
  invitations: InvitationStatus[]
  error?: string
}

export interface InvitationResponse {
  success: boolean
  message: string
  one_or_more_email_failed: boolean
}

export enum ItemDisplayType {
  ITEMS = 'items',
  WASTE_ITEMS = 'waste_items',
  OUT_OF_STOCK_ITEMS = 'out_of_stock_items',
}

export interface ConfirmationDialogProps {
  title: string
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  target?: HTMLElement | null
}

export interface ImportedFile {
  id: string
  filename: string
  uploaded_at: Date
}

export interface TemplateItem {
  id: string
  name: string
  category?: string
  unit: string
}

export const EXPECTED_HEADERS: string[] = ['name', 'category', 'unit']

export interface MapLocation {
  id: string
  title: string
  lat: number
  lng: number
}

export enum MapViewerMode {
  NORMAL = 'NORMAL',
  ADDING_LOCATION_CONNECTION = 'ADDING_LOCATION_CONNECTION',
}

export interface Connection {
  from: MapLocation | null
  to: MapLocation | null
}

export interface LocationConnection {
  id: string
  map_location_from: MapLocation
  map_location_to: MapLocation
  description: string
  date_and_time?: Date
  completion_status: LocationConnectionCompletionStatus
}

export interface LocationConnectionPlain {
  id: string
  map_location_from_id: string
  map_location_to_id: string
  description: string
  date_and_time?: Date
  completion_status: LocationConnectionCompletionStatus
}

export interface LocationConnectionItem {
  id: string
  item_id: string
  item_available_amount: number
  item_reserved_amount: number
  name: string
  connection_amount: number
  item_amount: number
  unit: string
  category: string
  amount_arrived: number
  arrival_status: LocationConnectionItemArrivalStatus
}

export enum LocationConnectionItemArrivalStatus {
  ALL_ARRIVED = 'arrived',
  SOME_ARRIVED = 'some_arrived',
  NONE_ARRIVED = 'none_arrived',
}

export enum LocationConnectionCompletionStatus {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}

export type ProjectParticipantGroup = {
  id: string
  name: string
}

export type ProjectParticipantGroupData = {
  id: string
  name: string
  members: GroupMember[]
}

export type GroupMember = {
  user_id: string
  group_id: string
  email: string
}

export type Notification = {
  id: string
  item_name?: string
  item_id?: string
  map_location_id?: string
  map_location_name?: string
  min_stock_threshold: number
  unit?: string
  category?: string
  status: NotificationStatus
  current_amount: number
  notification_setting_id?: string
  created_at: Date
  updated_at: Date
}

export enum NotificationStatus {
  NEW = 'new',
  ACTION_REQUIRED = 'action_required',
  DONE = 'done',
}

export type NotificationSetting = {
  id: string
  item_name: string
  min_stock_threshold: number
  unit?: string
  category?: string
  map_location?: MapLocation
  receive_email: boolean
}

export enum NotificationScope {
  PROJECT = 'PROJECT',
  MAP_LOCATION = 'MAP_LOCATION',
}

export interface InventoryFlowData {
  name: string
  unit: string
  inventory_level: number
  change: number
  timestamp: string
}

export interface WasteChartItem {
  name: string
  category?: string
  unit: string
  amount: number
  timestamp: string
}

export interface StockOutItem {
  name: string
  category?: string
  frequency: number
}
