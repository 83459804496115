import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/additional-info-fields'

const getAdditionalInfoFieldDataByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/projects/${project_id}`)
  return response
}

const updateAdditionalInfoByItem = async (
  itemId: string,
  project_id: string,
  additionalInfoFieldId: string,
  data: {
    field_value: string
  }
) => {
  const response = await axiosInstance.put(
    `${baseUrl}/${additionalInfoFieldId}/items/${itemId}/projects/${project_id}`,
    data
  )
  return response
}

export default {
  getAdditionalInfoFieldDataByProjectId,
  updateAdditionalInfoByItem,
}
