import { BsTools } from 'react-icons/bs'
import { Button } from 'src/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import { Separator } from 'src/components/ui/separator'
import i18n from 'src/i18n'
import { Bubble, Item, ItemLastUpdateType } from 'src/lib/types'
import DemoItemsTable from './DemoItemsTable'
import { iconAndTextStyling } from 'src/constants'
import { Plus } from 'lucide-react'

interface Props {
  bubble: Bubble
}

const DemoSingleBubbleDialog = ({ bubble }: Props) => {
  const defaulItem = {
    id: 'item1id',
    bubble_id: 'someBubbleId1',
    map_location_id: 'joku-location-1',
    name: i18n.t('demoDisplay.items.one'),
    amount: 10,
    unit: i18n.t('demoDisplay.units.kg'),
    updated_at: new Date(),
    category: i18n.t('demoDisplay.categories.one'),
    last_update_type: ItemLastUpdateType.CREATION,
  }

  const demoItems: Item[] = [
    defaulItem,
    {
      ...defaulItem,
      id: 'item2id',
      bubble_id: 'someBubbleId2',
      name: i18n.t('demoDisplay.items.two'),
      amount: 1200,
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 10), // 10 hours ago
      unit: i18n.t('demoDisplay.units.pcs'),
      category: i18n.t('demoDisplay.categories.two'),
    },
    {
      ...defaulItem,
      id: 'item3id',
      bubble_id: 'someBubbleId3',
      name: i18n.t('demoDisplay.items.three'),
      amount: 50,
      unit: i18n.t('demoDisplay.units.meter'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 12), // 12 hours ago
      category: i18n.t('demoDisplay.categories.three'),
    },
    {
      ...defaulItem,
      id: 'item4id',
      bubble_id: 'someBubbleId1',
      name: i18n.t('demoDisplay.items.three'),
      amount: 500,
      unit: i18n.t('demoDisplay.units.kg'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2), // 2 days ago
      category: i18n.t('demoDisplay.categories.two'),
    },
    {
      ...defaulItem,
      id: 'item5id',
      bubble_id: 'someBubbleId2',
      name: i18n.t('demoDisplay.items.four'),
      amount: 15,
      unit: i18n.t('demoDisplay.units.meter'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 24 * 3), // 3 days ago
      category: i18n.t('demoDisplay.categories.one'),
    },
    {
      ...defaulItem,
      id: 'item6id',
      bubble_id: 'someBubbleId3',
      name: i18n.t('demoDisplay.items.five'),
      amount: 2500,
      unit: i18n.t('demoDisplay.units.pcs'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 24 * 1), // 1 day ago
      category: i18n.t('demoDisplay.categories.three'),
    },
    {
      ...defaulItem,
      id: 'item7id',
      bubble_id: 'someBubbleId1',
      name: i18n.t('demoDisplay.items.six'),
      amount: 100,
      unit: i18n.t('demoDisplay.units.kg'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 2), // 2 hours ago
      category: i18n.t('demoDisplay.categories.one'),
    },
    {
      ...defaulItem,
      id: 'item8id',
      bubble_id: 'someBubbleId2',
      name: i18n.t('demoDisplay.items.five'),
      amount: 80,
      unit: i18n.t('demoDisplay.units.meter'),
      updated_at: new Date(Date.now() - 1000 * 60 * 60 * 1), // 1 hour ago
      category: i18n.t('demoDisplay.categories.two'),
    },
  ]

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="rounded-full" title={bubble.title}>
          <BsTools />
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-6xl">
        <DialogHeader>
          <DialogTitle>{bubble.title}</DialogTitle>
        </DialogHeader>
        <Separator className="my-2" />

        <div className="flex flex-row flex-wrap gap-2 justify-between mb-2">
          <DialogTitle className="my-2">{i18n.t('bubbleItems')}</DialogTitle>
          <Dialog>
            <DialogTrigger asChild>
              <Button className={iconAndTextStyling}>
                <Plus /> {i18n.t('addNewItem')}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {i18n.t('addNewItem')} {bubble.title}
                </DialogTitle>
                <DialogDescription>
                  {i18n.t('demoDisplay.addNewItem.description')}
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
        <DemoItemsTable
          items={demoItems.filter((item) => item.bubble_id === bubble.id)}
        />
      </DialogContent>
    </Dialog>
  )
}

export default DemoSingleBubbleDialog
