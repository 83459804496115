import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import { NotificationStatus } from 'src/lib/types'

const baseUrl = '/api/notifications'
const baseUrlNotificationSettings = '/api/notifications/notification-settings'

const addNewNotificationSetting = async (
  project_id: string,
  data: {
    item_name: string
    min_threshold: number
    category?: string
    unit?: string
    receive_email: boolean
    recipient_users: string[]
    recipient_groups: string[]
    map_location_id?: string
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlNotificationSettings}/projects/${project_id}/create-notification-setting`,
    data
  )
  return response
}

const updateNotificationSettingGeneralInfo = async (
  project_id: string,
  notification_setting_id: string,
  data: {
    item_name: string
    min_threshold: number
    category?: string
    unit?: string
    receive_email: boolean
    map_location_id?: string
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/update-general-info`,
    data
  )
  return response
}

const getAllUsersNotificationSettingsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/projects/${project_id}/get-users-notification-settings`
  )
  return response
}

const getRecipientUsers = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/recipient-users`
  )
  return response
}

const getRecipientGroups = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/recipient-groups`
  )
  return response
}

const getNotificationSettingById = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}`
  )
  return response
}

const deleteRecipientUsers = async (
  project_id: string,
  notification_setting_id: string,
  userIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/delete-recipient-users`,
    {
      list_of_ids: userIds,
    }
  )
  return response
}

const deleteRecipientGroups = async (
  project_id: string,
  notification_setting_id: string,
  groupIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/delete-recipient-groups`,
    {
      list_of_ids: groupIds,
    }
  )
  return response
}

const getNonRecipientUsers = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/get-non-recipient-users`
  )
  return response
}

const getNonRecipientGroups = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/get-non-recipient-groups`
  )
  return response
}

const addRecipientGroups = async (
  project_id: string,
  notification_setting_id: string,
  groupIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/add-recipient-groups`,
    {
      list_of_ids: groupIds,
    }
  )
  return response
}

const addRecipientUsers = async (
  project_id: string,
  notification_setting_id: string,
  userIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}/add-recipient-users`,
    {
      list_of_ids: userIds,
    }
  )
  return response
}

const deleteNotificationSetting = async (
  project_id: string,
  notification_setting_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrlNotificationSettings}/${notification_setting_id}/projects/${project_id}`
  )
  return response
}

const checkProjectsNotifications = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/projects/${project_id}/check-notifications`
  )
  return response
}

const changeNotificationStatus = async (
  project_id: string,
  notification_id: string,
  data: {
    status: NotificationStatus
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${notification_id}/projects/${project_id}/change-status`,
    data
  )
  return response
}

const getAllUsersNotificationsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/projects/${project_id}/users-notifications`
  )
  return response
}

const deleteNotification = async (
  project_id: string,
  notification_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/${notification_id}/projects/${project_id}`
  )
  return response
}

export default {
  addNewNotificationSetting,
  getAllUsersNotificationSettingsByProjectId,
  updateNotificationSettingGeneralInfo,
  getNotificationSettingById,
  getRecipientUsers,
  getRecipientGroups,
  deleteRecipientUsers,
  deleteRecipientGroups,
  getNonRecipientGroups,
  getNonRecipientUsers,
  addRecipientGroups,
  addRecipientUsers,
  deleteNotificationSetting,
  checkProjectsNotifications,
  changeNotificationStatus,
  getAllUsersNotificationsByProjectId,
  deleteNotification,
}
