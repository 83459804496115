import { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { Card, CardContent } from '../ui/card'
import { Field, Form, Formik } from 'formik'
import { Label } from '../ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Input } from '../ui/input'
import { Checkbox } from '../ui/checkbox'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import {
  ForeignUserData,
  MapLocation,
  NotificationScope,
  ProjectParticipantGroup,
} from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import usersService from 'src/services/Users/users'
import { useAppContext } from 'src/context/AppProvider'
import projectParticipantGroupService from 'src/services/ProjectParticipantGroups/projectParticipantGroupService'
import notificationsService from 'src/services/Notifications/notificationsService'
import { toast } from 'react-toastify'
import FormErrorMessage from '../Forms/FormErrorMessage'
import SelectMapLocationDialog from './SelectMapLocationDialog'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { validationSchemaNotificationGeneralInfo } from 'src/lib/validationSchemas'
import itemsService from 'src/services/Items/items'
import { List, ListItem } from '../ui/list'
import { useNavigate } from 'react-router'

interface FormValues {
  item_name: string
  min_threshold: number
  unit?: string
  category?: string
  receive_email: boolean
}

const AddNewNotificationSettingForm = () => {
  const { currentProject } = useAppContext()
  const [selectedScope, setSelectedScope] = useState<NotificationScope>(
    NotificationScope.PROJECT
  )
  const [selectedRecipientGroups, setSelectedRecipientGroups] = useState<
    Set<string>
  >(new Set())
  const [selectedRecipientUsers, setSelectedRecipientUsers] = useState<
    Set<string>
  >(new Set())
  const [recipientGroups, setRecipientGroups] = useState<
    ProjectParticipantGroup[]
  >([])
  const [recipientUsers, setRecipientUsers] = useState<ForeignUserData[]>([])
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(
    null
  )

  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])
  const [selectableItemNames, setSelectableItemNames] = useState<string[]>([])
  const [isNameDropdownOpen, setIsNameDropdownOpen] = useState(false)
  const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false)
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)

  const navigate = useNavigate()

  const fetchItemUtils = async () => {
    try {
      const [categories, units, itemNames] = await Promise.all([
        itemsService.getCategoriesByProjectId(currentProject.id),
        itemsService.getUnitsByProjectId(currentProject.id),
        itemsService.getItemNamesByProjectId(currentProject.id),
      ])
      setSelectableCategories(categories)
      setSelectableUnits(units)
      setSelectableItemNames(itemNames)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUsers = async () => {
    try {
      const response = await usersService.getUsersByProjectId(currentProject.id)
      if (response.status === 200) {
        setRecipientUsers(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchGroups = async () => {
    try {
      const response =
        await projectParticipantGroupService.getAllGroupsByProjectId(
          currentProject.id
        )
      if (response.status === 200) {
        setRecipientGroups(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    // Fetch groups and users from the backend
    fetchUsers()
    fetchGroups()
    fetchItemUtils()
  }, [])

  const toggleSelection = (
    id: string,
    setState: React.Dispatch<React.SetStateAction<Set<string>>>
  ) => {
    setState((prev) => {
      const newSet = new Set(prev)
      newSet.has(id) ? newSet.delete(id) : newSet.add(id)
      return newSet
    })
  }

  const handleSelectAllRecipientUsers = (checked: boolean) => {
    setSelectedRecipientUsers(
      checked
        ? new Set(recipientUsers.map((user) => user.userData.id))
        : new Set()
    )
  }

  const handleSelectAllRecipientGroups = (checked: boolean) => {
    setSelectedRecipientGroups(
      checked ? new Set(recipientGroups.map((group) => group.id)) : new Set()
    )
  }

  const handleCreateNotificationSetting = async (formValues: FormValues) => {
    if (selectedScope === NotificationScope.MAP_LOCATION && !selectedLocation) {
      toast.error(i18n.t('noLocationSelected'))
      return
    }
    try {
      const response = await notificationsService.addNewNotificationSetting(
        currentProject.id,
        {
          item_name: formValues.item_name,
          min_threshold: formValues.min_threshold,
          category: formValues.category,
          unit: formValues.unit,
          receive_email: formValues.receive_email,
          recipient_groups: Array.from(selectedRecipientGroups),
          recipient_users: Array.from(selectedRecipientUsers),
          map_location_id:
            selectedScope === NotificationScope.MAP_LOCATION
              ? selectedLocation!.id
              : undefined,
        }
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        navigate(
          i18n.t('paths.projects') +
            '/' +
            currentProject.id +
            '/' +
            i18n.t('paths.notifications')
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Card>
      <CardContent>
        <h2 className="text-lg font-semibold mb-4">
          {i18n.t('notifications.createNewNotificationSetting')}
        </h2>
        <Formik
          initialValues={{
            item_name: '',
            min_threshold: 0,
            unit: '',
            category: '',
            receive_email: true,
          }}
          validationSchema={validationSchemaNotificationGeneralInfo}
          onSubmit={handleCreateNotificationSetting}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className="space-y-4">
              <Tabs defaultValue="general">
                <TabsList className="flex items-center justify-center flex-wrap h-auto space-y-1">
                  <TabsTrigger value="general">
                    {i18n.t('notifications.general')}
                  </TabsTrigger>
                  <TabsTrigger value="groups">
                    {i18n.t('notifications.recipientGroups')}
                  </TabsTrigger>
                  <TabsTrigger value="users">
                    {i18n.t('notifications.recipientUsers')}
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="general">
                  <div>
                    <Label>{i18n.t('notifications.scope')}</Label>
                    <Select
                      value={selectedScope}
                      onValueChange={(value) =>
                        setSelectedScope(value as NotificationScope)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue
                          placeholder={i18n.t('notifications.selectScope')}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={NotificationScope.PROJECT}>
                          {i18n.t('notifications.scopes.entireProject')}
                        </SelectItem>
                        <SelectItem value={NotificationScope.MAP_LOCATION}>
                          {i18n.t('notifications.scopes.mapLocation')}
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  {selectedScope === NotificationScope.MAP_LOCATION && (
                    <div className="flex flex-col gap-2 my-2">
                      <Label>{i18n.t('notifications.selectMapLocation')}</Label>
                      <p>
                        {i18n.t('selectedLocation')}:{' '}
                        {selectedLocation
                          ? selectedLocation.title
                          : i18n.t('noLocationSelected')}
                      </p>
                      <div>
                        <SelectMapLocationDialog
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                        />
                      </div>
                    </div>
                  )}
                  <div className="relative">
                    <Label htmlFor="name">{i18n.t('itemName')}</Label>
                    <Field
                      type="text"
                      name="item_name"
                      autoComplete="off"
                      as={Input}
                      onFocus={() => setIsNameDropdownOpen(true)}
                      onBlur={() => setIsNameDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('item_name', e.target.value)
                        setIsNameDropdownOpen(true)
                      }}
                      value={values.item_name}
                    />
                    {isNameDropdownOpen && (
                      <List>
                        {selectableItemNames
                          .filter((name) =>
                            name
                              .toLowerCase()
                              .includes(values.item_name.toLowerCase())
                          )
                          .map((name) => (
                            <ListItem
                              key={name}
                              onMouseDown={() => {
                                setFieldValue('item_name', name)
                                setIsNameDropdownOpen(false)
                              }}
                            >
                              {name}
                            </ListItem>
                          ))}
                      </List>
                    )}
                    <FormErrorMessage name="item_name" />
                  </div>
                  <FormErrorMessage name="item_name" />
                  <Label>{i18n.t('minThresholdAmount')}</Label>
                  <Field
                    type="number"
                    min={0}
                    step="any"
                    name="min_threshold"
                    as={Input}
                  />
                  <FormErrorMessage name="min_threshold" />
                  <div className="relative">
                    <Label htmlFor="unit">{i18n.t('unit')}</Label>
                    <Field
                      type="text"
                      name="unit"
                      autoComplete="off"
                      as={Input}
                      onFocus={() => setIsUnitDropdownOpen(true)}
                      onBlur={() => setIsUnitDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('unit', e.target.value)
                        setIsUnitDropdownOpen(true)
                      }}
                      value={values.unit}
                    />
                    {isUnitDropdownOpen && (
                      <List>
                        {selectableUnits
                          .filter((name) =>
                            name
                              .toLowerCase()
                              .includes((values.unit ?? '').toLowerCase())
                          )
                          .map((name) => (
                            <ListItem
                              key={name}
                              onMouseDown={() => {
                                setFieldValue('unit', name)
                                setIsUnitDropdownOpen(false)
                              }}
                            >
                              {name}
                            </ListItem>
                          ))}
                      </List>
                    )}
                    <FormErrorMessage name="unit" />
                  </div>
                  <div className="relative">
                    <Label htmlFor="category">{i18n.t('category')}</Label>
                    <Field
                      type="text"
                      name="category"
                      autoComplete="off"
                      as={Input}
                      onFocus={() => setIsCategoryDropdownOpen(true)}
                      onBlur={() => setIsCategoryDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('category', e.target.value)
                        setIsCategoryDropdownOpen(true)
                      }}
                      value={values.category}
                    />
                    {isCategoryDropdownOpen && (
                      <List>
                        {selectableCategories
                          .filter((name) =>
                            name
                              .toLowerCase()
                              .includes((values.category ?? '').toLowerCase())
                          )
                          .map((name) => (
                            <ListItem
                              key={name}
                              onMouseDown={() => {
                                setFieldValue('category', name)
                                setIsCategoryDropdownOpen(false)
                              }}
                            >
                              {name}
                            </ListItem>
                          ))}
                      </List>
                    )}
                    <FormErrorMessage name="category" />
                  </div>
                  <div className="mt-4 flex gap-1 items-center">
                    <Label>{i18n.t('notifications.receiveEmail')}</Label>
                    <Checkbox
                      name="receive_email"
                      checked={values.receive_email}
                      onCheckedChange={(checked: any) => {
                        setFieldValue('receive_email', checked)
                      }}
                    />
                  </div>
                </TabsContent>

                <TabsContent value="groups">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Checkbox
                            className="mr-1"
                            checked={recipientGroups.every((group) =>
                              selectedRecipientGroups.has(group.id)
                            )}
                            onCheckedChange={(checked) =>
                              handleSelectAllRecipientGroups(checked as boolean)
                            }
                          />
                          {i18n.t('selectAll')}
                        </TableHead>
                        <TableHead>{i18n.t('groupName')}</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {recipientGroups.map((group) => (
                        <TableRow key={group.id}>
                          <TableCell>
                            <Checkbox
                              checked={selectedRecipientGroups.has(group.id)}
                              onCheckedChange={() =>
                                toggleSelection(
                                  group.id,
                                  setSelectedRecipientGroups
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{group.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TabsContent>

                <TabsContent value="users">
                  {i18n.t('notifications.notificationSettingCreatorInfo')}
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Checkbox
                            className="mr-1"
                            checked={recipientUsers.every((user) =>
                              selectedRecipientUsers.has(user.userData.id)
                            )}
                            onCheckedChange={(checked) =>
                              handleSelectAllRecipientUsers(checked as boolean)
                            }
                          />
                          {i18n.t('selectAll')}
                        </TableHead>
                        <TableHead>{i18n.t('email')}</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {recipientUsers.map((user) => (
                        <TableRow key={user.userData.id}>
                          <TableCell>
                            <Checkbox
                              checked={selectedRecipientUsers.has(
                                user.userData.id
                              )}
                              onCheckedChange={() =>
                                toggleSelection(
                                  user.userData.id,
                                  setSelectedRecipientUsers
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{user.userData.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TabsContent>
              </Tabs>
              <PendingSubmitButton
                isSubmitting={isSubmitting}
                buttonText={i18n.t('notifications.saveAndCreateNotification')}
              />
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default AddNewNotificationSettingForm
