import React from 'react'
import { cn } from 'src/lib/utils'
import i18n from 'src/i18n'

interface ProjectRoleInfo {
  title: string
  member: string
  memberInfo: string[]
  moderator?: string
  moderatorInfo?: string[]
  admin: string
  adminInfo: string[]
}

export const RenderProjectRolesInfo: React.FC = () => {
  const projectRoleInfo = i18n.t('projectRoles.infoAboutProjectRoles', {
    returnObjects: true,
  }) as ProjectRoleInfo

  return (
    <div
      className={cn(
        'z-50 w-full rounded-md border bg-popover p-4 text-popover-foreground shadow-md',
        'text-sm leading-relaxed'
      )}
    >
      <p className="font-semibold">{projectRoleInfo.title}</p>

      <div className="mt-2">
        <p className="font-semibold">{projectRoleInfo.member}:</p>
        <ul className="list-disc list-inside pl-4 text-sm">
          {projectRoleInfo.memberInfo.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="mt-4">
        <p className="font-semibold">{projectRoleInfo.moderator}:</p>
        <ul className="list-disc list-inside pl-4 text-sm">
          {projectRoleInfo.moderatorInfo?.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="mt-4">
        <p className="font-semibold">{projectRoleInfo.admin}:</p>
        <ul className="list-disc list-inside pl-4 text-sm">
          {projectRoleInfo.adminInfo.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
