import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { OutOfStockItem } from 'src/lib/types'
import i18n from 'src/i18n'
import { formatDateLocaleExact } from 'src/lib/utils'
import SingleUserInfoDialog from '../Users/SingleUserInfoDialog'
import { useAppContext } from 'src/context/AppProvider'
import ShowLocationOnMapDialog from './ShowLocationOnMapDialog'

interface Props {
  items: OutOfStockItem[]
  showMapButton?: boolean
  displayLocation?: boolean
}

const OutOfStockItemsTable = ({
  items,
  showMapButton,
  displayLocation,
}: Props) => {
  const { currentUser } = useAppContext()
  return (
    <Table className="w-full overflow-x-auto">
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{i18n.t('product')}</TableHead>
          <TableHead>{i18n.t('amount')}</TableHead>
          <TableHead>{i18n.t('unit')}</TableHead>
          <TableHead>{i18n.t('category')}</TableHead>
          <TableHead>{i18n.t('lastModified')}</TableHead>
          {displayLocation && <TableHead>{i18n.t('location')}</TableHead>}
          <TableHead>{i18n.t('functions')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} data-testid={`items-table-row-${item.name}`}>
            <TableCell className="font-medium">{item.name}</TableCell>
            <TableCell>{item.amount}</TableCell>
            <TableCell>{item.unit}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>
              {item.latest_editor_email ? (
                <div>
                  <SingleUserInfoDialog email={item.latest_editor_email} />
                </div>
              ) : (
                <p>{i18n.t('notFoundNA')}</p>
              )}
              {formatDateLocaleExact(item.updated_at.toString(), currentUser)}
            </TableCell>
            {displayLocation && <TableCell>{item.location_title}</TableCell>}
            <TableCell>
              {showMapButton && <ShowLocationOnMapDialog item={item} />}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default OutOfStockItemsTable
