import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import i18n from 'src/i18n'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import { Separator } from '../ui/separator'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { Field, Form, Formik } from 'formik'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { Button } from '../ui/button'
import { Blueprint, Bubble, Item, MapLocation } from 'src/lib/types'
import { RadioGroup, RadioGroupItem } from '../ui/radio-group'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { validationSchemaMoveItem } from 'src/lib/validationSchemas'
import { useAppContext } from 'src/context/AppProvider'
import bubblesService from 'src/services/Bubbles/bubbles'
import blueprintsService from 'src/services/Blueprints/blueprints'
import { toast } from 'react-toastify'
import DisplaySingleBlueprintImage from '../Blueprints/DisplaySingleBlueprintImage'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import itemsService from 'src/services/Items/items'
import AddOrUpdateBubbleDialog from '../Blueprints/AddOrUpdateBubbleDialog'
import { useLocation } from 'react-router'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'
import MapViewer from '../MapViewer/MapViewer'

interface Props {
  item: Item
  renderInDropDownMenu?: boolean
  fetchItems?: () => Promise<void>
}

enum MoveAction {
  ALL = 'all',
  CHOOSE = 'choose',
}

enum MoveLevel {
  MAP = 'MAP',
  BUBBLE = 'BUBBLE',
}

interface FormValues {
  move_amount: number
}

const MoveItemsDialog = ({ item, renderInDropDownMenu, fetchItems }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [selectedMoveAction, setSelectedMoveAction] = useState<MoveAction>(
    MoveAction.ALL
  )
  const [selectedBubble, setSelectedBubble] = useState<Bubble | null>(null)
  const [imageUrl, setImageUrl] = useState<string>('')
  const [selectedBlueprintBubbles, setSelectedBlueprintBubbles] = useState<
    Bubble[]
  >([])
  const [blueprints, setBlueprints] = useState<Blueprint[]>()
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(
    null
  )
  const [selectedMoveLevel, setSelectedMoveLevel] = useState<MoveLevel>(
    MoveLevel.MAP
  )
  const [selectableMapLocations, setSelectableMapLocations] = useState<
    MapLocation[]
  >([])
  const [selectedMapLocation, setSelectedMapLocation] =
    useState<MapLocation | null>(null)
  const { currentProject, setRenderRefreshAlert } = useAppContext()
  const projectId = currentProject.id

  const location = useLocation()
  const currentPath = location.pathname

  const projectMainViewPath = i18n.t('paths.projects') + '/' + projectId
  const projectInventarionViewPath =
    projectMainViewPath + '/' + i18n.t('paths.inventarion')

  const projectHistoryViewPath =
    projectMainViewPath + '/' + i18n.t('paths.history')

  const handleSubmit = async (values: FormValues) => {
    if (!selectedBubble && !selectedMapLocation) {
      toast.error(i18n.t('mustProvideTargetLocation'))
      return
    }
    try {
      const amountToMove =
        selectedMoveAction === MoveAction.ALL ? item.amount : values.move_amount
      const response = await itemsService.moveItem(item.id, projectId, {
        amount: amountToMove,
        bubble_id: selectedBubble?.id,
        map_location_id: selectedMapLocation?.id,
      })
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))

        // Update the items list
        if (fetchItems) {
          await fetchItems()
        } else {
          setRenderRefreshAlert(true)
        }
        // const updatedItem = response.data as Item
        /*
        if (item.map_location_id !== updatedItem.map_location_id) {
          if (item.amount === amountToMove) {
            // filter the item from current items view
            // the response data contains information about the moved or deleted item
            setItems((oldItems) =>
              oldItems.filter((oldItem) => oldItem.id !== item.id)
            )
          } else {
            // user moved part of the items
            // update the item amount in the items table
            // response data contains information about the item after updating
            setItems((oldItems) =>
              oldItems.map((oldItem) =>
                oldItem.id === updatedItem.id ? updatedItem : oldItem
              )
            )
          }
        }
          */
        setDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchBubbleData = async () => {
    if (selectedBlueprint) {
      try {
        const bubblesResponse = await bubblesService.getBubblesByBlueprintId(
          selectedBlueprint.id,
          projectId
        )
        if (bubblesResponse.status === 200) {
          setSelectedBlueprintBubbles(bubblesResponse.data)
        } else {
          toast.error(i18n.t('errorGeneric'))
        }
      } catch (error) {
        toast.error(i18n.t('errorGeneric'))
      }
    }
  }

  const fetchBlueprintsList = async () => {
    try {
      const blueprintsResponse =
        await blueprintsService.getBlueprintsByProjectId(projectId)
      if (blueprintsResponse.status === 200) {
        const blueprintsList = blueprintsResponse.data as Blueprint[]
        setBlueprints(blueprintsList)
        setSelectedBlueprint(blueprintsList[0])
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (selectedBlueprint) {
        try {
          const signedUrl = await blueprintsService.getSignedUrlByBlueprintId(
            selectedBlueprint.id,
            selectedBlueprint.project_id
          )
          if (signedUrl) {
            setImageUrl(signedUrl)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchImageUrl()
    fetchBubbleData()
  }, [selectedBlueprint])

  const fetchMapLocations = async () => {
    try {
      const mapLocationsResponse =
        await mapLocationsService.getAllMapLocationsByProjectId(
          currentProject.id
        )
      if (mapLocationsResponse.status === 200) {
        const selectableLocations = mapLocationsResponse.data as MapLocation[]
        // the current item map location cannot be selected
        setSelectableMapLocations(
          selectableLocations.filter(
            (location) => location.id !== item.map_location_id || item.bubble_id
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    setSelectedMapLocation(null)
    setSelectedBubble(null)
    if (selectedMoveLevel === MoveLevel.BUBBLE) {
      fetchBlueprintsList()
    } else if (selectedMoveLevel === MoveLevel.MAP) {
      fetchMapLocations()
    }
  }, [selectedMoveLevel])

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
        } else {
          setDialogOpen(false)
        }
      }}
    >
      <DialogTrigger asChild>
        {renderInDropDownMenu ? (
          <DropdownMenuItem
            data-testid="open-item-move-modal-button"
            onSelect={(event) => {
              event.preventDefault()
            }}
          >
            {i18n.t('moveItems')}
          </DropdownMenuItem>
        ) : (
          <Button>{i18n.t('moveItems')}</Button>
        )}
      </DialogTrigger>
      <DialogContent data-testid="move-items-dialog-content">
        <DialogHeader>
          <DialogTitle>
            {i18n.t('moveItems')} {item.name}
          </DialogTitle>
          <DialogDescription>
            {i18n.t('moveItemsDescription')}
          </DialogDescription>
        </DialogHeader>
        <Separator />
        <div>
          <Formik
            initialValues={{
              move_amount: item.amount,
            }}
            onSubmit={handleSubmit}
            validationSchema={() => validationSchemaMoveItem(item.amount)}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col gap-2">
                <div>
                  <RadioGroup
                    onValueChange={(value) =>
                      setSelectedMoveAction(value as MoveAction)
                    }
                    value={selectedMoveAction}
                    className="my-2"
                    data-testid="choose-move-option-radiogroup"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value={MoveAction.ALL}
                        id={MoveAction.ALL}
                      />
                      <Label htmlFor={MoveAction.ALL}>
                        {i18n.t('moveAllItems')}
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem
                        value={MoveAction.CHOOSE}
                        id={MoveAction.CHOOSE}
                      />
                      <Label htmlFor={MoveAction.CHOOSE}>
                        {i18n.t('chooseAmount')}
                      </Label>
                    </div>
                  </RadioGroup>
                  {selectedMoveAction === MoveAction.CHOOSE && (
                    <div>
                      <Label htmlFor="move_amount">
                        {i18n.t('amountToMove')}
                      </Label>
                      <Field
                        type="number"
                        id="move_amount"
                        name="move_amount"
                        max={item.amount}
                        min={0}
                        placeholder={i18n.t('amountToMove')}
                        as={Input}
                      />
                      <FormErrorMessage name="move_amount" />
                    </div>
                  )}
                  <Label>{i18n.t('moveItemLevelLabel')}</Label>
                  <Select
                    onValueChange={(value) =>
                      setSelectedMoveLevel(value as MoveLevel)
                    }
                    value={selectedMoveLevel}
                  >
                    <SelectTrigger data-testid="select-move-level-trigger">
                      <SelectValue placeholder={i18n.t('moveLevel')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem
                        key={MoveLevel.MAP}
                        value={MoveLevel.MAP}
                        data-testid={`select-movelevel-${MoveLevel.MAP}`}
                      >
                        {i18n.t(`moveLevels.${MoveLevel.MAP}`)}
                      </SelectItem>
                      <SelectItem
                        key={MoveLevel.BUBBLE}
                        value={MoveLevel.BUBBLE}
                        data-testid={`select-movelevel-${MoveLevel.BUBBLE}`}
                      >
                        {i18n.t(`moveLevels.${MoveLevel.BUBBLE}`)}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  {selectedMoveLevel === MoveLevel.MAP ? (
                    <div className="my-2">
                      <Label>{i18n.t('selectItemToMoveLocation')}</Label>
                      <div className="h-1/2">
                        <MapViewer
                          locations={selectableMapLocations}
                          setLocations={setSelectableMapLocations}
                          selectedLocation={selectedMapLocation}
                          setSelectedLocation={setSelectedMapLocation}
                          suggestRefreshAfterOperation
                          preventSingleLocationSheetOpen
                          customHeight="50vh"
                        />
                      </div>
                    </div>
                  ) : selectedMoveLevel === MoveLevel.BUBBLE && blueprints ? (
                    <div className="my-2">
                      <Label>{i18n.t('selectItemToMoveLocation')}</Label>
                      <div className="flex gap-2">
                        <Select
                          onValueChange={(value) =>
                            setSelectedBlueprint(
                              blueprints.find((b) => b.id === value) || null
                            )
                          }
                        >
                          <SelectTrigger className="w-[180px]">
                            <SelectValue
                              placeholder={
                                selectedBlueprint?.title ?? i18n.t('blueprint')
                              }
                            />
                          </SelectTrigger>
                          <SelectContent>
                            {blueprints.map((blueprint) => (
                              <SelectItem
                                key={blueprint.id}
                                value={blueprint.id.toString()}
                              >
                                {blueprint.title}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        {selectedBlueprint && (
                          <AddOrUpdateBubbleDialog
                            blueprint={selectedBlueprint}
                            setSelectedBlueprintBubbles={
                              setSelectedBlueprintBubbles
                            }
                            bubbles={selectedBlueprintBubbles.filter(
                              (bubble) =>
                                bubble.blueprint_id === selectedBlueprint.id
                            )}
                            imageUrl={imageUrl}
                            suggestRefreshAfterOperation
                          />
                        )}
                      </div>
                      {selectedBlueprint && (
                        <div className="mt-1">
                          <DisplaySingleBlueprintImage
                            blueprint={selectedBlueprint}
                            imageSource={imageUrl}
                            selectableBubbles={selectedBlueprintBubbles.filter(
                              (bubble) =>
                                bubble.blueprint_id === selectedBlueprint.id
                            )}
                            setSelectedBubble={setSelectedBubble}
                            selectedBubble={selectedBubble}
                            item={item}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                  <Separator className="my-2" />
                  <DialogFooter className="my-2 flex gap-2">
                    <DialogClose asChild>
                      <Button type="button" variant={'destructive'}>
                        {i18n.t('cancel')}
                      </Button>
                    </DialogClose>
                    <PendingSubmitButton
                      data-testid="submit-move-items-form-button"
                      buttonText={i18n.t('save')}
                      isSubmitting={isSubmitting}
                    />
                  </DialogFooter>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MoveItemsDialog
