import { Plus, Settings } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import AddOrUpdateNewProjectParticipantGroupDialog from 'src/components/ProjectParticipantGroups/AddOrUpdateNewProjectParticipantGroupDialog'
import { Button } from 'src/components/ui/button'
import { Card, CardContent, CardTitle } from 'src/components/ui/card'
import { Checkbox } from 'src/components/ui/checkbox'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import { Separator } from 'src/components/ui/separator'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import {
  ForeignUserData,
  GroupMember,
  ProjectParticipantGroup,
  UserProjectRoles,
} from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import projectParticipantGroupService from 'src/services/ProjectParticipantGroups/projectParticipantGroupService'
import usersService from 'src/services/Users/users'

const ProjectParticipantGroupsView = () => {
  const [groups, setGroups] = useState<ProjectParticipantGroup[]>([])
  const [selectableUsers, setSelectableUsers] = useState<ForeignUserData[]>([])
  const [groupMembers, setGroupMembers] = useState<GroupMember[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set())
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set())
  const { currentProject, currentUser } = useAppContext()

  const currentUserRole = currentUser.project_role

  const fetchGroups = async () => {
    try {
      const response =
        await projectParticipantGroupService.getAllGroupsByProjectId(
          currentProject.id
        )
      if (response.status === 200) {
        setGroups(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchGroupData = async (groupId: string) => {
    try {
      const response = await projectParticipantGroupService.getGroupById(
        currentProject.id,
        groupId
      )
      setGroupMembers(response.data.members)
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchProjectUsers = async () => {
    try {
      const response = await usersService.getUsersByProjectId(currentProject.id)
      if (response.status === 200) {
        const allUsers = response.data as ForeignUserData[]
        const existingUserIds = groupMembers.map((user) => user.user_id)
        setSelectableUsers(
          allUsers.filter(
            (user) => !existingUserIds?.includes(user.userData.id)
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleUserSelect = (userId: string) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev)
      newSet.has(userId) ? newSet.delete(userId) : newSet.add(userId)
      return newSet
    })
  }

  const handleMemberSelect = (userId: string) => {
    setSelectedMembers((prev) => {
      const newSet = new Set(prev)
      newSet.has(userId) ? newSet.delete(userId) : newSet.add(userId)
      return newSet
    })
  }

  const handleSelectAllSelectableUsers = (checked: boolean) => {
    setSelectedUsers(
      checked
        ? new Set(selectableUsers.map((user) => user.userData.id))
        : new Set()
    )
  }

  const handleSelectAllMembers = (checked: boolean) => {
    setSelectedMembers(
      checked ? new Set(groupMembers.map((user) => user.user_id)) : new Set()
    )
  }

  const handleDeleteSelectedMembers = async (groupId: string) => {
    try {
      const response =
        await projectParticipantGroupService.deleteUsersFromGroup(
          currentProject.id,
          groupId,
          Array.from(selectedMembers)
        )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setSelectedMembers(new Set())
        setGroupMembers((oldMembers) =>
          oldMembers.filter((member) => !response.data.includes(member.user_id))
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const allSelectableUsersSelected =
    selectableUsers.length > 0 &&
    selectableUsers.every((user) => selectedUsers.has(user.userData.id))

  const allMembersSelected =
    groupMembers.length > 0 &&
    groupMembers.every((user) => selectedMembers.has(user.user_id))

  const handleAddUsersToGroup = async (groupId: string) => {
    try {
      const response = await projectParticipantGroupService.addUsersToGroup(
        currentProject.id,
        groupId,
        Array.from(selectedUsers)
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setSelectedUsers(new Set())
        setGroupMembers(response.data)
        setIsDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleDeleteGroup = async (groupId: string) => {
    const showConfirmationDialog = useConfirmaDialogStableTarget()

    // Wait for user confirmation
    const confirmed = await showConfirmationDialog({
      title: i18n.t('deleteGroup'),
      text: i18n.t('confirmDeleteGroup'),
    })

    if (!confirmed) {
      return
    }
    try {
      const response = await projectParticipantGroupService.deleteGroup(
        currentProject.id,
        groupId
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setGroups((oldGroups) => oldGroups.filter((g) => g.id !== groupId))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  return (
    <div className="mx-2">
      <div className="flex flex-wrap justify-between gap-2">
        <h2 className="mb-10">{i18n.t('allGroups')}</h2>
        {currentUserRole === UserProjectRoles.ADMIN && (
          <div>
            <AddOrUpdateNewProjectParticipantGroupDialog
              setGroups={setGroups}
            />
          </div>
        )}
      </div>
      <div className="max-w-lg">
        {groups.map((group) => (
          <Card key={group.id} className="my-2">
            <CardContent className="flex justify-between gap-1 pb-0">
              <CardTitle>{group.name}</CardTitle>
              <Sheet
                onOpenChange={async (open) => {
                  if (open) {
                    await fetchGroupData(group.id)
                  }
                }}
              >
                <SheetTrigger asChild>
                  <Button variant={'secondary'}>
                    <Settings />
                  </Button>
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>{group.name}</SheetTitle>
                  </SheetHeader>
                  <Separator className="my-2" />
                  {currentUserRole === UserProjectRoles.ADMIN && (
                    <div className="flex flex-wrap gap-2 mb-2">
                      <Button
                        variant={'destructive'}
                        onClick={() => handleDeleteGroup(group.id)}
                      >
                        {i18n.t('deleteGroup')}
                      </Button>
                      <AddOrUpdateNewProjectParticipantGroupDialog
                        setGroups={setGroups}
                        group={group}
                      />
                      <Separator className="my-2" />
                    </div>
                  )}
                  <div className="flex justify-between">
                    <h3>{i18n.t('groupMembers')}</h3>
                    {currentUserRole === UserProjectRoles.ADMIN && (
                      <Dialog
                        open={isDialogOpen}
                        onOpenChange={async (open) => {
                          if (open) {
                            await fetchProjectUsers()
                          }
                          setIsDialogOpen(open)
                        }}
                      >
                        <DialogTrigger asChild>
                          <Button variant={'secondary'}>
                            <Plus />
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>{i18n.t('addNewMember')}</DialogTitle>
                          </DialogHeader>
                          <Separator className="my-2" />
                          <div>
                            {selectableUsers.length > 0 ? (
                              <Table>
                                <TableHeader>
                                  <TableRow>
                                    <TableHead>
                                      <Checkbox
                                        className="mr-1"
                                        checked={allSelectableUsersSelected}
                                        onCheckedChange={(checked) =>
                                          handleSelectAllSelectableUsers(
                                            checked as boolean
                                          )
                                        }
                                      />
                                      {i18n.t('selectAll')}
                                    </TableHead>
                                    <TableHead>{i18n.t('email')}</TableHead>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                  {selectableUsers.map((user) => (
                                    <TableRow key={user.userData.id}>
                                      <TableCell>
                                        <Checkbox
                                          checked={selectedUsers.has(
                                            user.userData.id
                                          )}
                                          onCheckedChange={() =>
                                            handleUserSelect(user.userData.id)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell>
                                        {user.userData.email}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            ) : (
                              <p>{i18n.t('noAddableUsersFound')}</p>
                            )}
                            <Separator className="my-2" />
                            <Button
                              onClick={() => handleAddUsersToGroup(group.id)}
                            >
                              {i18n.t('save')}
                            </Button>
                          </div>
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                  {groupMembers.length > 0 ? (
                    <div>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            {currentUserRole === UserProjectRoles.ADMIN && (
                              <TableHead>
                                <Checkbox
                                  className="mr-1"
                                  checked={allMembersSelected}
                                  onCheckedChange={(checked) =>
                                    handleSelectAllMembers(checked as boolean)
                                  }
                                />
                                {i18n.t('selectAll')}
                              </TableHead>
                            )}
                            <TableHead>{i18n.t('email')}</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {groupMembers.map((member) => (
                            <TableRow key={member.user_id}>
                              {currentUserRole === UserProjectRoles.ADMIN && (
                                <TableCell>
                                  <Checkbox
                                    checked={selectedMembers.has(
                                      member.user_id
                                    )}
                                    onCheckedChange={() =>
                                      handleMemberSelect(member.user_id)
                                    }
                                  />
                                </TableCell>
                              )}
                              <TableCell>{member.email}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Separator className="my-2" />
                      {currentUserRole === UserProjectRoles.ADMIN && (
                        <Button
                          onClick={() => handleDeleteSelectedMembers(group.id)}
                          disabled={selectedMembers.size === 0}
                          variant="destructive"
                        >
                          {i18n.t('deleteSelected')}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <p>{i18n.t('noUsersFound')}</p>
                  )}
                </SheetContent>
              </Sheet>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default ProjectParticipantGroupsView
