import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import { ImportedFile, TemplateItem } from 'src/lib/types'

const baseUrl = '/api/data/imports'

const importFile = async (
  file: File,
  project_id: string
): Promise<ImportedFile> => {
  const formData = new FormData()
  formData.append('file', file)
  const response: AxiosResponse<ImportedFile> = await axiosInstance.post(
    `${baseUrl}/add-new/${project_id}`,
    formData
  )
  return response.data
}

const getImportedFiles = async (
  project_id: string
): Promise<ImportedFile[]> => {
  const response: AxiosResponse<ImportedFile[]> = await axiosInstance.get(
    `${baseUrl}/files/${project_id}`
  )
  return response.data
}

const getFileContents = async (
  fileId: string,
  projectId: string
): Promise<string[][]> => {
  const response: AxiosResponse<string[][]> = await axiosInstance.get(
    `${baseUrl}/file-contents/${fileId}/${projectId}`
  )
  return response.data
}

const deleteImportedFile = async (
  fileId: string,
  projectId: string
): Promise<void> => {
  await axiosInstance.delete(`${baseUrl}/delete-file/${fileId}/${projectId}`)
}

export default {
  importFile,
  getImportedFiles,
  getFileContents,
  deleteImportedFile,
}
