import React, { useState } from 'react'
import i18n from 'src/i18n'
import InventoryFlowChart from 'src/components/DataCharts/InventoryFlowChart'
import WasteChart from 'src/components/DataCharts/WasteChart'
import StockOutChart from 'src/components/DataCharts/StockOutChart'
import { Card } from '../ui/card'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '../ui/select'

export const ProjectDataDashboard = () => {
  const [selectedChart, setSelectedChart] = useState('inventory')

  return (
    <Card className="my-2 p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">{i18n.t('data.title')}</h2>
        <div className="w-auto">
          <Select value={selectedChart} onValueChange={setSelectedChart}>
            <SelectTrigger className="border rounded-lg p-2 text-sm bg-gray-100 min-w-[180px] w-auto">
              <SelectValue placeholder={i18n.t('data.materialFlow')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="inventory">
                {i18n.t('data.materialFlow')}
              </SelectItem>
              <SelectItem value="waste">{i18n.t('data.wasteData')}</SelectItem>
              <SelectItem value="stockout">
                {i18n.t('data.StockOut')}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="w-full h-auto min-h-[500px] border rounded-lg bg-gray-100 p-4 flex items-start justify-center">
        {selectedChart === 'inventory' && <InventoryFlowChart />}
        {selectedChart === 'waste' && <WasteChart />}
        {selectedChart === 'stockout' && <StockOutChart />}
      </div>
    </Card>
  )
}
