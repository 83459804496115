import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ItemsTable from 'src/components/Items/ItemsTable'
import OutOfStockItemsTable from 'src/components/Items/OutOfStockItemsTable'
import SearchItems from 'src/components/Items/SearchItems'
import ShowAllLocationsDialog from 'src/components/Items/ShowAllLocationsDialog'
import WasteItemsTable from 'src/components/Items/WasteItemsTable'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import Pagination from 'src/components/Pagination'
import { Card, CardContent } from 'src/components/ui/card'
import { Label } from 'src/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import { OutOfStockItem, Item, ItemDisplayType, WasteItem } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import itemsService from 'src/services/Items/items'

const ProjectHistoryView = () => {
  const [searchParams] = useSearchParams()
  const [inventarion, setInventarion] = useState<Item[]>([])
  const [wasteInventarion, setWasteInventarion] = useState<WasteItem[]>([])
  const [outOfStockInventarion, setOutOfStockInventarion] = useState<
    OutOfStockItem[]
  >([])
  const [totalResults, setTotalResults] = useState<number>(0)
  const [selectedItemDisplay, setSelectedItemDisplay] =
    useState<ItemDisplayType>(ItemDisplayType.ITEMS)
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { currentProject } = useAppContext()
  const projectId = currentProject.id
  const resultsPerPage = parseInt(
    searchParams.get('resultsPerPage') ?? DEFAULT_SEARCH_RESULTS.toString()
  )
  const fetchProject = async () => {
    if (projectId) {
      try {
        setLoading(true)
        const page = searchParams.get('page')
        const searchQuery = searchParams.get('search') ?? ''
        const category = searchParams.get('category') ?? ''
        const itemLocation = searchParams.get('itemLocation') ?? ''
        const unit = searchParams.get('unit') ?? ''
        const tagKeys = searchParams.get('tagKeys')?.split(',')
        const tagValues = searchParams.get('tagValues')?.split(',')
        if (selectedItemDisplay === ItemDisplayType.ITEMS) {
          const fetchedInventarion =
            await itemsService.searchItemsHistoryByProjectId(projectId, {
              page: page ? parseInt(page) : 1,
              search: searchQuery,
              category: category !== 'null' ? category : '',
              unit: unit !== 'null' ? unit : '',
              item_location: itemLocation !== 'null' ? itemLocation : '',
              sort: 'updated_at_desc',
              tag_keys: tagKeys,
              tag_values: tagValues,
              items_per_page: resultsPerPage,
            })
          if (fetchedInventarion) {
            setInventarion(fetchedInventarion.items)
            setTotalResults(fetchedInventarion.totalResults)
          }
        } else if (selectedItemDisplay === ItemDisplayType.WASTE_ITEMS) {
          const fetchedInventarion =
            await itemsService.searchWasteItemsByProjectId(projectId, {
              page: page ? parseInt(page) : 1,
              search: searchQuery,
              category: category !== 'null' ? category : '',
              unit: unit !== 'null' ? unit : '',
              item_location: itemLocation !== 'null' ? itemLocation : '',
              sort: 'updated_at_desc',
              items_per_page: resultsPerPage,
            })
          if (fetchedInventarion) {
            setWasteInventarion(fetchedInventarion.items)
            setTotalResults(fetchedInventarion.totalResults)
          }
        } else if (selectedItemDisplay === ItemDisplayType.OUT_OF_STOCK_ITEMS) {
          const fetchedInventarion =
            await itemsService.searchOutOfStockItemsByProjectId(projectId, {
              page: page ? parseInt(page) : 1,
              search: searchQuery,
              category: category !== 'null' ? category : '',
              unit: unit !== 'null' ? unit : '',
              item_location: itemLocation !== 'null' ? itemLocation : '',
              sort: 'updated_at_desc',
              items_per_page: resultsPerPage,
            })
          if (fetchedInventarion) {
            setOutOfStockInventarion(fetchedInventarion.items)
            setTotalResults(fetchedInventarion.totalResults)
          }
        } else {
          return
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchProject()
  }, [searchParams, selectedItemDisplay])

  const handleSelectValueChange = (value: string) => {
    searchParams.set('page', '1')
    const currentLocation = location.pathname
    navigate({ pathname: currentLocation, search: searchParams.toString() })
    setSelectedItemDisplay(value as ItemDisplayType)
  }

  return (
    <div className="mx-2" data-testid="single-project-history-view">
      <h1 className="mb-2">{i18n.t('projectHistory')}</h1>
      <SearchItems hideSortingMethods displayResultsPerPageSelection />
      <div className="flex flex-row gap-2 justify-between flex-wrap my-3">
        <div>
          {i18n.t('totalSearchResults')}: {totalResults}
        </div>
        {selectedItemDisplay === ItemDisplayType.ITEMS && (
          <div className="my-2">
            <ShowAllLocationsDialog projectId={projectId} />
          </div>
        )}
      </div>
      <div className="my-2 max-w-fit">
        <Label>{i18n.t('itemDisplayType')}</Label>
        <Select
          name="item-display-type"
          onValueChange={(v) => handleSelectValueChange(v)}
          value={selectedItemDisplay}
        >
          <SelectTrigger data-testid="select-item-display-type-trigger">
            <SelectValue placeholder={i18n.t('itemDisplayType')} />
          </SelectTrigger>
          <SelectContent>
            {Object.values(ItemDisplayType).map((type) => (
              <SelectItem
                key={type}
                value={type}
                data-testid={`select-item-display-type-${type}`}
              >
                {i18n.t(`itemDisplayTypes.${type}`)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <Card className="my-2">
          <CardContent>
            {selectedItemDisplay === ItemDisplayType.ITEMS ? (
              <ItemsTable
                items={inventarion}
                setItems={setInventarion}
                showMapButton
                displayLatestChangeInfo
                displayLocation
                fetchItems={fetchProject}
              />
            ) : selectedItemDisplay === ItemDisplayType.WASTE_ITEMS ? (
              <WasteItemsTable items={wasteInventarion} />
            ) : selectedItemDisplay === ItemDisplayType.OUT_OF_STOCK_ITEMS ? (
              <OutOfStockItemsTable
                items={outOfStockInventarion}
                showMapButton
                displayLocation
              />
            ) : null}
          </CardContent>
        </Card>
      )}
      <div className="text-center my-2">
        <Pagination totalPages={Math.ceil(totalResults / resultsPerPage)} />
      </div>
    </div>
  )
}

export default ProjectHistoryView
