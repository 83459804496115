import React, { useEffect, useState, useMemo } from 'react'
import ReactECharts from 'echarts-for-react'
import { useDebounce } from 'use-debounce'
import i18n from 'src/i18n'
import { toast } from 'react-toastify'
import chartService from 'src/services/Data/chartService'
import itemService from 'src/services/Items/items'
import { WasteChartItem } from 'src/lib/types'
import { useAppContext } from 'src/context/AppProvider'
import { MultiSelect } from 'src/components/ui/multi-select'
import { Label } from 'src/components/ui/label'
import { Checkbox } from 'src/components/ui/checkbox'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'

// Helper function to format date
const getFormattedDate = (date: Date) => date.toISOString().split('T')[0]

// Dynamic color selection for better visibility
const generateColors = (count: number) => {
  const baseColors = [
    '#3498DB',
    '#E74C3C',
    '#2ECC71',
    '#F39C12',
    '#9B59B6',
    '#1ABC9C',
  ]
  return Array.from(
    { length: count },
    (_, i) => baseColors[i % baseColors.length]
  )
}

const WasteChart: React.FC = () => {
  const { currentProject } = useAppContext()

  const [wasteData, setWasteData] = useState<WasteChartItem[]>([])
  const [allWasteCategories, setAllWasteCategories] = useState<string[]>([])
  const [allWasteItemNames, setAllWasteItemNames] = useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [showTop10, setShowTop10] = useState(false)
  const [wholeProject, setWholeProject] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(
    getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 7)))
  )
  const [endDate, setEndDate] = useState(getFormattedDate(new Date()))
  const [tempStartDate, setTempStartDate] = useState(startDate)
  const [tempEndDate, setTempEndDate] = useState(endDate)
  // Debounced Start and End Dates
  const [debouncedStartDate] = useDebounce(tempStartDate, 500)
  const [debouncedEndDate] = useDebounce(tempEndDate, 500)
  const [loading, setLoading] = useState<boolean>(true)

  // Adjust Start Date for Whole Project
  useEffect(() => {
    if (wholeProject && currentProject?.created_at) {
      setStartDate(getFormattedDate(new Date(currentProject.created_at)))
    } else {
      setStartDate(tempStartDate)
    }
  }, [wholeProject])

  useEffect(() => {
    setStartDate(debouncedStartDate)
    setEndDate(debouncedEndDate)
  }, [debouncedStartDate, debouncedEndDate])

  useEffect(() => {
    if (!currentProject?.id) return

    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await chartService.getWasteData(
          currentProject.id,
          startDate,
          endDate
        )
        setWasteData(result || [])
      } catch (err) {
        toast.error(i18n.t('errorGeneric'))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [startDate, endDate])

  useEffect(() => {
    if (!currentProject?.id) return
    const fetchWasteOptions = async () => {
      try {
        const [categories, items] = await Promise.all([
          itemService.getWasteCategoriesByProjectId(currentProject.id),
          itemService.getWasteItemNamesByProjectId(currentProject.id),
        ])
        setAllWasteCategories(categories || [])
        setAllWasteItemNames(items || [])
      } catch (err) {
        toast.error(i18n.t('errorGeneric'))
      }
    }

    fetchWasteOptions()
  }, [currentProject?.id])

  useEffect(() => {
    if (showTop10) {
      setSelectedCategories([])
      const totals: Record<string, number> = {}
      wasteData.forEach((item) => {
        if (!totals[item.name]) totals[item.name] = 0
        totals[item.name] += item.amount
      })
      const topItems = Object.entries(totals)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10)
        .map(([name]) => name)
      setSelectedItems(topItems)
    } else {
      setSelectedCategories([])
      setSelectedItems([])
    }
  }, [showTop10, wasteData])

  const categories = useMemo(() => {
    return Array.from(
      new Set(wasteData.map((item) => item.category || 'Others'))
    )
  }, [wasteData])

  const itemsForCategory = useMemo(() => {
    const uniqueItems = new Set<string>()
    return wasteData
      .filter((item) => {
        const category = item.category || 'Others'
        return selectedCategories.length > 0
          ? selectedCategories.includes(category)
          : true
      })
      .filter((item) => {
        if (uniqueItems.has(item.name)) {
          return false // Skip duplicates
        }
        uniqueItems.add(item.name)
        return true
      })
      .map((item) => ({
        label: item.name,
        value: item.name,
      }))
  }, [wasteData, selectedCategories])

  useEffect(() => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) =>
        itemsForCategory.some((i) => i.value === item)
      )
    )
  }, [itemsForCategory])

  const filteredWasteData = useMemo(() => {
    return wasteData.filter((item) => {
      const category = item.category || 'Others'
      return (
        (selectedCategories.length === 0 ||
          selectedCategories.includes(category)) &&
        (selectedItems.length === 0 || selectedItems.includes(item.name)) // Ensure selected items are filtered correctly
      )
    })
  }, [wasteData, selectedCategories, selectedItems])

  const stackedData = useMemo(() => {
    const groupedData: { [itemName: string]: { [unit: string]: number } } = {}
    filteredWasteData.forEach((item) => {
      if (!groupedData[item.name]) {
        groupedData[item.name] = {}
      }
      if (!groupedData[item.name][item.unit]) {
        groupedData[item.name][item.unit] = 0
      }
      groupedData[item.name][item.unit] += item.amount
    })
    return groupedData
  }, [filteredWasteData])

  // Get unique units for stacking
  const unitNames = useMemo(() => {
    const units = new Set<string>()
    filteredWasteData.forEach((item) => units.add(item.unit))
    return Array.from(units)
  }, [filteredWasteData])

  // Assign Colors Dynamically
  const colorPalette = generateColors(unitNames.length)

  const stackedSeries = useMemo(() => {
    const updatedSeries = unitNames.map((unit, index) => ({
      name: unit,
      type: 'bar',
      stack: 'waste',
      data: Object.keys(stackedData).map(
        (itemName) => stackedData[itemName]?.[unit] || 0
      ),
      itemStyle: { color: colorPalette[index] },
    }))
    return updatedSeries
  }, [filteredWasteData])

  const poleChartOptions = {
    title: {
      text: `${i18n.t('data.wasteChartTitle')}`,
      left: 'center',
      textStyle: { fontSize: 16, fontWeight: 'bold' },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: (params: any) => {
        if (!params || params.length === 0) {
          return ''
        }
        const filteredParams = params.filter((p: any) => p.value > 0)
        if (filteredParams.length === 0) {
          return ''
        }
        let tooltipContent = `<b>${filteredParams[0]?.name || 'Unknown'}</b><br/>`

        filteredParams.forEach((p: any) => {
          tooltipContent += `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${p.color};"></span> 
                              ${p.seriesName}: ${p.value}<br/>`
        })
        return tooltipContent
      },
    },
    xAxis: {
      type: 'category',
      data: Object.keys(stackedData),
    },
    yAxis: {
      type: 'value',
      name: 'Waste Amount',
    },
    series: stackedSeries,
    grid: { right: '10%', bottom: '15%' },

    legend: {
      data: unitNames,
      orient: 'vertical',
      right: 10,
      top: 'center',
    },
    media: [
      {
        query: { maxWidth: 768 }, // If screen width is <= 768px (Tablet & Mobile)
        option: {
          legend: {
            orient: 'horizontal',
            bottom: 10,
            left: 'center',
          },
          grid: { bottom: '20%' },
        },
      },
    ],
  }

  return (
    <div className="p-4 w-full">
      <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4 items-center p-4">
        <Label className="flex items-center w-full sm:w-auto">
          <Checkbox
            checked={wholeProject}
            onCheckedChange={(checked) => setWholeProject(checked === true)}
          />
          <span className="ml-2">{i18n.t('data.wholeProject')}</span>
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.startDate')}
          </span>
          <input
            type="date"
            value={tempStartDate}
            max={
              tempEndDate
                ? getFormattedDate(
                    new Date(
                      new Date(tempEndDate).setDate(
                        new Date(tempEndDate).getDate() - 1
                      )
                    )
                  )
                : getFormattedDate(new Date())
            }
            onChange={(e) => setTempStartDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-0"
          />
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.endDate')}
          </span>
          <input
            type="date"
            value={tempEndDate}
            min={
              tempStartDate
                ? getFormattedDate(
                    new Date(
                      new Date(tempStartDate).setDate(
                        new Date(tempStartDate).getDate() + 1
                      )
                    )
                  )
                : ''
            }
            max={getFormattedDate(new Date())}
            onChange={(e) => setTempEndDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-0"
          />
        </Label>
        <div className="w-full sm:w-auto">
          <MultiSelect
            key={showTop10 ? 'top10-cat' : 'normal-cat'}
            options={allWasteCategories.map((category) => ({
              label: category,
              value: category,
            }))}
            value={selectedCategories}
            onValueChange={setSelectedCategories}
            disabled={showTop10}
            className="bg-white"
            placeholder={i18n.t('data.selectCategories')}
          />
        </div>
        <div className="w-full sm:w-auto">
          <MultiSelect
            key={showTop10 ? 'top10-cat' : 'normal-cat'}
            options={allWasteItemNames.map((item) => ({
              label: item,
              value: item,
            }))}
            value={selectedItems}
            onValueChange={setSelectedItems}
            disabled={showTop10}
            placeholder={i18n.t('data.selectItems')}
            className="bg-white"
            maxCount={10}
          />
        </div>
        <Label className="flex items-center w-full sm:w-auto">
          <Checkbox
            checked={showTop10}
            onCheckedChange={(checked) => setShowTop10(checked === true)}
          />
          <span className="ml-2">{i18n.t('data.top10MostWasted')}</span>
        </Label>
      </div>
      <div className="flex justify-center items-center w-full">
        {loading ? (
          <SmallLoadingCircleOnly />
        ) : stackedSeries.length === 0 ? (
          <p className="text-center text-gray-600">
            {i18n.t('data.noWasteData')}
          </p>
        ) : (
          <ReactECharts
            key={JSON.stringify(stackedSeries)}
            option={poleChartOptions}
            style={{ width: '100%', minHeight: '500px' }}
          />
        )}
      </div>
    </div>
  )
}

export default WasteChart
