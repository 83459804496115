import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { Button } from '../ui/button'
import { MapIcon } from 'lucide-react'
import {
  Blueprint,
  Bubble,
  OutOfStockItem,
  Item,
  MapLocation,
} from 'src/lib/types'
import bubblesService from 'src/services/Bubbles/bubbles'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import blueprintsService from 'src/services/Blueprints/blueprints'
import DisplaySingleBlueprintImage from '../Blueprints/DisplaySingleBlueprintImage'
import { useAppContext } from 'src/context/AppProvider'
import { iconAndTextStyling } from 'src/constants'
import { displayResponseErrorMessage } from 'src/lib/utils'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'
import MapViewer from '../MapViewer/MapViewer'

interface Props {
  item: Item | OutOfStockItem
}

const ShowLocationOnMapDialog = ({ item }: Props) => {
  const [bubble, setBubble] = useState<Bubble | null>(null)
  const [blueprint, setBlueprint] = useState<Blueprint | null>(null)
  const [mapLocation, setMapLocation] = useState<MapLocation | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>('')

  const { currentProject } = useAppContext()
  const projectId = currentProject.id

  const fetchBubbleData = async () => {
    setLoading(true)
    try {
      if (!projectId || !item.bubble_id) {
        return
      }
      const bubble = (await bubblesService.getBubbleById(
        item.bubble_id,
        projectId
      )) as Bubble
      const signedUrl = await blueprintsService.getSignedUrlByBlueprintId(
        bubble.blueprint_id,
        projectId
      )
      const blueprint = await blueprintsService.getBlueprintById(
        bubble.blueprint_id,
        projectId
      )
      if (bubble && blueprint && signedUrl) {
        setImageUrl(signedUrl)
        setBubble(bubble)
        setBlueprint(blueprint)
      } else {
        throw new Error(i18n.t('errorFetchingBubbleData'))
      }
    } catch (error) {
      toast.error(i18n.t('errorFetchingBubbleData'))
    } finally {
      setLoading(false)
    }
  }

  const fetchMapLocationData = async () => {
    if (!item.map_location_id) {
      return
    }
    setLoading(true)
    try {
      const mapLocationResponse = await mapLocationsService.getMapLocationById(
        currentProject.id,
        item.map_location_id
      )
      if (mapLocationResponse.status === 200) {
        const mapLocation = mapLocationResponse.data as MapLocation
        setMapLocation(mapLocation)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  if (!item.bubble_id && !item.map_location_id) {
    return null
  }

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          if (item.bubble_id) {
            await fetchBubbleData()
          } else if (item.map_location_id) {
            await fetchMapLocationData()
          }
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant={'secondary'} className={iconAndTextStyling}>
          <MapIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        {loading && <SmallLoadingCircleOnly borderColor="border-blue-500" />}
        {bubble && blueprint ? (
          <div className="w-full">
            <h1>{bubble.title}</h1>
            <DisplaySingleBlueprintImage
              blueprint={blueprint}
              bubbles={[bubble]}
              imageSource={imageUrl}
            />
          </div>
        ) : mapLocation ? (
          <MapViewer locations={[mapLocation]} selectedLocation={null} />
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

export default ShowLocationOnMapDialog
