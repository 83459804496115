import i18n from 'src/i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Item } from 'src/lib/types'
import { Separator } from '../ui/separator'
import { Button } from '../ui/button'
import ItemTag from './ItemTag'

interface Props {
  item: Item
  handleDeleteTagFromItem: (itemId: string, tagId: string) => void
}

const AllItemTagsDialog = ({ item, handleDeleteTagFromItem }: Props) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="underline max-w-fit">{i18n.t('showMore')}</button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {i18n.t('allTagsForItem')} {item.name}
          </DialogTitle>
        </DialogHeader>
        <Separator className="my-2" />
        {item.tags?.map((tag) => {
          return (
            <ItemTag
              item={item}
              tag={tag}
              handleDeleteTagFromItem={handleDeleteTagFromItem}
            />
          )
        })}
        <Separator className="my-2" />
        <DialogFooter className="my-2 flex gap-2">
          <DialogClose asChild>
            <Button type="button" variant={'secondary'}>
              {i18n.t('close')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AllItemTagsDialog
