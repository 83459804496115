import { useState, useEffect, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card'
import { Field, Form, Formik } from 'formik'
import { Label } from '../ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Input } from '../ui/input'
import { Checkbox } from '../ui/checkbox'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import {
  ForeignUserData,
  MapLocation,
  NotificationScope,
  NotificationSetting,
  ProjectParticipantGroup,
} from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import usersService from 'src/services/Users/users'
import { useAppContext } from 'src/context/AppProvider'
import projectParticipantGroupService from 'src/services/ProjectParticipantGroups/projectParticipantGroupService'
import notificationsService from 'src/services/Notifications/notificationsService'
import { toast } from 'react-toastify'
import FormErrorMessage from '../Forms/FormErrorMessage'
import SelectMapLocationDialog from './SelectMapLocationDialog'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { useNavigate, useParams } from 'react-router'
import { validationSchemaNotificationGeneralInfo } from 'src/lib/validationSchemas'
import { useConfirmaDialogStableTarget } from '../ui/confirmDialog'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Separator } from '../ui/separator'
import itemsService from 'src/services/Items/items'
import { List, ListItem } from '../ui/list'

interface FormValues {
  item_name: string
  min_threshold: number
  unit?: string
  category?: string
  receive_email: boolean
}

const ModifyNotificationSettingView = () => {
  const { notificationSettingId } = useParams<{
    notificationSettingId: string
  }>()
  const { currentProject } = useAppContext()
  const [selectedScope, setSelectedScope] = useState<NotificationScope>()
  const [selectedRecipientGroups, setSelectedRecipientGroups] = useState<
    Set<string>
  >(new Set())
  const [selectedRecipientUsers, setSelectedRecipientUsers] = useState<
    Set<string>
  >(new Set())
  const [recipientGroups, setRecipientGroups] = useState<
    ProjectParticipantGroup[]
  >([])
  const [recipientUsers, setRecipientUsers] = useState<
    { id: string; email: string }[]
  >([])
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(
    null
  )
  const [notificationSetting, setNotificationSetting] =
    useState<NotificationSetting | null>(null)
  const [addableGroups, setAddableGroups] = useState<ProjectParticipantGroup[]>(
    []
  )
  const [addableUsers, setAddableUsers] = useState<
    { id: string; email: string }[]
  >([])
  const [selectedAddableGroups, setSelectedAddableGroups] = useState<
    Set<string>
  >(new Set())
  const [selectedAddableUsers, setSelectedAddableUsers] = useState<Set<string>>(
    new Set()
  )
  const [isAddGroupsDialogOpen, setIsAddGroupsDialogOpen] =
    useState<boolean>(false)
  const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] =
    useState<boolean>(false)
  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])
  const [selectableItemNames, setSelectableItemNames] = useState<string[]>([])
  const [isNameDropdownOpen, setIsNameDropdownOpen] = useState(false)
  const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false)
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false)

  const navigate = useNavigate()
  const confirmDialog = useConfirmaDialogStableTarget()

  const fetchItemUtils = async () => {
    try {
      const [categories, units, itemNames] = await Promise.all([
        itemsService.getCategoriesByProjectId(currentProject.id),
        itemsService.getUnitsByProjectId(currentProject.id),
        itemsService.getItemNamesByProjectId(currentProject.id),
      ])
      setSelectableCategories(categories)
      setSelectableUnits(units)
      setSelectableItemNames(itemNames)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchRecipientUsers = async (notificationSettingId: string) => {
    try {
      const response = await notificationsService.getRecipientUsers(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        setRecipientUsers(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchRecipientGroups = async (notificationSettingId: string) => {
    try {
      const response = await notificationsService.getRecipientGroups(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        setRecipientGroups(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchUsers = async (notificationSettingId: string) => {
    try {
      const response = await notificationsService.getNonRecipientUsers(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        setAddableUsers(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchGroups = async (notificationSettingId: string) => {
    try {
      const response = await notificationsService.getNonRecipientGroups(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        setAddableGroups(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchNotificationSettingInfo = async (
    notificationSettingId: string
  ) => {
    try {
      const response = await notificationsService.getNotificationSettingById(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        const responseSetting = response.data as NotificationSetting
        setNotificationSetting(responseSetting)
        setSelectedScope(
          responseSetting.map_location
            ? NotificationScope.MAP_LOCATION
            : NotificationScope.PROJECT
        )
        setSelectedLocation(responseSetting.map_location ?? null)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    // Fetch groups and users from the backend
    if (notificationSettingId) {
      fetchNotificationSettingInfo(notificationSettingId)
      fetchRecipientUsers(notificationSettingId)
      fetchRecipientGroups(notificationSettingId)
      fetchItemUtils()
    }
  }, [])

  const toggleSelection = (
    id: string,
    setState: React.Dispatch<React.SetStateAction<Set<string>>>
  ) => {
    setState((prev) => {
      const newSet = new Set(prev)
      newSet.has(id) ? newSet.delete(id) : newSet.add(id)
      return newSet
    })
  }

  const handleSelectAllRecipientUsers = (checked: boolean) => {
    setSelectedRecipientUsers(
      checked ? new Set(recipientUsers.map((user) => user.id)) : new Set()
    )
  }

  const handleSelectAllRecipientGroups = (checked: boolean) => {
    setSelectedRecipientGroups(
      checked ? new Set(recipientGroups.map((group) => group.id)) : new Set()
    )
  }

  const handleSelectAllAddableUsers = (checked: boolean) => {
    setSelectedAddableUsers(
      checked ? new Set(addableUsers.map((user) => user.id)) : new Set()
    )
  }

  const handleSelectAllAddableGroups = (checked: boolean) => {
    setSelectedAddableGroups(
      checked ? new Set(addableGroups.map((group) => group.id)) : new Set()
    )
  }

  const handleDeleteSelectedRecipientUsers = async (
    notificationSettingId: string
  ) => {
    if (
      !(await confirmDialog({
        title: i18n.t('confirmDelete'),
        text: i18n.t('notifications.confirmDeleteRecipientUsers'),
      }))
    ) {
      return
    }
    try {
      const response = await notificationsService.deleteRecipientUsers(
        currentProject.id,
        notificationSettingId,
        Array.from(selectedRecipientUsers)
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setRecipientUsers((oldUsers) =>
          oldUsers.filter((user) => !selectedRecipientUsers.has(user.id))
        )
        setSelectedRecipientUsers(new Set())
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleDeleteSelectedRecipientGroups = async (
    notificationSettingId: string
  ) => {
    if (
      !(await confirmDialog({
        title: i18n.t('confirmDelete'),
        text: i18n.t('notifications.confirmDeleteRecipientGroups'),
      }))
    ) {
      return
    }
    try {
      const response = await notificationsService.deleteRecipientGroups(
        currentProject.id,
        notificationSettingId,
        Array.from(selectedRecipientGroups)
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setRecipientGroups((oldGroups) =>
          oldGroups.filter((group) => !selectedRecipientGroups.has(group.id))
        )
        setSelectedRecipientGroups(new Set())
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleAddSelectedRecipientUsers = async (
    notificationSettingId: string
  ) => {
    try {
      const response = await notificationsService.addRecipientUsers(
        currentProject.id,
        notificationSettingId,
        Array.from(selectedAddableUsers)
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        // get the addableUsers where addableUser.id is in selectedAddableUsers
        const newUsers = addableUsers.filter((user) =>
          selectedAddableUsers.has(user.id)
        )
        setRecipientUsers(recipientUsers.concat(newUsers))
        setSelectedAddableUsers(new Set())
        setIsAddUsersDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleAddSelectedRecipientGroups = async (
    notificationSettingId: string
  ) => {
    try {
      const response = await notificationsService.addRecipientGroups(
        currentProject.id,
        notificationSettingId,
        Array.from(selectedAddableGroups)
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        const newGroups = addableGroups.filter((group) =>
          selectedAddableGroups.has(group.id)
        )
        setRecipientGroups(recipientGroups.concat(newGroups))
        setSelectedAddableGroups(new Set())
        setIsAddGroupsDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleUpdateNotificationSettingGeneralInfo = async (
    notificationSettingId: string,
    formValues: FormValues
  ) => {
    if (selectedScope === NotificationScope.MAP_LOCATION && !selectedLocation) {
      toast.error(i18n.t('noLocationSelected'))
      return
    }
    try {
      const response =
        await notificationsService.updateNotificationSettingGeneralInfo(
          currentProject.id,
          notificationSettingId,
          {
            item_name: formValues.item_name,
            min_threshold: formValues.min_threshold,
            category: formValues.category,
            unit: formValues.unit,
            receive_email: formValues.receive_email,
            map_location_id:
              selectedScope === NotificationScope.MAP_LOCATION
                ? selectedLocation!.id
                : undefined,
          }
        )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleDeleteNotificationSetting = async (
    notificationSettingId: string
  ) => {
    if (
      !(await confirmDialog({
        title: i18n.t('confirmDelete'),
        text: i18n.t('notifications.confirmDeleteNotificationSetting'),
      }))
    ) {
      return
    }
    try {
      const response = await notificationsService.deleteNotificationSetting(
        currentProject.id,
        notificationSettingId
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        navigate(
          i18n.t('paths.projects') +
            '/' +
            currentProject.id +
            '/' +
            i18n.t('paths.notifications')
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  if (!notificationSetting) {
    return null
  }

  return (
    <div className="mx-2">
      <Card className="mt-2">
        <CardContent>
          <CardHeader className="flex flex-row flex-wrap gap-2 justify-between">
            <div>
              <CardTitle>
                {i18n.t('notifications.modifyNotificationSetting')}
              </CardTitle>
            </div>
            <div>
              <Button
                variant={'destructive'}
                onClick={() =>
                  handleDeleteNotificationSetting(notificationSetting.id)
                }
              >
                {i18n.t('notifications.deleteNotificationSetting')}
              </Button>
            </div>
          </CardHeader>
          <Tabs defaultValue="general">
            <TabsList className="flex items-center justify-center flex-wrap h-auto space-y-1">
              <TabsTrigger value="general">
                {i18n.t('notifications.general')}
              </TabsTrigger>
              <TabsTrigger value="groups">
                {i18n.t('notifications.recipientGroups')}
              </TabsTrigger>
              <TabsTrigger value="users">
                {i18n.t('notifications.recipientUsers')}
              </TabsTrigger>
            </TabsList>
            <Formik
              initialValues={{
                item_name: notificationSetting.item_name,
                min_threshold: notificationSetting.min_stock_threshold,
                unit: notificationSetting.unit,
                category: notificationSetting.category,
                receive_email: notificationSetting.receive_email,
              }}
              validationSchema={validationSchemaNotificationGeneralInfo}
              onSubmit={(values) =>
                handleUpdateNotificationSettingGeneralInfo(
                  notificationSetting.id,
                  values
                )
              }
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <Form className="space-y-4">
                  <TabsContent value="general">
                    <div>
                      <Label>{i18n.t('notifications.scope')}</Label>
                      <Select
                        value={selectedScope}
                        onValueChange={(value) =>
                          setSelectedScope(value as NotificationScope)
                        }
                      >
                        <SelectTrigger>
                          <SelectValue
                            placeholder={i18n.t('notifications.selectScope')}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={NotificationScope.PROJECT}>
                            {i18n.t('notifications.scopes.entireProject')}
                          </SelectItem>
                          <SelectItem value={NotificationScope.MAP_LOCATION}>
                            {i18n.t('notifications.scopes.mapLocation')}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    {selectedScope === NotificationScope.MAP_LOCATION && (
                      <div className="flex flex-col gap-2 my-2">
                        <Label>
                          {i18n.t('notifications.selectMapLocation')}
                        </Label>
                        <p>
                          {i18n.t('selectedLocation')}:{' '}
                          {selectedLocation
                            ? selectedLocation.title
                            : i18n.t('noLocationSelected')}
                        </p>
                        <div>
                          <SelectMapLocationDialog
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                          />
                        </div>
                      </div>
                    )}
                    <div className="relative">
                      <Label htmlFor="name">{i18n.t('itemName')}</Label>
                      <Field
                        type="text"
                        name="item_name"
                        autoComplete="off"
                        as={Input}
                        onFocus={() => setIsNameDropdownOpen(true)}
                        onBlur={() => setIsNameDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('item_name', e.target.value)
                          setIsNameDropdownOpen(true)
                        }}
                        value={values.item_name}
                      />
                      {isNameDropdownOpen && (
                        <List>
                          {selectableItemNames
                            .filter((name) =>
                              name
                                .toLowerCase()
                                .includes(values.item_name.toLowerCase())
                            )
                            .map((name) => (
                              <ListItem
                                key={name}
                                onMouseDown={() => {
                                  setFieldValue('item_name', name)
                                  setIsNameDropdownOpen(false)
                                }}
                              >
                                {name}
                              </ListItem>
                            ))}
                        </List>
                      )}
                      <FormErrorMessage name="item_name" />
                    </div>
                    <Label>{i18n.t('minThresholdAmount')}</Label>
                    <Field
                      type="number"
                      min={0}
                      step="any"
                      name="min_threshold"
                      as={Input}
                    />
                    <FormErrorMessage name="min_threshold" />
                    <div className="relative">
                      <Label htmlFor="unit">{i18n.t('unit')}</Label>
                      <Field
                        type="text"
                        name="unit"
                        autoComplete="off"
                        as={Input}
                        onFocus={() => setIsUnitDropdownOpen(true)}
                        onBlur={() => setIsUnitDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('unit', e.target.value)
                          setIsUnitDropdownOpen(true)
                        }}
                        value={values.unit}
                      />
                      {isUnitDropdownOpen && (
                        <List>
                          {selectableUnits
                            .filter((name) =>
                              name
                                .toLowerCase()
                                .includes((values.unit ?? '').toLowerCase())
                            )
                            .map((name) => (
                              <ListItem
                                key={name}
                                onMouseDown={() => {
                                  setFieldValue('unit', name)
                                  setIsUnitDropdownOpen(false)
                                }}
                              >
                                {name}
                              </ListItem>
                            ))}
                        </List>
                      )}
                      <FormErrorMessage name="unit" />
                    </div>
                    <div className="relative">
                      <Label htmlFor="category">{i18n.t('category')}</Label>
                      <Field
                        type="text"
                        name="category"
                        autoComplete="off"
                        as={Input}
                        onFocus={() => setIsCategoryDropdownOpen(true)}
                        onBlur={() => setIsCategoryDropdownOpen(false)} // Hide dropdown with delay to allow click selection
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('category', e.target.value)
                          setIsCategoryDropdownOpen(true)
                        }}
                        value={values.category}
                      />
                      {isCategoryDropdownOpen && (
                        <List>
                          {selectableCategories
                            .filter((name) =>
                              name
                                .toLowerCase()
                                .includes((values.category ?? '').toLowerCase())
                            )
                            .map((name) => (
                              <ListItem
                                key={name}
                                onMouseDown={() => {
                                  setFieldValue('category', name)
                                  setIsCategoryDropdownOpen(false)
                                }}
                              >
                                {name}
                              </ListItem>
                            ))}
                        </List>
                      )}
                      <FormErrorMessage name="category" />
                    </div>
                    <div className="mt-4 flex gap-1 items-center">
                      <Label>{i18n.t('notifications.receiveEmail')}</Label>
                      <Checkbox
                        name="receive_email"
                        checked={values.receive_email}
                        onCheckedChange={(checked: any) => {
                          setFieldValue('receive_email', checked)
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <PendingSubmitButton
                        isSubmitting={isSubmitting}
                        buttonText={i18n.t('notifications.saveGeneralInfo')}
                      />
                    </div>
                  </TabsContent>
                </Form>
              )}
            </Formik>
            <TabsContent value="groups">
              <div className="flex flex-wrap gap-2 justify-between">
                <Button
                  disabled={selectedRecipientGroups.size === 0}
                  variant={'destructive'}
                  onClick={() =>
                    handleDeleteSelectedRecipientGroups(notificationSetting.id)
                  }
                >
                  {i18n.t('deleteSelected')}
                </Button>
                <Dialog
                  open={isAddGroupsDialogOpen}
                  onOpenChange={async (open) => {
                    if (open) {
                      fetchGroups(notificationSetting.id)
                    }
                    setIsAddGroupsDialogOpen(open)
                  }}
                >
                  <DialogTrigger asChild>
                    <Button>
                      {i18n.t('notifications.addRecipientGroups')}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>
                        {i18n.t('notifications.addRecipientGroups')}
                      </DialogTitle>
                    </DialogHeader>
                    <Separator className="my-2" />
                    <div>
                      <Button
                        disabled={selectedAddableGroups.size === 0}
                        onClick={() =>
                          handleAddSelectedRecipientGroups(
                            notificationSetting.id
                          )
                        }
                      >
                        {i18n.t('saveSelected')}
                      </Button>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>
                              <Checkbox
                                className="mr-1"
                                checked={addableGroups.every((group) =>
                                  selectedAddableGroups.has(group.id)
                                )}
                                onCheckedChange={(checked) =>
                                  handleSelectAllAddableGroups(
                                    checked as boolean
                                  )
                                }
                              />
                              {i18n.t('selectAll')}
                            </TableHead>
                            <TableHead>{i18n.t('groupName')}</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {addableGroups.map((group) => (
                            <TableRow key={group.id}>
                              <TableCell>
                                <Checkbox
                                  checked={selectedAddableGroups.has(group.id)}
                                  onCheckedChange={() =>
                                    toggleSelection(
                                      group.id,
                                      setSelectedAddableGroups
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>{group.name}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>
                      <Checkbox
                        className="mr-1"
                        checked={recipientGroups.every((group) =>
                          selectedRecipientGroups.has(group.id)
                        )}
                        onCheckedChange={(checked) =>
                          handleSelectAllRecipientGroups(checked as boolean)
                        }
                      />
                      {i18n.t('selectAll')}
                    </TableHead>
                    <TableHead>{i18n.t('groupName')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {recipientGroups.map((group) => (
                    <TableRow key={group.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRecipientGroups.has(group.id)}
                          onCheckedChange={() =>
                            toggleSelection(
                              group.id,
                              setSelectedRecipientGroups
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>{group.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabsContent>

            <TabsContent value="users">
              <div className="flex flex-wrap gap-2 justify-between">
                <Button
                  disabled={selectedRecipientUsers.size === 0}
                  variant={'destructive'}
                  onClick={() =>
                    handleDeleteSelectedRecipientUsers(notificationSetting.id)
                  }
                >
                  {i18n.t('deleteSelected')}
                </Button>
                <Dialog
                  open={isAddUsersDialogOpen}
                  onOpenChange={async (open) => {
                    if (open) {
                      fetchUsers(notificationSetting.id)
                    }
                    setIsAddUsersDialogOpen(open)
                  }}
                >
                  <DialogTrigger asChild>
                    <Button>{i18n.t('notifications.addRecipientUsers')}</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>
                        {i18n.t('notifications.addRecipientUsers')}
                      </DialogTitle>
                    </DialogHeader>
                    <Separator className="my-2" />
                    <div>
                      <Button
                        disabled={selectedAddableUsers.size === 0}
                        onClick={() =>
                          handleAddSelectedRecipientUsers(
                            notificationSetting.id
                          )
                        }
                      >
                        {i18n.t('saveSelected')}
                      </Button>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>
                              <Checkbox
                                className="mr-1"
                                checked={addableUsers.every((group) =>
                                  selectedAddableUsers.has(group.id)
                                )}
                                onCheckedChange={(checked) =>
                                  handleSelectAllAddableUsers(
                                    checked as boolean
                                  )
                                }
                              />
                              {i18n.t('selectAll')}
                            </TableHead>
                            <TableHead>{i18n.t('email')}</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {addableUsers.map((user) => (
                            <TableRow key={user.id}>
                              <TableCell>
                                <Checkbox
                                  checked={selectedAddableUsers.has(user.id)}
                                  onCheckedChange={() =>
                                    toggleSelection(
                                      user.id,
                                      setSelectedAddableUsers
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>{user.email}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>
                      <Checkbox
                        className="mr-1"
                        checked={recipientUsers.every((user) =>
                          selectedRecipientUsers.has(user.id)
                        )}
                        onCheckedChange={(checked) =>
                          handleSelectAllRecipientUsers(checked as boolean)
                        }
                      />
                      {i18n.t('selectAll')}
                    </TableHead>
                    <TableHead>{i18n.t('email')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {recipientUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRecipientUsers.has(user.id)}
                          onCheckedChange={() =>
                            toggleSelection(user.id, setSelectedRecipientUsers)
                          }
                        />
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  )
}

export default ModifyNotificationSettingView
