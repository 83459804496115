import AddNewNotificationSettingForm from 'src/components/Notifications/AddNewNotificationSettingForm'

const AddNewNotificationSettingView = () => {
  return (
    <div className="mx-2">
      <AddNewNotificationSettingForm />
    </div>
  )
}

export default AddNewNotificationSettingView
