import { Dispatch, SetStateAction, useState } from 'react'
import { Blueprint, Bubble, Item } from 'src/lib/types'
import SingleBubbleDialog from '../Bubbles/SingleBubbleDialog'
import { Button } from '../ui/button'
import { Undo2, ZoomIn, ZoomOut } from 'lucide-react'
import { Coordinates } from './AddOrUpdateBubbleDialog'
import { BsTools } from 'react-icons/bs'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { cn } from 'src/lib/utils'

interface Props {
  blueprint?: Blueprint
  imageSource: string
  bubbles?: Bubble[]
  setBubbles?: Dispatch<SetStateAction<Bubble[]>>
  handleImageClickFunction?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void
  addBubbleCoordinates?: Coordinates
  selectableBubbles?: Bubble[]
  selectedBubble?: Bubble | null
  setSelectedBubble?: Dispatch<SetStateAction<Bubble | null>>
  item?: Item
}

const DisplaySingleBlueprintImage = ({
  blueprint,
  imageSource,
  bubbles,
  setBubbles,
  handleImageClickFunction,
  addBubbleCoordinates,
  selectableBubbles,
  setSelectedBubble,
  selectedBubble,
  item,
}: Props) => {
  const initialScale = 1
  const [scale, setScale] = useState<number>(initialScale)
  const [imageLoading, setImageLoading] = useState<boolean>(true)

  const handleScaleChange = (event: any) => {
    setScale(event.instance.transformState.scale)
  }

  const handleImageLoad = (resetTransform: () => void) => {
    resetTransform()
    setImageLoading(false)
  }

  return (
    <TransformWrapper
      doubleClick={{
        excluded: [],
        step: 0.7,
      }}
      panning={{
        activationKeys: [],
        excluded: [],
      }}
      pinch={{
        excluded: [],
        step: 5,
      }}
      wheel={{
        activationKeys: [],
        excluded: [],
        smoothStep: 0.001,
        step: 0.2,
      }}
      minScale={0.05}
      maxScale={100}
      initialScale={initialScale}
      centerOnInit
      onTransformed={(e) => handleScaleChange(e)}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className="relative w-full h-[80svh] md:h-full">
          <div className="absolute top-2 right-2 z-10 flex flex-col gap-2">
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => zoomIn()}
            >
              <ZoomIn />
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => zoomOut()}
            >
              <ZoomOut />
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => resetTransform()}
            >
              <Undo2 />
            </Button>
          </div>
          <div className="w-full h-full">
            <TransformComponent
              wrapperStyle={{ height: '100%', width: '100%' }}
            >
              {imageLoading && <SmallLoadingCircleOnly />}
              <div className="relative w-full h-full">
                <img
                  src={imageSource}
                  data-testid={`blueprint-image-${blueprint?.title}`}
                  alt="blueprint"
                  className={`md:max-h-[55vh] 2xl:max-h-[60vh] block object-contain transition-opacity duration-300 ${imageLoading ? 'opacity-0' : 'opacity-100'}`}
                  draggable={false}
                  onClick={handleImageClickFunction ?? undefined}
                  style={{
                    pointerEvents: 'auto', // Ensure pointer events are enabled
                  }}
                  onLoad={() => handleImageLoad(resetTransform)}
                />
                {addBubbleCoordinates && (
                  <div
                    style={{
                      position: 'absolute',
                      top: `${addBubbleCoordinates.y * 100}%`,
                      left: `${addBubbleCoordinates.x * 100}%`,
                      transform: `translate(-50%, -50%) scale(${1 / scale})`, // Adjust bubble scaling based on image scale
                    }}
                  >
                    <Button
                      data-testid="bubble-to-add"
                      className="rounded-full"
                      type="button"
                    >
                      <BsTools />
                    </Button>
                  </div>
                )}
                {/* Indicators */}
                {blueprint &&
                  bubbles?.map((bubble) => (
                    <div
                      className={`transition-opacity duration-300 ${imageLoading ? 'opacity-0' : 'opacity-100'}`}
                      key={bubble.id}
                      style={{
                        position: 'absolute',
                        top: `${bubble.y * 100}%`,
                        left: `${bubble.x * 100}%`,
                        transform: `translate(-50%, -50%) scale(${1 / scale})`, // Adjust bubble scaling based on image scale
                      }}
                    >
                      {handleImageClickFunction !== undefined ? (
                        <Button className="rounded-full" disabled>
                          <BsTools />
                        </Button>
                      ) : (
                        <SingleBubbleDialog
                          blueprint={blueprint}
                          bubble={bubble}
                          setBubbles={setBubbles}
                          bubbles={bubbles}
                          imageUrl={imageSource}
                        />
                      )}
                    </div>
                  ))}
                {/*Selectable bubbles (e.g. moving an item) */}
                {selectableBubbles &&
                  setSelectedBubble &&
                  selectableBubbles.map((selectableBubble) => (
                    <div
                      key={selectableBubble.id}
                      style={{
                        position: 'absolute',
                        top: `${selectableBubble.y * 100}%`,
                        left: `${selectableBubble.x * 100}%`,
                        transform: `translate(-50%, -50%) scale(${1 / scale})`, // Adjust bubble scaling based on image scale
                      }}
                    >
                      <Button
                        disabled={item?.bubble_id === selectableBubble.id}
                        className={cn(
                          'rounded-full',
                          selectableBubble.id === selectedBubble?.id
                            ? 'border-4 border-red-500'
                            : ''
                        )}
                        type="button"
                        data-testid={`selectable-bubble-${selectableBubble.title}`}
                        onClick={() => {
                          setSelectedBubble(selectableBubble)
                        }}
                      >
                        <BsTools />
                      </Button>
                    </div>
                  ))}
              </div>
            </TransformComponent>
          </div>
        </div>
      )}
    </TransformWrapper>
  )
}

export default DisplaySingleBlueprintImage
