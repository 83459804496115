import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { Field, Form, Formik } from 'formik'
import { Input } from '../ui/input'
import * as Yup from 'yup'
import { Label } from '../ui/label'
import { displayResponseErrorMessage } from 'src/lib/utils'
import projectParticipantGroupService from 'src/services/ProjectParticipantGroups/projectParticipantGroupService'
import { useAppContext } from 'src/context/AppProvider'
import { toast } from 'react-toastify'
import { ProjectParticipantGroup } from 'src/lib/types'
import { iconAndTextStyling } from 'src/constants'
import { Edit, Plus } from 'lucide-react'

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(i18n.t('fieldValidation.required')),
  })

interface ProjectParticipantGroupFormValues {
  name: string
}

interface Props {
  setGroups: Dispatch<SetStateAction<ProjectParticipantGroup[]>>
  group?: ProjectParticipantGroup
}

const AddOrUpdateNewProjectParticipantGroupDialog = ({
  setGroups,
  group,
}: Props) => {
  const { currentProject } = useAppContext()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const initialValues: ProjectParticipantGroupFormValues = {
    name: group ? group.name : '',
  }

  const handleSubmit = async (values: ProjectParticipantGroupFormValues) => {
    try {
      // modify group
      if (group) {
        const response = await projectParticipantGroupService.updateGroupData(
          currentProject.id,
          group.id,
          { name: values.name }
        )

        if (response.status === 200) {
          const newGroup = response.data as ProjectParticipantGroup
          toast.success(i18n.t('successGeneric'))
          setGroups((oldGroups) =>
            oldGroups.map((g) => (g.id === newGroup.id ? newGroup : g))
          )
          setIsDialogOpen(false)
        }
      } else {
        // add new group
        const response =
          await projectParticipantGroupService.addNewParticipantGroup(
            { name: values.name },
            currentProject.id
          )

        if (response.status === 200) {
          const newGroup = response.data as ProjectParticipantGroup
          toast.success(i18n.t('successGeneric'))
          setGroups((oldGroups) => oldGroups.concat(newGroup))
          setIsDialogOpen(false)
        }
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={(open) => setIsDialogOpen(open)}>
      <DialogTrigger asChild>
        <Button
          className={iconAndTextStyling}
          variant={group ? 'secondary' : 'default'}
        >
          {group ? (
            <>
              <Edit />
            </>
          ) : (
            <>
              <Plus />
              {i18n.t('createGroup')}
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {group ? i18n.t('modifyGroup') : i18n.t('createGroup')}
          </DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              <div>
                <Label className="block text-sm font-medium">
                  {i18n.t('groupName')}
                </Label>
                <Field
                  as={Input}
                  name="name"
                  placeholder={i18n.t('groupName')}
                  className="mt-1 block w-full"
                />
                <FormErrorMessage name="name" />
              </div>

              <PendingSubmitButton
                isSubmitting={isSubmitting}
                buttonText={i18n.t('save')}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateNewProjectParticipantGroupDialog
