import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import {
  AdditionalInfoField,
  AdditionalInfoFieldType,
  Item,
} from 'src/lib/types'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Separator } from '../ui/separator'
import { Field, Form, Formik } from 'formik'
import { validationSchemaAddAdditionalInfo } from 'src/lib/validationSchemas'
import { Label } from '../ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { ChevronsUpDown } from 'lucide-react'
import { useAppContext } from 'src/context/AppProvider'
import { displayResponseErrorMessage } from 'src/lib/utils'
import itemsService from 'src/services/Items/items'
import { commandItemStyles } from 'src/constants'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { toast } from 'react-toastify'
import additionalInfoFieldsService from 'src/services/AdditionalInfoFields/additionalInfoFieldsService'
import { Textarea } from '../ui/textarea'
import { Input } from '../ui/input'

interface Props {
  item: Item
  setItems: Dispatch<SetStateAction<Item[]>>
  additionalInfoField?: AdditionalInfoField
  renderInDropDownMenu?: boolean
}

const AddOrUpdateAdditionalInfoFieldDialog = ({
  item,
  setItems,
  additionalInfoField,
  renderInDropDownMenu,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { currentProject } = useAppContext()
  const projectId = currentProject.id
  const [selectableKeys, setSelectableKeys] = useState<string[]>([])
  const [keyQuery, setKeyQuery] = useState<string>('')

  const [isKeyPopoverOpen, setIsKeyPopoverOpen] = useState<boolean>(false)

  const handleSubmit = async (values: {
    field_key: string
    field_value: string
    field_type: AdditionalInfoFieldType
  }) => {
    try {
      if (additionalInfoField) {
        // Update field value
        const response =
          await additionalInfoFieldsService.updateAdditionalInfoByItem(
            item.id,
            projectId,
            additionalInfoField.id,
            {
              field_value: values.field_value,
            }
          )
        const updatedField = response.data as AdditionalInfoField
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) =>
          oldItems.map((oldItem) =>
            oldItem.id === item.id
              ? {
                  ...oldItem,
                  additional_info_fields: oldItem.additional_info_fields?.map(
                    (field) =>
                      field.id === additionalInfoField.id ? updatedField : field
                  ),
                }
              : oldItem
          )
        )
        setDialogOpen(false)
      } else {
        // add new additional info field
        const response = await itemsService.addAdditionalInfoToItem(
          item.id,
          projectId,
          {
            ...values,
          }
        )
        const addedInfoField = response.data as AdditionalInfoField
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) =>
          oldItems.map((oldItem) =>
            oldItem.id === item.id
              ? {
                  ...oldItem,
                  additional_info_fields: (
                    oldItem.additional_info_fields ?? []
                  ).concat(addedInfoField),
                }
              : oldItem
          )
        )
        setDialogOpen(false)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchAdditionalInfoKeysAndValues = async () => {
    try {
      const response =
        await additionalInfoFieldsService.getAdditionalInfoFieldDataByProjectId(
          projectId
        )
      if (response.status === 200) {
        const res = response.data
        const uniqueKeys = res.keys
        setSelectableKeys(uniqueKeys)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        setDialogOpen(open)
        if (open) {
          await fetchAdditionalInfoKeysAndValues()
        }
      }}
    >
      <DialogTrigger asChild>
        {renderInDropDownMenu ? (
          <DropdownMenuItem
            onSelect={(event) => {
              event.preventDefault()
            }}
          >
            {additionalInfoField
              ? i18n.t('modify')
              : i18n.t('additionalFields.addNewAdditionalInfoField')}
          </DropdownMenuItem>
        ) : (
          <Button>
            {additionalInfoField
              ? i18n.t('modify')
              : i18n.t('additionalFields.addNewAdditionalInfoField')}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {additionalInfoField
              ? i18n.t('additionalFields.updateAdditionalInfoFieldForItem')
              : i18n.t(
                  'additionalFields.addNewAdditionalInfoFieldForItem'
                )}{' '}
            {item.name}
          </DialogTitle>
          <DialogDescription>
            {i18n.t('additionalFields.addNewAdditionalInfoFieldDescription')}
          </DialogDescription>
          <Separator />
        </DialogHeader>
        <div>
          <Formik
            initialValues={{
              field_key: additionalInfoField?.key ?? '',
              field_value: additionalInfoField?.value ?? '',
              field_type:
                additionalInfoField?.type ?? AdditionalInfoFieldType.STRING,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchemaAddAdditionalInfo}
          >
            {({ isSubmitting, setValues, values, setFieldValue }) => (
              <Form className="flex flex-col gap-2">
                <div>
                  <Label>{i18n.t('additionalFields.fieldType')}</Label>
                  <Select
                    disabled={!!additionalInfoField}
                    value={values.field_type}
                    onValueChange={(value) =>
                      setFieldValue(
                        'field_type',
                        value as AdditionalInfoFieldType
                      )
                    }
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={i18n.t('additionalFields.selectFieldType')}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={AdditionalInfoFieldType.STRING}>
                        {i18n.t('additionalFields.fieldTypes.string')}
                      </SelectItem>
                      <SelectItem value={AdditionalInfoFieldType.LINK}>
                        {i18n.t('additionalFields.fieldTypes.link')}
                      </SelectItem>
                      <SelectItem
                        value={AdditionalInfoFieldType.MULTILINE_STRING}
                      >
                        {i18n.t('additionalFields.fieldTypes.multiline_string')}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Label>{i18n.t('additionalFields.fieldKeyTitle')}</Label>
                <Popover
                  open={isKeyPopoverOpen}
                  onOpenChange={setIsKeyPopoverOpen}
                  modal={true}
                >
                  <PopoverTrigger asChild disabled={!!additionalInfoField}>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isKeyPopoverOpen}
                      className="w-full justify-between"
                      data-testid="field-key-trigger"
                    >
                      <span className="text-sm font-normal">
                        {values.field_key ||
                          i18n.t('additionalFields.addFieldKey')}
                      </span>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0" align="start">
                    <Command className="max-h-[40vh]" value={values.field_key}>
                      <CommandInput
                        value={keyQuery}
                        onValueChange={(val) => setKeyQuery(val)}
                        data-testid="field-key-input"
                        placeholder={i18n.t('additionalFields.addFieldKey')}
                      />
                      <CommandList>
                        <CommandGroup>
                          {keyQuery && !selectableKeys.includes(keyQuery) && (
                            <CommandItem
                              className={commandItemStyles}
                              key={keyQuery}
                              value={keyQuery}
                              data-testid={`add-field-key-option-${keyQuery}`}
                              onSelect={(currentValue) => {
                                setValues((oldValues) => ({
                                  ...oldValues,
                                  field_key: currentValue,
                                }))
                                setIsKeyPopoverOpen(false)
                              }}
                            >
                              {i18n.t('add')} "{keyQuery}"
                            </CommandItem>
                          )}
                          {selectableKeys.map((sug, index) => (
                            <CommandItem
                              className={commandItemStyles}
                              key={index}
                              value={sug}
                              data-testid={`select-field-key-${sug}`}
                              onSelect={(currentValue) => {
                                setValues((oldValues) => ({
                                  ...oldValues,
                                  field_key: currentValue,
                                }))
                                setIsKeyPopoverOpen(false)
                              }}
                            >
                              {sug}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormErrorMessage name="field_key" />
                <Label>{i18n.t('additionalFields.fieldValueTitle')}</Label>
                <Field
                  id="field_value"
                  name="field_value"
                  placeholder={i18n.t('additionalFields.addFieldValue')}
                  as={
                    values.field_type ===
                    AdditionalInfoFieldType.MULTILINE_STRING
                      ? Textarea
                      : Input
                  }
                />
                <FormErrorMessage name="field_value" />
                <Separator className="my-2" />
                <DialogFooter className="my-2 flex gap-2">
                  <DialogClose asChild>
                    <Button type="button" variant={'destructive'}>
                      {i18n.t('cancel')}
                    </Button>
                  </DialogClose>
                  <PendingSubmitButton
                    data-testid="submit-add-tag-form-button"
                    buttonText={i18n.t('save')}
                    isSubmitting={isSubmitting}
                  />
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateAdditionalInfoFieldDialog
