import React, { useState, useEffect, useCallback } from 'react'
import i18n from 'src/i18n'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import { Badge } from 'src/components/ui/badge'
import { X } from 'lucide-react'
import { formatDateLocale } from 'src/lib/utils'
import { ImportedFile, EXPECTED_HEADERS } from 'src/lib/types'
import importService from 'src/services/Data/importService'
import FilePreviewTable from './FilePreviewTable'

interface Props {
  projectId: string
}

export const ImportProjectData = ({ projectId }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isPreview, setIsPreview] = useState(false)
  const [previewData, setPreviewData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [importedFiles, setImportedFiles] = useState<ImportedFile[]>([])
  const [isFetchingFiles, setIsFetchingFiles] = useState(true)

  const resetState = () => {
    setSelectedFile(null)
    setIsPreview(false)
    setPreviewData([])
    setIsLoading(false)
  }

  const fetchImportedFiles = useCallback(async () => {
    try {
      setIsFetchingFiles(true)
      const response = await importService.getImportedFiles(projectId)
      setImportedFiles(response)
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
    } finally {
      setIsFetchingFiles(false)
    }
  }, [projectId])

  useEffect(() => {
    fetchImportedFiles()
  }, [fetchImportedFiles])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const validExtensions = ['.xls', '.xlsx']
      const fileExtension = file.name
        .slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()

      if (!validExtensions.includes(`.${fileExtension}`)) {
        toast.error(i18n.t('data.fileTypeError'))
        resetState()
        return
      }

      setSelectedFile(file)
      setIsPreview(false)
      setPreviewData([])
      e.target.value = ''
    }
  }

  const handlePreview = async () => {
    if (!selectedFile) return
    setIsLoading(true)

    try {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target?.result
        if (data) {
          try {
            const workbook = XLSX.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const jsonData: any[] = XLSX.utils.sheet_to_json(sheet, {
              header: 1,
            })

            if (jsonData.length === 0) {
              toast.error(i18n.t('data.emptyFileError'))
              resetState()
              return
            }
            const headers = jsonData[0].map((header: any) =>
              header.toString().trim()
            )
            const formattedData = jsonData.slice(1).map((row) => {
              const obj: any = {}
              headers.forEach((header: string, index: number) => {
                obj[header] = row[index] ?? ''
              })
              return obj
            })
            const normalizedHeaders = headers.map((h: string) =>
              h.toLowerCase()
            )
            if (
              EXPECTED_HEADERS.length !== normalizedHeaders.length ||
              !EXPECTED_HEADERS.every((header) =>
                normalizedHeaders.includes(header)
              )
            ) {
              toast.error(i18n.t('data.fileFormatError'))
              resetState()
              return
            }
            setPreviewData(formattedData)
            setIsPreview(true)
          } catch (error) {
            toast.error(i18n.t('data.fileParsingError'))
          }
        }
        setIsLoading(false)
      }
      reader.readAsArrayBuffer(selectedFile)
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
      setIsLoading(false)
    }
  }

  const handleUpload = async () => {
    if (!selectedFile) return
    setIsLoading(true)

    try {
      await importService.importFile(selectedFile, projectId)
      resetState()
      toast.success(i18n.t('successGeneric'))
      fetchImportedFiles()
    } catch (error: any) {
      toast.error(
        error.response?.status === 400
          ? i18n.t('data.fileFormatError')
          : i18n.t('errorGeneric')
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleFileClick = async (fileId: string) => {
    setIsLoading(true)
    try {
      const fileContents = await importService.getFileContents(
        fileId,
        projectId
      )
      setPreviewData(fileContents)
      setIsPreview(true)
    } catch (error) {
      toast.error(i18n.t('error_fetching_file_contents'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteFile = async (fileId: string) => {
    setIsLoading(true)
    try {
      await importService.deleteImportedFile(fileId, projectId)
      toast.success(i18n.t('successGeneric'))
      fetchImportedFiles()
      if (isPreview) {
        resetState()
      }
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Card className="my-2" data-testid="project-import-card">
      <CardHeader>
        <CardTitle className="mb-2">{i18n.t('data.import')}</CardTitle>
        <CardDescription className="whitespace-break-spaces">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <Card>
                <CardHeader>
                  <CardTitle>{i18n.t('data.importedFiles')}</CardTitle>
                </CardHeader>
                <CardDescription className="p-4">
                  {isFetchingFiles ? (
                    <SmallLoadingCircleOnly />
                  ) : importedFiles.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {importedFiles.map((file) => (
                        <Badge
                          key={file.id}
                          variant={'secondary'}
                          className="max-w-fit cursor-pointer flex items-center gap-1"
                          data-testid={`imported-file-${file.id}`}
                          onClick={() => handleFileClick(file.id)}
                        >
                          {file.filename} - {formatDateLocale(file.uploaded_at)}
                          <button
                            className="hover:bg-red-300 rounded-md ml-1"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDeleteFile(file.id)
                            }}
                            title={i18n.t('data.deleteFile')}
                          >
                            <X size={14} />
                          </button>
                        </Badge>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">
                      {i18n.t('data.noImportedFiles')}
                    </p>
                  )}
                </CardDescription>
              </Card>

              <Card className="mt-4">
                <CardHeader>
                  <CardTitle>{i18n.t('selectFile')}</CardTitle>
                </CardHeader>
                <CardDescription className="p-4">
                  <Input
                    type="file"
                    accept=".xls,.xlsx"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                  {selectedFile && (
                    <p className="mt-2 text-sm text-gray-600">
                      {i18n.t('data.selectedFile')}: {selectedFile.name}
                    </p>
                  )}
                </CardDescription>
                <div className="p-4 flex gap-2">
                  <Button
                    variant="secondary"
                    onClick={resetState}
                    disabled={isLoading}
                  >
                    {i18n.t('cancel')}
                  </Button>
                  <Button
                    variant="default"
                    onClick={isPreview ? handleUpload : handlePreview}
                    disabled={!selectedFile || isLoading}
                  >
                    {isLoading ? (
                      <SmallLoadingCircleOnly />
                    ) : isPreview ? (
                      i18n.t('data.upload')
                    ) : (
                      i18n.t('preview')
                    )}
                  </Button>
                </div>
              </Card>
            </div>
            <Card className="flex-1">
              <CardHeader>
                <CardTitle>{i18n.t('preview')}</CardTitle>
              </CardHeader>
              <CardDescription>
                {isLoading ? (
                  <SmallLoadingCircleOnly />
                ) : (
                  <FilePreviewTable
                    previewData={previewData}
                    isPreview={isPreview}
                  />
                )}
              </CardDescription>
            </Card>
          </div>
        </CardDescription>
      </CardHeader>
    </Card>
  )
}
