import React, { useEffect, useMemo, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import { useDebounce } from 'use-debounce'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import chartService from 'src/services/Data/chartService'
import itemService from 'src/services/Items/items'
import { useAppContext } from 'src/context/AppProvider'
import { Label } from 'src/components/ui/label'
import { MultiSelect } from 'src/components/ui/multi-select'
import { Checkbox } from 'src/components/ui/checkbox'
import { StockOutItem } from 'src/lib/types'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'

const getFormattedDate = (date: Date) => date.toISOString().split('T')[0]

const StockoutChart: React.FC = () => {
  const { currentProject } = useAppContext()

  const [stockoutData, setStockoutData] = useState<StockOutItem[]>([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])
  const [allItemNames, setAllItemNames] = useState<string[]>([])
  const [showTop10, setShowTop10] = useState(false)
  const [wholeProject, setWholeProject] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(
    getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 7)))
  )
  const [endDate, setEndDate] = useState(getFormattedDate(new Date()))
  const [tempStartDate, setTempStartDate] = useState(startDate)
  const [tempEndDate, setTempEndDate] = useState(endDate)
  const [debouncedStartDate] = useDebounce(tempStartDate, 500)
  const [debouncedEndDate] = useDebounce(tempEndDate, 500)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (wholeProject && currentProject?.created_at) {
      setStartDate(getFormattedDate(new Date(currentProject.created_at)))
    } else {
      setStartDate(tempStartDate)
    }
  }, [wholeProject])

  useEffect(() => {
    setStartDate(debouncedStartDate)
    setEndDate(debouncedEndDate)
  }, [debouncedStartDate, debouncedEndDate])

  useEffect(() => {
    if (!currentProject?.id) return

    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await chartService.getStockoutData(
          currentProject.id,
          startDate,
          endDate
        )
        setStockoutData(result || [])
      } catch (err) {
        toast.error(i18n.t('errorGeneric'))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [startDate, endDate])

  useEffect(() => {
    if (!currentProject?.id) return
    const fetchFilterOptions = async () => {
      try {
        const [categories, items] = await Promise.all([
          itemService.getCategoriesByProjectId(currentProject.id),
          itemService.getItemNamesByProjectId(currentProject.id),
        ])
        setAllCategories(categories || [])
        setAllItemNames(items || [])
      } catch (err) {
        toast.error(i18n.t('errorGeneric'))
      }
    }
    fetchFilterOptions()
  }, [])

  const filteredData = useMemo(() => {
    return stockoutData.filter((item) => {
      const category = item.category || 'Others'
      return (
        (selectedCategories.length === 0 ||
          selectedCategories.includes(category)) &&
        (selectedItems.length === 0 || selectedItems.includes(item.name))
      )
    })
  }, [stockoutData, selectedCategories, selectedItems])

  const groupedByItem = useMemo(() => {
    const grouped: { [itemName: string]: number } = {}
    filteredData.forEach((item) => {
      grouped[item.name] = (grouped[item.name] || 0) + item.frequency
    })
    return grouped
  }, [filteredData])

  useEffect(() => {
    if (showTop10) {
      setSelectedCategories([])
      const topItems = [...stockoutData]
        .sort((a, b) => b.frequency - a.frequency)
        .slice(0, 10)
        .map((item) => item.name)
      setSelectedItems(topItems)
    } else {
      setSelectedCategories([])
      setSelectedItems([])
    }
  }, [showTop10, stockoutData])

  const barSeries = useMemo(() => {
    return [
      {
        type: 'bar',
        data: Object.entries(groupedByItem)
          .sort((a, b) => b[1] - a[1])
          .map(([name, freq]) => ({
            value: freq,
            name,
          })),
        itemStyle: {
          color: '#3498DB', // blue
        },
        label: {
          show: true,
          position: 'right',
        },
      },
    ]
  }, [groupedByItem])

  const chartOptions = {
    title: {
      text: `${i18n.t('data.stockOutChartTitle')}`,
      left: 'center',
      textStyle: { fontSize: 16, fontWeight: 'bold' },
    },
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        const label = params?.name
        const value = params?.value
        return `${label}: ${value} ${i18n.t('data.stockouts')}`
      },
    },
    xAxis: {
      type: 'value',
      name: 'Frequency',
      interval: 1,
      axisLabel: {
        formatter: '{value}',
      },
    },
    yAxis: {
      type: 'category',
      data: Object.keys(groupedByItem).sort(
        (a, b) => groupedByItem[b] - groupedByItem[a]
      ),
      name: 'Material',
    },
    series: barSeries,
    grid: {
      left: '20%',
      right: '10%',
      bottom: '10%',
    },
  }

  return (
    <div className="p-4 w-full">
      <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4 items-center p-4">
        <Label className="flex items-center w-full sm:w-auto">
          <Checkbox
            checked={wholeProject}
            onCheckedChange={(checked) => setWholeProject(checked === true)}
          />
          <span className="ml-2">{i18n.t('data.wholeProject')}</span>
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.startDate')}
          </span>
          <input
            type="date"
            value={tempStartDate}
            max={getFormattedDate(
              new Date(
                new Date(tempEndDate).setDate(
                  new Date(tempEndDate).getDate() - 1
                )
              )
            )}
            onChange={(e) => setTempStartDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-0"
          />
        </Label>
        <Label className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <span className="whitespace-nowrap p-1">
            {i18n.t('data.endDate')}
          </span>
          <input
            type="date"
            value={tempEndDate}
            min={getFormattedDate(
              new Date(
                new Date(tempStartDate).setDate(
                  new Date(tempStartDate).getDate() + 1
                )
              )
            )}
            max={getFormattedDate(new Date())}
            onChange={(e) => setTempEndDate(e.target.value)}
            disabled={wholeProject}
            className="border rounded p-2 ml-2 w-full sm:w-auto min-w-0"
          />
        </Label>
        <div className="w-full sm:w-auto">
          <MultiSelect
            key={showTop10 ? 'top10' : 'manual'}
            options={allCategories.map((category) => ({
              label: category,
              value: category,
            }))}
            value={selectedCategories}
            onValueChange={setSelectedCategories}
            disabled={showTop10}
            className="bg-white"
            placeholder={i18n.t('data.selectCategories')}
          />
        </div>
        <div className="w-full sm:w-auto">
          <MultiSelect
            key={showTop10 ? 'top10' : 'manual'}
            options={allItemNames.map((item) => ({
              label: item,
              value: item,
            }))}
            value={selectedItems}
            onValueChange={setSelectedItems}
            disabled={showTop10}
            placeholder={i18n.t('data.selectItems')}
            className="bg-white"
            maxCount={10}
          />
        </div>
        <Label className="flex items-center w-full sm:w-auto">
          <Checkbox
            checked={showTop10}
            onCheckedChange={(checked) => setShowTop10(checked === true)}
          />
          <span className="ml-2">{i18n.t('data.top10MostFrequent')}</span>
        </Label>
      </div>
      <div className="flex justify-center items-center w-full">
        {loading ? (
          <SmallLoadingCircleOnly />
        ) : barSeries[0].data.length === 0 ? (
          <p className="text-center text-gray-600">{i18n.t('data.noData')}</p>
        ) : (
          <ReactECharts
            key={JSON.stringify(barSeries)}
            option={chartOptions}
            style={{ width: '100%', minHeight: '500px' }}
          />
        )}
      </div>
    </div>
  )
}

export default StockoutChart
