import i18n from 'src/i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { DropdownMenuItem } from '../ui/dropdown-menu'
import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from '../ui/button'
import { Separator } from '../ui/separator'
import { Form, Formik } from 'formik'
import { Label } from '../ui/label'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { validationSchemaAddTag } from 'src/lib/validationSchemas'
import { displayResponseErrorMessage } from 'src/lib/utils'
import itemsService from 'src/services/Items/items'
import { useAppContext } from 'src/context/AppProvider'
import { Item } from 'src/lib/types'
import { toast } from 'react-toastify'
import tagsService from 'src/services/Tags/tags'
import { Badge } from '../ui/badge'
import { Alert } from '../ui/alert'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command'
import { ChevronsUpDown } from 'lucide-react'
import { commandItemStyles } from 'src/constants'

interface Props {
  item: Item
  setItems: Dispatch<SetStateAction<Item[]>>
  renderInDropDownMenu?: boolean
}

interface FormValues {
  tag_key: string
  tag_value: string
}

interface TagKeysAndValues {
  keys: string[]
  values: string[]
}

const AddNewTagDialog = ({ item, setItems, renderInDropDownMenu }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { currentProject } = useAppContext()
  const projectId = currentProject.id
  const [selectableTagKeys, setSelectableTagsKeys] = useState<string[]>([])
  const [selectableTagValues, setSelectableTagValues] = useState<string[]>([])
  const [keyQuery, setKeyQuery] = useState<string>('')
  const [valueQuery, setValueQuery] = useState<string>('')

  const [isTagKeyPopoverOpen, setIsTagKeyPopoverOpen] = useState<boolean>(false)
  const [isTagValuePopoverOpen, setIsTagValuePopoverOpen] =
    useState<boolean>(false)

  const handleSubmit = async (values: FormValues) => {
    const tagExistsInItem = tagAlreadyExistsInItem(values)
    if (tagExistsInItem) {
      toast.error(i18n.t('tagAlreadyExistsInItem'))
      return
    }
    try {
      const response = await itemsService.addTagToItem(item.id, projectId, {
        key: values.tag_key,
        value: values.tag_value === '' ? undefined : values.tag_value,
      })
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setDialogOpen(false)
        setItems((oldItems) =>
          oldItems.map((oldItem) =>
            oldItem.id === item.id
              ? { ...oldItem, tags: [...(oldItem.tags || []), response.data] } // Ensuring tags is always an array
              : oldItem
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchProjectTags = async () => {
    try {
      const response = await tagsService.getAllTagsByProjectId(projectId)
      if (response.status === 200) {
        const res = response.data as TagKeysAndValues
        // Returns unique tag keys and valus for the project
        const uniqueTagKeys = res.keys
        const uniqueTagValues = res.values
        setSelectableTagsKeys(uniqueTagKeys)
        setSelectableTagValues(uniqueTagValues)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const tagAlreadyExistsInItem = (values: FormValues): boolean => {
    if (!values.tag_key) {
      return false
    }

    if (values.tag_key && values.tag_value) {
      // Both need to match
      const matchingTagBoth = item.tags?.find(
        (tag) => tag.key === values.tag_key && tag.value === values.tag_value
      )
      if (matchingTagBoth) {
        return true
      } else {
        return false
      }
    }

    if (values.tag_key && !values.tag_value) {
      // tag key must match and value must be null
      const matchingTagValueNull = item.tags?.find(
        (tag) => tag.key === values.tag_key && tag.value === null
      )
      if (matchingTagValueNull) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
          await fetchProjectTags()
        } else {
          setDialogOpen(false)
        }
      }}
    >
      <DialogTrigger asChild>
        {renderInDropDownMenu ? (
          <DropdownMenuItem
            data-testid="open-add-tag-modal-button"
            onSelect={(event) => {
              event.preventDefault()
            }}
          >
            {i18n.t('addTag')}
          </DropdownMenuItem>
        ) : (
          <Button>{i18n.t('addTag')}</Button>
        )}
      </DialogTrigger>
      <DialogContent data-testid="add-tag-dialog-content">
        <DialogHeader>
          <DialogTitle>
            {i18n.t('addTagForItem')} {item.name}
          </DialogTitle>
          <DialogDescription>{i18n.t('addTagDescription')}</DialogDescription>
          <Separator />
        </DialogHeader>
        <div>
          <Formik
            initialValues={{
              tag_key: '',
              tag_value: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={() => validationSchemaAddTag()}
          >
            {({ isSubmitting, setValues, values }) => (
              <Form className="flex flex-col gap-2">
                <Label>{i18n.t('tagKey')}</Label>
                <Popover
                  open={isTagKeyPopoverOpen}
                  onOpenChange={setIsTagKeyPopoverOpen}
                  modal={true}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isTagKeyPopoverOpen}
                      className="w-full justify-between"
                      data-testid="tag-key-trigger"
                    >
                      <span className="text-sm font-normal">
                        {values.tag_key || i18n.t('addTagKey')}
                      </span>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0" align="start">
                    <Command className="max-h-[40vh]" value={values.tag_key}>
                      <CommandInput
                        value={keyQuery}
                        onValueChange={(val) => setKeyQuery(val)}
                        data-testid="tag-key-input"
                        placeholder={i18n.t('addTagKey')}
                      />
                      <CommandList>
                        <CommandGroup>
                          {keyQuery &&
                            !selectableTagKeys.includes(keyQuery) && (
                              <CommandItem
                                className={commandItemStyles}
                                key={keyQuery}
                                value={keyQuery}
                                data-testid={`add-tag-key-option-${keyQuery}`}
                                onSelect={(currentValue) => {
                                  setValues((oldValues) => ({
                                    ...oldValues,
                                    tag_key: currentValue,
                                  }))
                                  setIsTagKeyPopoverOpen(false)
                                }}
                              >
                                {i18n.t('add')} "{keyQuery}"
                              </CommandItem>
                            )}
                          {selectableTagKeys.map((sug, index) => (
                            <CommandItem
                              className={commandItemStyles}
                              key={index}
                              value={sug}
                              data-testid={`select-tag-key-${sug}`}
                              onSelect={(currentValue) => {
                                setValues((oldValues) => ({
                                  ...oldValues,
                                  tag_key: currentValue,
                                }))
                                setIsTagKeyPopoverOpen(false)
                              }}
                            >
                              {sug}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormErrorMessage name="tag_key" />
                <Label>{i18n.t('tagValueTitle')}</Label>
                <Popover
                  open={isTagValuePopoverOpen}
                  onOpenChange={setIsTagValuePopoverOpen}
                  modal={true}
                >
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={isTagValuePopoverOpen}
                      className="w-full justify-between"
                      data-testid="tag-value-trigger"
                    >
                      <span className="text-sm font-normal">
                        {values.tag_value || i18n.t('addTagValue')}
                      </span>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0" align="start">
                    <Command className="max-h-[40vh]" value={values.tag_key}>
                      <CommandInput
                        value={valueQuery}
                        onValueChange={(val) => setValueQuery(val)}
                        data-testid="tag-value-input"
                        placeholder={i18n.t('addTagValue')}
                      />
                      <CommandList>
                        <CommandGroup>
                          {valueQuery &&
                            !selectableTagValues.includes(valueQuery) && (
                              <CommandItem
                                className={commandItemStyles}
                                key={valueQuery}
                                value={valueQuery}
                                data-testid={`add-tag-value-option-${valueQuery}`}
                                onSelect={(currentValue) => {
                                  setValues((oldValues) => ({
                                    ...oldValues,
                                    tag_value: currentValue,
                                  }))
                                  setIsTagValuePopoverOpen(false)
                                }}
                              >
                                {i18n.t('add')} "{valueQuery}"
                              </CommandItem>
                            )}
                          {selectableTagValues.map((sug, index) => (
                            <CommandItem
                              className={commandItemStyles}
                              key={index}
                              value={sug}
                              data-testid={`select-tag-value-${sug}`}
                              onSelect={(currentValue) => {
                                setValues((oldValues) => ({
                                  ...oldValues,
                                  tag_value: currentValue,
                                }))
                                setIsTagValuePopoverOpen(false)
                              }}
                            >
                              {sug}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormErrorMessage name="tag_value" />
                {tagAlreadyExistsInItem(values) && (
                  <Alert
                    variant={'destructive'}
                    data-testid="tag-already-exists-in-item-alert"
                  >
                    {i18n.t('tagAlreadyExistsInItem')}
                  </Alert>
                )}
                <Separator className="my-2" />
                <div className="text-center flex flex-row flex-wrap gap-2">
                  <p>{i18n.t('preview')}: </p>
                  {values.tag_key ? (
                    <Badge className="max-w-fit" variant={'secondary'}>
                      {values.tag_key}
                      {values.tag_value ? `: ${values.tag_value}` : ''}
                    </Badge>
                  ) : (
                    <p>{i18n.t('fillTagToShowPreview')}</p>
                  )}
                </div>
                <Separator className="my-2" />
                <DialogFooter className="my-2 flex gap-2">
                  <DialogClose asChild>
                    <Button type="button" variant={'destructive'}>
                      {i18n.t('cancel')}
                    </Button>
                  </DialogClose>
                  <PendingSubmitButton
                    data-testid="submit-add-tag-form-button"
                    buttonText={i18n.t('save')}
                    isSubmitting={isSubmitting}
                  />
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AddNewTagDialog
