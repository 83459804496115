import React, { useState, useCallback } from 'react'
import i18n from 'src/i18n'
import Swal from 'sweetalert2'
import { ConfirmationDialogProps } from 'src/lib/types'

export const useConfirmDialogUnStableTarget = () => {
  const [result, setResult] = useState<boolean | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const prompt = useCallback(
    ({
      title,
      text,
      confirmButtonText = i18n.t('confirmDialog.accept'),
      cancelButtonText = i18n.t('confirmDialog.cancel'),
    }: ConfirmationDialogProps) => {
      setIsOpen(true)
      const dialogs = document.querySelectorAll('[role="dialog"]')
      let targetDialog = document.body

      if (dialogs.length > 0) {
        const lastDialog = dialogs[dialogs.length - 1]
        const isSweetAlert =
          lastDialog.classList.contains('swal2-popup') ||
          lastDialog.closest('.swal2-container')

        if (isSweetAlert && dialogs.length > 1) {
          targetDialog = dialogs[dialogs.length - 2] as HTMLElement
        } else if (!isSweetAlert) {
          targetDialog = lastDialog as HTMLElement
        }
      }

      Swal.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText,
        cancelButtonText,
        position: 'top',
        target: targetDialog as HTMLElement,
        willOpen: () => (document.body.style.overflow = 'hidden'),
        willClose: () => (document.body.style.overflow = 'auto'),
      }).then((result) => {
        setResult(result.isConfirmed)
        setIsOpen(false)
      })
    },
    []
  )

  const reset = useCallback(() => {
    setResult(null)
  }, [])

  return { prompt, result, isOpen, reset }
}

export const useConfirmaDialogStableTarget = () => {
  const showConfirmationDialog = async ({
    title,
    text,
    confirmButtonText = i18n.t('confirmDialog.accept'),
    cancelButtonText = i18n.t('confirmDialog.cancel'),
  }: ConfirmationDialogProps): Promise<boolean> => {
    const dialogs = document.querySelectorAll('[role="dialog"]')
    let targetDialog = document.body

    if (dialogs.length > 0) {
      const lastDialog = dialogs[dialogs.length - 1]
      const isSweetAlert =
        lastDialog.classList.contains('swal2-popup') ||
        lastDialog.closest('.swal2-container')

      if (isSweetAlert && dialogs.length > 1) {
        targetDialog = dialogs[dialogs.length - 2] as HTMLElement
      } else if (!isSweetAlert) {
        targetDialog = lastDialog as HTMLElement
      }
    }

    const result = await Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: '#2563eb',
      cancelButtonColor: '#ee4444',
      confirmButtonText,
      cancelButtonText,
      position: 'top',
      target: targetDialog as HTMLElement,
    })
    return result.isConfirmed
  }

  return showConfirmationDialog
}
