import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import i18n from 'src/i18n'
import {
  CurrentUserState,
  Project,
  Customership,
  MapViewerMode,
  MapLocation,
} from 'src/lib/types'
import projectsService from 'src/services/Projects/projects'
import usersService from 'src/services/Users/users'
import customershipService from 'src/services/Customerships/customershipService'

interface AppProviderType {
  currentProject: Project
  currentCustomership: Customership
  currentUser: CurrentUserState
  initializeCurrentProject: (projectId: string) => Promise<void>
  initializeCurrentCustomership: (customershipId: string) => Promise<void>
  setCurrentProject: Dispatch<SetStateAction<Project>>
  setCurrentCustomership: Dispatch<SetStateAction<Customership>>
  setCurrentUser: Dispatch<SetStateAction<CurrentUserState>>
  initializeCurrentUser: (
    projectId?: string,
    customershipId?: string
  ) => Promise<void>
  renderRefreshAlert: boolean
  setRenderRefreshAlert: Dispatch<SetStateAction<boolean>>
  mapViewerMode: MapViewerMode
  setMapViewerMode: Dispatch<SetStateAction<MapViewerMode>>
  handleMapViewerModeChange: (
    mode: MapViewerMode,
    selectedLocation?: MapLocation
  ) => void
}

const AppContext = createContext<AppProviderType | undefined>(undefined)

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentProject, setCurrentProject] = useState<Project>({} as Project)
  const [currentCustomership, setCurrentCustomership] = useState<Customership>(
    {} as Customership
  )
  const [currentUser, setCurrentUser] = useState<CurrentUserState>(
    {} as CurrentUserState
  )
  const [renderRefreshAlert, setRenderRefreshAlert] = useState<boolean>(false)
  const [mapViewerMode, setMapViewerMode] = useState<MapViewerMode>(
    MapViewerMode.NORMAL
  )

  const location = useLocation()
  const currentPath = location.pathname
  useEffect(() => {
    if (!currentPath.startsWith(i18n.t('paths.projects'))) {
      setCurrentProject({} as Project)
    }
    if (
      !currentPath.startsWith(
        i18n.t('paths.customershipPortal') +
          '/' +
          i18n.t('paths.ownCustomerships')
      )
    ) {
      setCurrentCustomership({} as Customership)
    }
    setRenderRefreshAlert(false)
    setMapViewerMode(MapViewerMode.NORMAL)
  }, [currentPath])

  const initializeCurrentProject = async (projectId: string) => {
    try {
      const response = await projectsService.getProjectById(projectId)
      if (response.status === 200) {
        setCurrentProject(response.data)
      }
    } catch (error) {
      console.error('Error initializing current project:', error)
    }
  }

  const initializeCurrentCustomership = async (customershipId: string) => {
    try {
      const response =
        await customershipService.getCustomershipById(customershipId)
      if (response.status === 200) {
        setCurrentCustomership(response.data)
      }
    } catch (error) {
      console.error('Error initializing current customership:', error)
    }
  }

  const initializeCurrentUser = async (
    projectId?: string,
    customershipId?: string
  ) => {
    try {
      const response = await usersService.getCurrentUserStateData(
        projectId,
        customershipId
      )
      if (response.status === 200) {
        setCurrentUser(response.data)
      }
    } catch (error) {
      console.error('Error initializing current user:', error)
    }
  }

  const handleMapViewerModeChange = (
    mode: MapViewerMode,
    selectedLocation?: MapLocation
  ) => {
    setMapViewerMode(mode)
  }

  const contextValue = useMemo(
    () => ({
      currentProject,
      currentCustomership,
      currentUser,
      initializeCurrentProject,
      initializeCurrentCustomership,
      setCurrentProject,
      setCurrentCustomership,
      setCurrentUser,
      initializeCurrentUser,
      renderRefreshAlert,
      setRenderRefreshAlert,
      mapViewerMode,
      setMapViewerMode,
      handleMapViewerModeChange,
    }),
    [
      currentProject,
      currentCustomership,
      currentUser,
      initializeCurrentProject,
      initializeCurrentCustomership,
      setCurrentProject,
      setCurrentCustomership,
      setCurrentUser,
      initializeCurrentUser,
      renderRefreshAlert,
      setRenderRefreshAlert,
      mapViewerMode,
      setMapViewerMode,
      handleMapViewerModeChange,
    ]
  )

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useAppContext = (): AppProviderType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}
