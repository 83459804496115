import i18n from 'src/i18n'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Separator } from '../ui/separator'
import MapViewer from '../MapViewer/MapViewer'
import { Dispatch, SetStateAction, useState } from 'react'
import { MapLocation } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  selectedLocation: MapLocation | null
  setSelectedLocation: Dispatch<SetStateAction<MapLocation | null>>
}

const SelectMapLocationDialog = ({
  selectedLocation,
  setSelectedLocation,
}: Props) => {
  const [locations, setLocations] = useState<MapLocation[]>([])

  const { currentProject } = useAppContext()

  const fetchLocations = async () => {
    try {
      const response = await mapLocationsService.getAllMapLocationsByProjectId(
        currentProject.id
      )
      if (response.status === 200) {
        setLocations(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          await fetchLocations()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant={'secondary'}>{i18n.t('selectMapLocation')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t('selectMapLocation')}</DialogTitle>
        </DialogHeader>
        <Separator className="my-2" />
        <p>
          {i18n.t('selectedLocation')}:{' '}
          {selectedLocation
            ? selectedLocation.title
            : i18n.t('noLocationSelected')}
        </p>
        <div>
          <MapViewer
            locations={locations}
            setSelectedLocation={setSelectedLocation}
            selectedLocation={selectedLocation}
            preventSingleLocationSheetOpen
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button>{i18n.t('close')}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SelectMapLocationDialog
