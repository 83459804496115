import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Search, Settings2 } from 'lucide-react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { sortingMethods } from 'src/views/SingleProject/ProjectInventarionView'
import itemsService from 'src/services/Items/items'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'
import { Form, Formik } from 'formik'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import { MultiSelect } from '../ui/multi-select'

interface Props {
  hideSortingMethods?: boolean
  displayResultsPerPageSelection?: boolean
}

const SearchItems = ({
  hideSortingMethods,
  displayResultsPerPageSelection,
}: Props) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('search') || ''
  )
  const [category, setCategory] = useState<string>(
    searchParams.get('category') || ''
  )
  const [unit, setUnit] = useState<string>(searchParams.get('unit') || '')
  const [sort, setSort] = useState<string>(searchParams.get('sort') || '')
  const [itemLocation, setItemLocation] = useState<string>(
    searchParams.get('itemLocation') || ''
  )
  const [resultsPerPage, setResultsPerPage] = useState<string>(
    searchParams.get('resultsPerPage') || ''
  )

  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])
  const [selectableLocations, setSelectableLocations] = useState<string[]>([])
  const [selectableTagKeys, setSelectableTagKeys] = useState<string[]>([])
  const [selectableTagValues, setSelectableTagValues] = useState<string[]>([])

  const [tagKeys, setTagKeys] = useState<string[]>(
    searchParams.get('tagKeys')?.split(',') || []
  )
  const [tagValues, setTagValues] = useState<string[]>(
    searchParams.get('tagValues')?.split(',') || []
  )

  const fetchSearchUtils = async () => {
    if (projectId) {
      try {
        const searchUtils =
          await itemsService.getSearchUtilsByProjectId(projectId)
        setSelectableCategories(searchUtils.categories)
        setSelectableUnits(searchUtils.units)
        setSelectableLocations(searchUtils.locations)
        setSelectableTagKeys(searchUtils.tag_keys)
        setSelectableTagValues(searchUtils.tag_values)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleSearch = (
    values: any,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void
    }
  ) => {
    searchParams.set('page', '1')
    if (searchQuery) {
      searchParams.set('search', searchQuery) // Set search query in URLSearchParams
    } else {
      searchParams.delete('search')
    }
    if (category) {
      searchParams.set('category', category)
    } else {
      searchParams.delete('category')
    }
    if (unit) {
      searchParams.set('unit', unit)
    } else {
      searchParams.delete('unit')
    }
    if (itemLocation) {
      searchParams.set('itemLocation', itemLocation)
    } else {
      searchParams.delete('itemLocation')
    }
    if (sort) {
      searchParams.set('sort', sort)
    } else {
      searchParams.delete('sort')
    }
    if (tagKeys && tagKeys.length > 0) {
      searchParams.set('tagKeys', tagKeys.join(','))
    } else {
      searchParams.delete('tagKeys')
    }
    if (tagValues && tagValues.length > 0) {
      searchParams.set('tagValues', tagValues.join(','))
    } else {
      searchParams.delete('tagValues')
    }
    if (resultsPerPage) {
      searchParams.set('resultsPerPage', resultsPerPage)
    } else {
      searchParams.delete('resultsPerPage')
    }
    const currentLocation = location.pathname
    const destination =
      currentLocation.endsWith(i18n.t('paths.inventarion')) ||
      currentLocation.endsWith(i18n.t('paths.history'))
        ? currentLocation
        : currentLocation + '/' + i18n.t('paths.inventarion')
    navigate({ pathname: destination, search: searchParams.toString() })

    setSubmitting(false)
    setDialogOpen(false)
  }

  return (
    <Formik onSubmit={handleSearch} initialValues={{}}>
      {({ isSubmitting }) => (
        <Form className="flex flex-row gap-2 flex-grow flex-wrap sm:flex-nowrap">
          <div className="flex flex-row flex-nowrap gap-2 w-full">
            <Input
              type="search"
              placeholder={i18n.t('searchForItem')}
              className="w-full rounded-lg"
              data-testid="search-items-input-searchbar"
              value={searchQuery} // Controlled input value
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <PendingSubmitButton
              data-testid="search-button-searchbar"
              isSubmitting={isSubmitting}
              buttonText={<Search />}
            />
            <Dialog
              open={dialogOpen}
              onOpenChange={async (open) => {
                setDialogOpen(open)
                if (open) {
                  await fetchSearchUtils()
                }
              }}
            >
              <DialogTrigger asChild data-testid="search-dialog-trigger">
                <Button variant={'outline'} type="button">
                  <Settings2 />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <Form>
                  <DialogHeader>
                    <DialogTitle>{i18n.t('modifySearchParams')}</DialogTitle>
                  </DialogHeader>
                  <div className="flex flex-col gap-2 py-4 my-2">
                    <Input
                      type="search"
                      data-testid="search-items-input-dialog"
                      placeholder={i18n.t('searchForItem')}
                      className="w-full rounded-lg"
                      value={searchQuery} // Controlled input value
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Select
                      onValueChange={(value) => setCategory(value)}
                      value={category}
                    >
                      <SelectTrigger data-testid="select-search-category-trigger">
                        <SelectValue placeholder={i18n.t('category')} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={'null'}>
                          {i18n.t('noChoice')}
                        </SelectItem>
                        {selectableCategories.map((categ) => (
                          <SelectItem
                            key={categ}
                            value={categ}
                            data-testid={`select-category-item-${categ}`}
                          >
                            {categ}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      onValueChange={(value) => setUnit(value)}
                      value={unit}
                    >
                      <SelectTrigger data-testid="select-search-unit-trigger">
                        <SelectValue placeholder={i18n.t('unit')} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={'null'}>
                          {i18n.t('noChoice')}
                        </SelectItem>
                        {selectableUnits.map((unit) => (
                          <SelectItem
                            key={unit}
                            value={unit}
                            data-testid={`select-unit-item-${unit}`}
                          >
                            {unit}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Select
                      onValueChange={(value) => setItemLocation(value)}
                      value={itemLocation}
                    >
                      <SelectTrigger data-testid="select-search-location-trigger">
                        <SelectValue placeholder={i18n.t('location')} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={'null'}>
                          {i18n.t('noChoice')}
                        </SelectItem>
                        {selectableLocations.map((location) => (
                          <SelectItem
                            key={location}
                            value={location}
                            data-testid={`select-location-item-${location}`}
                          >
                            {location}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <MultiSelect
                      options={selectableTagKeys.map((tag) => ({
                        label: tag,
                        value: tag,
                      }))}
                      onValueChange={setTagKeys}
                      defaultValue={tagKeys}
                      placeholder={i18n.t('tags')}
                      variant="inverted"
                      maxCount={3}
                      modalPopover={true}
                      data-testid="select-tag-keys-trigger"
                    />
                    <MultiSelect
                      options={selectableTagValues.map((tag) => ({
                        label: tag,
                        value: tag,
                      }))}
                      onValueChange={setTagValues}
                      defaultValue={tagValues}
                      placeholder={i18n.t('tagValues')}
                      variant="inverted"
                      maxCount={3}
                      modalPopover={true}
                      data-testid="select-tag-values-trigger"
                    />
                    {!hideSortingMethods && (
                      <Select
                        onValueChange={(value) => setSort(value)}
                        value={sort}
                      >
                        <SelectTrigger data-testid="select-search-sort-trigger">
                          <SelectValue placeholder={i18n.t('sort')} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={'null'}>
                            {i18n.t('noChoice')}
                          </SelectItem>
                          {sortingMethods.map((method) => (
                            <SelectItem
                              key={method.method}
                              value={method.method}
                              data-testid={`select-sort-item-${method.method}`}
                            >
                              {i18n.t(`${method.name}`)}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  </div>
                  <DialogFooter className="flex flex-row gap-2 flex-wrap justify-end">
                    <DialogClose
                      asChild
                      data-testid="search-save-button-dialog"
                    >
                      <Button type="button">{i18n.t('save')}</Button>
                    </DialogClose>
                    <PendingSubmitButton
                      isSubmitting={isSubmitting}
                      buttonText={<Search />}
                      data-testid="search-button-dialog"
                    />
                  </DialogFooter>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
          {displayResultsPerPageSelection && (
            <div>
              <Select
                onValueChange={(value) => setResultsPerPage(value)}
                value={resultsPerPage}
              >
                <SelectTrigger data-testid="select-search-results-per-page-trigger">
                  <SelectValue placeholder={i18n.t('resultsPerPage')} />
                </SelectTrigger>
                <SelectContent>
                  {Array.from([DEFAULT_SEARCH_RESULTS, 20, 30, 50]).map(
                    (resultsAmount) => (
                      <SelectItem
                        key={resultsAmount}
                        value={resultsAmount.toString()}
                        data-testid={`select-resultsperpage-item-${resultsAmount}`}
                      >
                        {resultsAmount}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default SearchItems
