import React, { useRef, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { useNavigate } from 'react-router-dom'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import { Input } from '../ui/input'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import registerService from 'src/services/Register/register'
import { Link } from 'react-router-dom'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { validationSchemaRegister } from 'src/lib/validationSchemas'
import verifService from 'src/services/Auth/login'
import { Checkbox } from '../ui/checkbox'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '../ui/card'
import { Separator } from '../ui/separator'
import { Eye, EyeOff } from 'lucide-react'

export interface LanguageSelect {
  language: string
  title: string
}

export const getSelectableLanguages = (): LanguageSelect[] => {
  return [
    { language: 'fi_fi', title: i18n.t('languages.finnish') },
    { language: 'en_us', title: i18n.t('languages.english') },
  ]
}

interface RegisterFormValues {
  email: string
  password: string
  verifyPassword: string
  language: string
  phone: string
  organization: string
  termsAccepted: boolean
  hcaptcha: string
}

const RegisterForm = () => {
  const navigate = useNavigate()
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const hcaptchaRef = useRef<HCaptcha>(null)

  const handleRegister = async (
    values: RegisterFormValues,
    { setSubmitting }: FormikHelpers<RegisterFormValues>
  ) => {
    setSubmitting(true)

    if (process.env.REACT_APP_USE_HCAPTCHA === 'true' && !values.hcaptcha) {
      toast.error(i18n.t('authMessages.failedCaptcha'))
      setSubmitting(false)
      return
    }

    try {
      const res = await registerService.registerUser({
        email: values.email,
        password: values.password,
        language: values.language,
        phone: values.phone,
        organization: values.organization,
        hcaptcha:
          process.env.REACT_APP_USE_HCAPTCHA === 'false'
            ? 'test_token'
            : values.hcaptcha,
      })
      if (res) {
        setEmail(values.email)
        setRegisterSuccess(true)
      }
    } catch (error) {
      toast.error(i18n.t('registrationFailed'))
    } finally {
      setSubmitting(false)
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha()
      }
    }
  }

  const handleResendVerification = async () => {
    try {
      const response = await verifService.resendVerificationEmail(email)
      if (response.status === 200) {
        toast.success(i18n.t('authMessages.verificationEmailSent'))
      } else {
        toast.error(i18n.t('authMessages.verificationEmailFailed'))
      }
    } catch (error: any) {
      if (error.response?.status === 429) {
        toast.error(i18n.t('limiter.verifyEmail'))
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    }
  }

  const hcaptchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY
  if (!hcaptchaSiteKey) {
    console.error('hCaptcha site key is not set in the environment variables.')
    return <div>Error: hCaptcha site key is not set.</div>
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {!registerSuccess ? (
        <Card className="w-full max-w-md p-2">
          <Formik
            initialValues={{
              email: '',
              password: '',
              verifyPassword: '',
              language: '',
              phone: '',
              organization: '',
              termsAccepted: false,
              hcaptcha: '',
            }}
            validationSchema={validationSchemaRegister}
            onSubmit={handleRegister}
          >
            {({ setFieldValue, values, isSubmitting, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CardTitle className="mb-2 text-center">
                    {i18n.t('register')}
                  </CardTitle>
                  <Separator />
                  <CardContent className="flex flex-col gap-3 w-full my-2">
                    <Label>{i18n.t('email')}</Label>
                    <Field
                      name="email"
                      type="email"
                      placeholder={i18n.t('email')}
                      className="bg-white text-black rounded-lg px-3 py-2"
                      as={Input}
                    />
                    <FormErrorMessage name="email" />

                    <Label>{i18n.t('phone')}</Label>
                    <Field
                      name="phone"
                      type="text"
                      placeholder={i18n.t('phoneValidation.placeholder')}
                      className="bg-white text-black rounded-lg px-3 py-2"
                      as={Input}
                    />
                    <FormErrorMessage name="phone" />

                    <Label>{i18n.t('organization')}</Label>
                    <Field
                      name="organization"
                      type="text"
                      placeholder={i18n.t('organization')}
                      className="bg-white text-black rounded-lg px-3 py-2"
                      as={Input}
                    />
                    <FormErrorMessage name="organization" />

                    <Label>{i18n.t('password')}</Label>
                    <div className="relative">
                      <Field
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={i18n.t('password')}
                        className="bg-white text-black rounded-lg px-3 py-2 w-full pr-10" // Add padding-right for the icon space
                        as={Input}
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                      >
                        {showPassword ? (
                          <EyeOff className="w-5 h-5 text-gray-500" />
                        ) : (
                          <Eye className="w-5 h-5 text-gray-500" />
                        )}
                      </span>
                    </div>
                    <FormErrorMessage name="password" />
                    <Label>{i18n.t('confirmPassword')}</Label>
                    <Field
                      name="verifyPassword"
                      type="password"
                      placeholder={i18n.t('confirmPassword')}
                      className="bg-white text-black rounded-lg px-3 py-2"
                      as={Input}
                    />
                    <FormErrorMessage name="verifyPassword" />

                    <Label>{i18n.t('selectLanguage')}</Label>
                    <Select
                      required
                      onValueChange={(value) =>
                        setFieldValue('language', value)
                      }
                      value={values.language}
                    >
                      <SelectTrigger data-testid="select-language-trigger">
                        <SelectValue placeholder={i18n.t('selectLanguage')} />
                      </SelectTrigger>
                      <SelectContent>
                        {getSelectableLanguages().map((lang) => (
                          <SelectItem
                            key={lang.language}
                            value={lang.language}
                            data-testid={`select-item-${lang.language}`}
                          >
                            {lang.title}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormErrorMessage name="language" />

                    <div className="my-4 flex items-center gap-2">
                      <Checkbox
                        className="bg-white"
                        id="termsAccepted"
                        name="termsAccepted"
                        data-testid="termsAccepted"
                        onCheckedChange={(value) =>
                          setFieldValue('termsAccepted', value)
                        }
                      />
                      <Label>
                        {i18n.t('iAccept')}{' '}
                        <a
                          href="/terms-of-service"
                          target="_blank"
                          className="underline"
                        >
                          {i18n.t('termsAndConditions')}
                        </a>{' '}
                        {i18n.t('and')}{' '}
                        <a
                          href="https://www.gobosoft.fi/tietosuojaseloste/"
                          target="_blank"
                          className="underline"
                        >
                          {i18n.t('acceptPrivacyStatement')}
                        </a>
                      </Label>
                    </div>
                    <FormErrorMessage name="termsAccepted" />

                    {process.env.REACT_APP_USE_HCAPTCHA !== 'false' && (
                      <div className="flex items-center mb-4">
                        <HCaptcha
                          sitekey={hcaptchaSiteKey}
                          onVerify={(token) => {
                            setFieldValue('hcaptcha', token)
                          }}
                          onError={() => {
                            toast.error(
                              'Captcha verification failed. Please try again.'
                            )
                          }}
                          onExpire={() => {
                            toast.error(
                              'Captcha verification expired. Please try again.'
                            )
                            if (hcaptchaRef.current) {
                              hcaptchaRef.current.resetCaptcha()
                            }
                          }}
                          ref={hcaptchaRef}
                        />
                      </div>
                    )}

                    <Button type="submit" disabled={isSubmitting}>
                      {i18n.t('register')}
                    </Button>
                    <Link to={'/'}>
                      {i18n.t('alreadyHaveUser')}{' '}
                      <span className="underline">{i18n.t('logIn')}</span>
                    </Link>
                  </CardContent>
                </Form>
              )
            }}
          </Formik>
        </Card>
      ) : (
        <Card
          className="w-full max-w-md"
          data-testid="register-successful-card"
        >
          <CardTitle>{i18n.t('userRegisterSuccessful')}</CardTitle>
          <Separator className="my-3" />
          <CardDescription>{i18n.t('emailConfirmationSent')}</CardDescription>
          <Separator className="my-3" />
          <CardContent className="flex flex-col gap-3">
            <Button
              data-testid="register-successful-link-to-login"
              type="button"
              onClick={() => navigate('/')}
              className="mt-4"
            >
              {i18n.t('logIn')}
            </Button>
          </CardContent>
          <Separator />
          <CardFooter className="flex flex-col gap-3 mt-2">
            <p>{i18n.t('questionDidntReceiveEmail')}</p>
            <Button
              onClick={handleResendVerification}
              data-testid="resend-verification-link"
            >
              {i18n.t('sendNewConfirmationEmail')}
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  )
}

export default RegisterForm
