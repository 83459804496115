import { iconAndTextStyling } from 'src/constants'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Button } from '../ui/button'
import { Ellipsis } from 'lucide-react'
import i18n from 'src/i18n'
import {
  Notification,
  NotificationStatus,
  UserProjectRoles,
} from 'src/lib/types'
import { Dispatch, SetStateAction } from 'react'
import { displayResponseErrorMessage } from 'src/lib/utils'
import notificationsService from 'src/services/Notifications/notificationsService'
import { useAppContext } from 'src/context/AppProvider'
import { toast } from 'react-toastify'
import { useConfirmaDialogStableTarget } from '../ui/confirmDialog'
import { Link } from 'react-router-dom'

interface Props {
  notification: Notification
  setNotifications: Dispatch<SetStateAction<Notification[]>>
}

const ManageNotificationButton = ({
  notification,
  setNotifications,
}: Props) => {
  const { currentProject, currentUser } = useAppContext()
  const confirmDialog = useConfirmaDialogStableTarget()

  const handleNotificationStateChange = async (status: NotificationStatus) => {
    if (
      !(await confirmDialog({
        title: i18n.t('confirmStatusChange'),
        text: i18n.t('notifications.confirmStatusChange'),
      }))
    ) {
      return
    }
    try {
      const response = await notificationsService.changeNotificationStatus(
        currentProject.id,
        notification.id,
        {
          status,
        }
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        const updatedNotification = response.data as Notification
        setNotifications((oldNotifications) =>
          oldNotifications.map((n) =>
            n.id === updatedNotification.id ? updatedNotification : n
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleNotificationDelete = async () => {
    if (
      !(await confirmDialog({
        title: i18n.t('confirmDelete'),
        text: i18n.t('notifications.confirmDeleteNotification'),
      }))
    ) {
      return
    }
    try {
      const response = await notificationsService.deleteNotification(
        currentProject.id,
        notification.id
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setNotifications((oldNotifications) =>
          oldNotifications.filter((n) => n.id !== notification.id)
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <DropdownMenu /*open={dropdownOpen} onOpenChange={setDropdownOpen}*/>
      <DropdownMenuTrigger asChild>
        <Button variant={'ghost'} className={iconAndTextStyling}>
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{i18n.t('manage')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {currentUser.project_role === UserProjectRoles.ADMIN && (
          <>
            {notification.notification_setting_id && (
              <Link
                to={
                  i18n.t('paths.projects') +
                  `/${currentProject.id}/` +
                  i18n.t('paths.notifications') +
                  `/${notification.notification_setting_id}/` +
                  i18n.t('paths.editNotificationSetting')
                }
              >
                <DropdownMenuItem>
                  {i18n.t('notifications.moveToNotificationSetting')}
                </DropdownMenuItem>
              </Link>
            )}
            {notification.status !== NotificationStatus.DONE && (
              <DropdownMenuItem
                onClick={() =>
                  handleNotificationStateChange(NotificationStatus.DONE)
                }
              >
                {i18n.t('notifications.markAsDone')}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => handleNotificationDelete()}>
              {i18n.t('notifications.delete')}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ManageNotificationButton
