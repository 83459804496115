import LocationIcon from '../assets/svgs/map-pin.svg'
import LocationIconSelected from '../assets/svgs/map-pin-selected.svg'
import LocationIconPlus from '../assets/svgs/map-pin-plus.svg'
import InfoIcon from '../assets/svgs/info.svg'

import L from 'leaflet'

export const mapPinIcon = L.icon({
  iconUrl: LocationIcon, // Path to your image
  iconSize: [32, 32], // Size of the icon in pixels
  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -32], // Position of popup relative to icon
})

export const mapPinIconPlus = L.icon({
  iconUrl: LocationIconPlus, // Path to your image
  iconSize: [32, 32], // Size of the icon in pixels
  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -32], // Position of popup relative to icon
})

export const mapPinIconSelected = L.icon({
  iconUrl: LocationIconSelected, // Path to your image
  iconSize: [32, 32], // Size of the icon in pixels
  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -32], // Position of popup relative to icon
})

export const arrowIcon = L.divIcon({
  className: 'leaflet-arrow-icon',
  html: `<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 16h24M20 12l8 4-8 4" stroke="blue" stroke-width="2"/>
        </svg>`,
  iconSize: [32, 32],
  iconAnchor: [16, 16],
})

export const connectionInfoIcon = L.icon({
  iconUrl: InfoIcon, // Path to your image
  iconSize: [32, 32], // Size of the icon in pixels
  iconAnchor: [16, 32], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -32], // Position of popup relative to icon
  className: 'bg-white rounded-xl',
})
