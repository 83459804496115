import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import { InventoryFlowData, WasteChartItem, StockOutItem } from 'src/lib/types'

const baseUrl = '/api/data/charts'

const getInventoryFlowData = async (
  startDate: string,
  endDate: string,
  project_id: string
): Promise<InventoryFlowData[]> => {
  const response: AxiosResponse<InventoryFlowData[]> = await axiosInstance.get(
    `${baseUrl}/inventory-flow/${project_id}`,
    {
      params: { start_date: startDate, end_date: endDate },
    }
  )
  return response.data
}

const getWasteData = async (
  project_id: string,
  startDate: string,
  endDate: string
): Promise<WasteChartItem[]> => {
  const response: AxiosResponse<WasteChartItem[]> = await axiosInstance.get(
    `${baseUrl}/waste-data/${project_id}`,
    {
      params: { start_date: startDate, end_date: endDate },
    }
  )
  return response.data
}

const getStockoutData = async (
  project_id: string,
  startDate: string,
  endDate: string
): Promise<StockOutItem[]> => {
  const response: AxiosResponse<StockOutItem[]> = await axiosInstance.get(
    `${baseUrl}/stock-out/${project_id}`,
    {
      params: { start_date: startDate, end_date: endDate },
    }
  )
  return response.data
}

export default {
  getInventoryFlowData,
  getWasteData,
  getStockoutData,
}
