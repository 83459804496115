import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../ui/sheet'
import {
  Item,
  LocationConnection,
  LocationConnectionCompletionStatus,
  LocationConnectionItem,
  LocationConnectionItemArrivalStatus,
  LocationConnectionPlain,
} from 'src/lib/types'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import {
  Check,
  CheckIcon,
  ChevronsDown,
  ChevronsUp,
  Clock,
  Edit,
  RefreshCcw,
  TrashIcon,
} from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'
import { Input } from '../ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { displayResponseErrorMessage } from 'src/lib/utils'
import locationConnectionService from 'src/services/LocationConnections/locationConnectionService'
import { useAppContext } from 'src/context/AppProvider'
import { iconAndTextStyling } from 'src/constants'
import { toast } from 'react-toastify'
import { Checkbox } from '../ui/checkbox'
import { Badge } from '../ui/badge'
import clsx from 'clsx'
import itemsService from 'src/services/Items/items'
import 'dayjs/locale/fi'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import {
  validationSchemaAddNewLocationConnection,
  validationSchemaUpdateConnectionItem,
} from 'src/lib/validationSchemas'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'

interface Props {
  selectedConnection: LocationConnection
  setSelectedConnection: Dispatch<SetStateAction<LocationConnection | null>>
  setVisualConnections: Dispatch<SetStateAction<LocationConnection[]>>
}

interface FormValues {
  description: string
}

const SingleLocationConnection = ({
  selectedConnection,
  setSelectedConnection,
  setVisualConnections,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [items, setItems] = useState<LocationConnectionItem[]>([])
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: number
  }>({})
  const [selectedConnectableItems, setSelectedConnectableItems] = useState<{
    [key: string]: number
  }>({})
  const [connectableItems, setConnectableItems] = useState<Item[]>([])
  const [date, setDate] = useState<Dayjs | null>(null)
  const { currentProject } = useAppContext()

  const fetchConnectionItemsData = async () => {
    try {
      const response =
        await locationConnectionService.getLocationConnectionItemsByLocationConnectionId(
          currentProject.id,
          selectedConnection.id
        )

      if (response.status === 200) {
        const connectionItems = response.data as LocationConnectionItem[]
        setItems(connectionItems)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchLocationItems = async () => {
    try {
      const response = await itemsService.getItemsByMapLocationId(
        selectedConnection.map_location_from.id,
        currentProject.id
      )
      setConnectableItems(response)
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const initLocationConnection = () => {
    fetchConnectionItemsData()
    fetchLocationItems()
    setDate(dayjs.utc(selectedConnection.date_and_time))
  }

  useEffect(() => {
    initLocationConnection()
  }, [selectedConnection])

  const handleDeleteLocationConnection = async (
    connectionId: string,
    projectId: string
  ) => {
    try {
      const response =
        await locationConnectionService.deleteLocationConnectionById(
          connectionId,
          projectId
        )

      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setSelectedConnection(null)
        setVisualConnections((oldConnections) => {
          return oldConnections.filter((con) => con.id !== response.data)
        })
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const connectItemsToConnection = async () => {
    try {
      const response =
        await locationConnectionService.connectItemsToLocationConnection(
          selectedConnection.id,
          currentProject.id,
          selectedConnectableItems
        )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setSelectedConnectableItems({})
        // update connection items list
        fetchConnectionItemsData()
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const nonArrivedItems = items.filter(
    (item) =>
      item.arrival_status !== LocationConnectionItemArrivalStatus.ALL_ARRIVED
  )

  // Check if all items are selected
  const allConnectedItemsSelected =
    nonArrivedItems.length > 0 &&
    nonArrivedItems.every((item) => selectedItems[item.id] > 0)

  const handleSelectAllConnectedItems = (checked: boolean) => {
    setSelectedItems(
      checked
        ? Object.fromEntries(
            nonArrivedItems.map((item) => [
              item.id,
              item.connection_amount - item.amount_arrived,
            ])
          ) // Select all
        : {} // Deselect all
    )
  }

  const handleSelectAllConnectableItems = (checked: boolean) => {
    setSelectedConnectableItems(
      checked
        ? Object.fromEntries(connectableItems.map((item) => [item.id, 1])) // Select all
        : {} // Deselect all
    )
  }

  const allConnectableItemsSelected =
    connectableItems.length > 0 &&
    connectableItems.every((item) => selectedConnectableItems[item.id] > 0)

  // Bulk delete action
  const handleDeleteSelected = async () => {
    const selectedItemIds = Object.keys(selectedItems)
      .filter((key) => selectedItems[key]) // Only keep the checked ones
      .map((id) => id) // Convert to an array of IDs

    if (selectedItemIds.length === 0) {
      toast.error(i18n.t('selectItems'))
      return
    }

    try {
      const response =
        await locationConnectionService.deleteLocationConnectionItems(
          selectedConnection.id,
          currentProject.id,
          selectedItemIds
        )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        const remainingItems = items.filter((item) => !selectedItems[item.id])
        setItems(remainingItems)
        setSelectedItems({})
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  // Bulk mark as arrived
  const handleMarkItemsAsArrived = async () => {
    if (
      selectedConnection.completion_status ===
      LocationConnectionCompletionStatus.COMPLETE
    ) {
      toast.error(i18n.t('connectionAlreadyCompleted'))
      return
    }

    try {
      const response = await locationConnectionService.markItemsArrived(
        selectedConnection.id,
        currentProject.id,
        selectedItems
      )

      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        fetchConnectionItemsData()
        fetchLocationItems()
        setSelectedItems({})
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const anySelectedConnectedItems = Object.values(selectedItems).some(
    (isSelected) => isSelected
  )

  const anySelectedConnectableItems = Object.values(
    selectedConnectableItems
  ).some((isSelected) => isSelected)

  const handleMarkConnectionStatusComplete = async () => {
    try {
      const response =
        await locationConnectionService.markConnectionStatusComplete(
          selectedConnection.id,
          currentProject.id
        )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        const updatedConnection = response.data as LocationConnection
        setSelectedConnection(updatedConnection)
        setVisualConnections((oldConnections) => {
          return oldConnections.map((connection) =>
            connection.id === updatedConnection.id
              ? updatedConnection
              : connection
          )
        })
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleSaveDateAndDescription = async (formValues: FormValues) => {
    try {
      const response =
        await locationConnectionService.updateLocationConnectionDateAndDescription(
          selectedConnection.id,
          currentProject.id,
          {
            date_and_time: date?.toDate(),
            description: formValues.description,
          }
        )
      if (response.status === 200) {
        const responseConnection = response.data as LocationConnectionPlain
        toast.success(i18n.t('successGeneric'))
        const updatedCon = {
          ...selectedConnection,
          description: responseConnection.description,
          date_and_time: responseConnection.date_and_time,
        }
        setSelectedConnection(updatedCon)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleConnectedItemUpdate = async (
    connectionItemId: string,
    amount: number
  ) => {
    try {
      const response = await locationConnectionService.updateConnectionItem(
        selectedConnection.id,
        currentProject.id,
        {
          connection_item_id: connectionItemId,
          amount,
        }
      )
      if (response.status === 200) {
        const updatedConnectionItem = response.data as LocationConnectionItem
        toast.success(i18n.t('successGeneric'))
        setSelectedItems({})
        setItems((oldItems) =>
          oldItems.map((i) =>
            i.id === updatedConnectionItem.id ? updatedConnectionItem : i
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  // Connection can be deleted if its completed or its incomplete with no items
  const canDeleteLocationConnection =
    selectedConnection.completion_status ===
      LocationConnectionCompletionStatus.COMPLETE ||
    (selectedConnection.completion_status ===
      LocationConnectionCompletionStatus.INCOMPLETE &&
      items.length === 0)

  return (
    <Sheet
      open={!!selectedConnection}
      onOpenChange={(open) => {
        if (open) {
          // On open
        } else {
          // On close
          if (setSelectedConnection) {
            setSelectedConnection(null)
          }
        }
      }}
      modal={false}
    >
      <SheetContent
        side={'bottom'}
        onInteractOutside={(e) => e.preventDefault()}
        className={`z-40 py-0 transition-height duration-300 ease-in-out ${
          isExpanded ? 'h-4/5' : 'h-1/2'
        } pointer-events-auto sm:ml-14`}
      >
        <SheetHeader className="flex flex-row items-center justify-between w-full mb-1 gap-1 flex-wrap">
          <SheetTitle>
            {selectedConnection.map_location_from.title} →{' '}
            {selectedConnection.map_location_to.title}
          </SheetTitle>
          <Button
            variant={'destructive'}
            className={iconAndTextStyling}
            size={'sm'}
            disabled={!canDeleteLocationConnection}
            onClick={() =>
              handleDeleteLocationConnection(
                selectedConnection.id,
                currentProject.id
              )
            }
          >
            <TrashIcon />
            {i18n.t('delete')}
          </Button>
          <Button
            size={'sm'}
            variant={'secondary'}
            onClick={initLocationConnection}
          >
            <RefreshCcw />
          </Button>
          <div className="flex-1 flex justify-center">
            <Button
              variant="outline"
              onClick={() => setIsExpanded(!isExpanded)}
              className="px-2 rounded-full"
            >
              {isExpanded ? (
                <>
                  <ChevronsDown className="w-5 h-5 mr-2" />
                  {i18n.t('collapse')}
                </>
              ) : (
                <>
                  <ChevronsUp className="w-5 h-5 mr-2" />
                  {i18n.t('expand')}
                </>
              )}
            </Button>
          </div>
        </SheetHeader>
        <Tabs defaultValue="general" className="h-full">
          <TabsList className="flex items-center justify-center flex-wrap h-auto space-y-1">
            <TabsTrigger value="general">{i18n.t('general')}</TabsTrigger>
            <TabsTrigger value="materials">
              {i18n.t('relatedItems')}
            </TabsTrigger>
            <TabsTrigger value="connect-materials">
              {i18n.t('connectItems')}
            </TabsTrigger>
          </TabsList>

          {/* General Tab - Editable Description */}
          <TabsContent value="general">
            <div className="flex flex-row gap-2 flex-wrap">
              <Badge
                className={clsx(
                  selectedConnection?.completion_status ===
                    LocationConnectionCompletionStatus.COMPLETE
                    ? 'bg-green-500'
                    : 'bg-orange-400',
                  'pointer-events-none' // Prevents hover effects
                )}
              >
                {selectedConnection.completion_status ===
                LocationConnectionCompletionStatus.COMPLETE ? (
                  <CheckIcon className="px-1" />
                ) : (
                  <Clock className="px-1" />
                )}
                {i18n.t(
                  `locationConnections.completionStatus.${selectedConnection.completion_status}`
                )}
              </Badge>
              {selectedConnection.completion_status !==
                LocationConnectionCompletionStatus.COMPLETE && (
                <Button
                  onClick={handleMarkConnectionStatusComplete}
                  size={'sm'}
                >
                  {i18n.t('markAllAsReady')}
                </Button>
              )}
            </div>
            <Formik
              initialValues={{ description: selectedConnection.description }}
              onSubmit={handleSaveDateAndDescription}
              validationSchema={validationSchemaAddNewLocationConnection}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Label>{i18n.t('dateAndTime')}</Label>
                  <div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fi"
                    >
                      <DateTimeField
                        disablePast
                        onChange={(val) => setDate(val)}
                        value={date}
                        timezone="UTC"
                      />
                    </LocalizationProvider>
                  </div>
                  <Label>{i18n.t('description')}</Label>
                  <Field
                    as={Textarea}
                    name="description"
                    placeholder={i18n.t('descriptionPlaceHolder') + '...'}
                  />
                  <FormErrorMessage name="description" />
                  <div className="absolute bottom-2 right-2">
                    <PendingSubmitButton
                      isSubmitting={isSubmitting}
                      buttonText={i18n.t('saveChanges')}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </TabsContent>

          {/* Materials Tab - Editable Materials */}
          <TabsContent value="materials" className="h-full">
            <div className="flex gap-2 flex-wrap">
              <Checkbox
                checked={allConnectedItemsSelected}
                onCheckedChange={(checked) =>
                  handleSelectAllConnectedItems(checked as boolean)
                }
              />
              <Label>{i18n.t('selectAll')}</Label>
              <Button
                variant="destructive"
                onClick={handleDeleteSelected}
                disabled={!anySelectedConnectedItems}
                size={'sm'}
              >
                <TrashIcon /> {i18n.t('delete')}
              </Button>
              <Button
                onClick={handleMarkItemsAsArrived}
                disabled={!anySelectedConnectedItems}
                size={'sm'}
              >
                <Check /> {i18n.t('markAsArrived')}
              </Button>
            </div>
            <div className="h-3/5 overflow-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{i18n.t('select')}</TableHead>
                    <TableHead>{i18n.t('itemName')}</TableHead>
                    <TableHead>{i18n.t('category')}</TableHead>
                    <TableHead>
                      {i18n.t('itemAmount')} ({i18n.t('availableItems')}/
                      {i18n.t('reservedItems')})
                    </TableHead>
                    <TableHead>{i18n.t('connectionAmount')}</TableHead>
                    <TableHead>
                      {i18n.t('status')} ({i18n.t('arrived')})
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Checkbox
                          disabled={
                            item.arrival_status ===
                            LocationConnectionItemArrivalStatus.ALL_ARRIVED
                          }
                          checked={!!selectedItems[item.id]}
                          onCheckedChange={(checked) => {
                            setSelectedItems((prev) =>
                              checked
                                ? {
                                    ...prev,
                                    [item.id]:
                                      item.connection_amount -
                                      item.amount_arrived,
                                  }
                                : Object.fromEntries(
                                    Object.entries(prev).filter(
                                      ([key]) => key !== String(item.id)
                                    )
                                  )
                            )
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>
                        {item.item_amount} ({item.item_available_amount}/
                        {item.item_reserved_amount})
                      </TableCell>
                      <TableCell className="flex items-center gap-2">
                        <Input
                          type="number"
                          step="any"
                          min={0}
                          max={item.connection_amount - item.amount_arrived}
                          value={
                            selectedItems[item.id] !== undefined
                              ? Number(selectedItems[item.id].toFixed(3))
                              : Number(
                                  (
                                    item.connection_amount - item.amount_arrived
                                  ).toFixed(3)
                                )
                          }
                          onChange={(e) =>
                            setSelectedItems((prev) => ({
                              ...prev,
                              [item.id]: parseFloat(e.target.value) || 0,
                            }))
                          }
                        />
                        <span>{item.unit}</span>
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          {item.arrival_status ===
                          LocationConnectionItemArrivalStatus.ALL_ARRIVED ? (
                            <Check className="h-5 w-5 text-green-500" />
                          ) : (
                            <Clock className="h-5 w-5 text-orange-400" />
                          )}
                          ({item.amount_arrived}/{item.connection_amount})
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                size={'sm'}
                                variant={'secondary'}
                                className="p-1"
                              >
                                <Edit />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <Formik
                                initialValues={{
                                  amount: item.connection_amount,
                                }}
                                onSubmit={(formValues) =>
                                  handleConnectedItemUpdate(
                                    item.id,
                                    formValues.amount
                                  )
                                }
                                validationSchema={() =>
                                  validationSchemaUpdateConnectionItem(
                                    item.amount_arrived,
                                    item.item_available_amount
                                  )
                                }
                              >
                                {({ isSubmitting }) => (
                                  <Form>
                                    <Label>
                                      {i18n.t('changeTotalAmountToMove')}
                                    </Label>
                                    <Field
                                      as={Input}
                                      type="number"
                                      step="any"
                                      min={item.amount_arrived}
                                      max={item.item_available_amount}
                                      name="amount"
                                      id="amount"
                                    />
                                    <FormErrorMessage name="amount" />
                                    <div className="mt-2">
                                      <PendingSubmitButton
                                        isSubmitting={isSubmitting}
                                        buttonText={i18n.t('save')}
                                      />
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </TabsContent>
          <TabsContent value="connect-materials" className="h-full">
            <div className="flex gap-2 flex-wrap">
              <Checkbox
                checked={allConnectableItemsSelected}
                onCheckedChange={(checked) =>
                  handleSelectAllConnectableItems(checked as boolean)
                }
              />
              <Label>{i18n.t('selectAll')}</Label>
              <Button
                onClick={connectItemsToConnection}
                size={'sm'}
                disabled={!anySelectedConnectableItems}
              >
                <Check /> {i18n.t('connectSelected')}
              </Button>
            </div>
            <div className="h-3/5 overflow-auto">
              {connectableItems.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>{i18n.t('select')}</TableHead>
                      <TableHead>{i18n.t('itemName')}</TableHead>
                      <TableHead>{i18n.t('category')}</TableHead>
                      <TableHead>
                        {i18n.t('itemAmount')} ({i18n.t('availableItems')}/
                        {i18n.t('reservedItems')})
                      </TableHead>
                      <TableHead>{i18n.t('connectionAmount')}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {connectableItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Checkbox
                            checked={Boolean(selectedConnectableItems[item.id])}
                            onCheckedChange={(checked) => {
                              setSelectedConnectableItems((prev) =>
                                checked
                                  ? { ...prev, [item.id]: 1 }
                                  : Object.fromEntries(
                                      Object.entries(prev).filter(
                                        ([key]) => key !== String(item.id)
                                      )
                                    )
                              )
                            }}
                          />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.category}</TableCell>
                        <TableCell>
                          {item.amount} ({item.available_amount}/
                          {item.reserved_amount}) {item.unit}
                        </TableCell>

                        <TableCell>
                          <Input
                            type="number"
                            min={0}
                            step={'any'}
                            max={item.available_amount}
                            disabled={!selectedConnectableItems[item.id]}
                            value={selectedConnectableItems[item.id] ?? ''}
                            onChange={(e) =>
                              setSelectedConnectableItems((prev) => ({
                                ...prev,
                                [item.id]: parseFloat(e.target.value) || 0,
                              }))
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <p>{i18n.t('noItemsFound')}</p>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </SheetContent>
    </Sheet>
  )
}

export default SingleLocationConnection
