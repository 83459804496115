import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  ImageOverlay,
  useMapEvents,
  Marker,
  Popup,
  Tooltip,
  Polyline,
  useMap,
} from 'react-leaflet'
import L, { LatLng, LatLngBoundsExpression, LatLngExpression } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import i18n from 'src/i18n'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Field, Form, Formik } from 'formik'
import { displayResponseErrorMessage, formatDateLocale } from 'src/lib/utils'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'
import { useAppContext } from 'src/context/AppProvider'
import { toast } from 'react-toastify'
import {
  Connection,
  LocationConnection,
  LocationConnectionCompletionStatus,
  MapLocation,
  MapViewerMode,
} from 'src/lib/types'
import { Card } from '../ui/card'
import { validationSchemaAddMapLocation } from 'src/lib/validationSchemas'
import {
  arrowIcon,
  connectionInfoIcon,
  mapPinIcon,
  mapPinIconPlus,
  mapPinIconSelected,
} from 'src/lib/icons'
import SingleLocationView from './SingleLocationView'
import { Button } from '../ui/button'
import { ArrowRight, Check, ChevronRight, Clock, Ellipsis } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import AddNewLocationConnection from '../LocationConnections/AddNewLocationConnection'
import locationConnectionService from 'src/services/LocationConnections/locationConnectionService'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import SingleLocationConnection from '../LocationConnections/SingleLocationConnection'

interface MarkerType {
  lat: number
  lng: number
}

interface FormValues {
  map_location_title: string
}

interface Props {
  locations: MapLocation[]
  setLocations?: Dispatch<SetStateAction<MapLocation[]>>
  selectedLocation: MapLocation | null
  setSelectedLocation?: Dispatch<SetStateAction<MapLocation | null>>
  suggestRefreshAfterOperation?: boolean
  preventSingleLocationSheetOpen?: boolean
  customHeight?: string
  allowConnectingLocations?: boolean
  displayMapMoreButton?: boolean
}

export default function MapViewer({
  locations,
  setLocations,
  selectedLocation,
  setSelectedLocation,
  suggestRefreshAfterOperation,
  preventSingleLocationSheetOpen,
  customHeight,
  allowConnectingLocations,
  displayMapMoreButton,
}: Props) {
  const [imageUrl, setImageUrl] = useState(
    'https://jossain.org/tampere-noin-kymmenen-vuoden-valein/images/2011.jpg'
  )

  const {
    currentProject,
    setRenderRefreshAlert,
    mapViewerMode,
    setMapViewerMode,
  } = useAppContext()

  const [bounds, setBounds] = useState<LatLngBoundsExpression>([
    [61.485, 23.69],
    [61.525, 23.85],
  ])
  const [marker, setMarker] = useState<MarkerType | null>(null)
  const [connection, setConnection] = useState<Connection>({
    from: null,
    to: null,
  })
  const [addNewConnectionSheetIsOpen, setAddNewConnectionSheetIsOpen] =
    useState<boolean>(false)
  const [displayConnections, setDisplayConnections] = useState<boolean>(false)
  const [visualConnections, setVisualConnections] = useState<
    LocationConnection[]
  >([])
  const [selectedConnection, setSelectedConnection] =
    useState<LocationConnection | null>(null)

  const fetchAllConnections = async () => {
    try {
      const response =
        await locationConnectionService.getAllMapLocationConnectionsByProjectId(
          currentProject.id
        )
      if (response.status === 200) {
        const responseConnections = response.data as LocationConnection[]
        setVisualConnections(responseConnections)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    if (displayConnections) {
      fetchAllConnections()
    }
  }, [displayConnections])

  function MapClickHandler() {
    useMapEvents({
      click: (e) => {
        const newMarker = { lat: e.latlng.lat, lng: e.latlng.lng }
        setMarker(newMarker)
      },
    })
    return null
  }

  const handleAddNewLocationSubmit = async (values: FormValues) => {
    if (!currentProject || !marker || !setLocations) {
      toast.error(i18n.t('invalidEntry'))
      return null
    }
    try {
      const response = await mapLocationsService.addNewMapLocation(
        {
          lng: marker.lng,
          lat: marker.lat,
          title: values.map_location_title,
        },
        currentProject.id
      )

      if (response.status === 200) {
        const newLocation = response.data
        setLocations((oldLocations) => oldLocations.concat(newLocation))
        setMarker(null)
        toast.success(i18n.t('successGeneric'))
        if (suggestRefreshAfterOperation) {
          setRenderRefreshAlert(true)
        }
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleSelectConnectionTargetLocation = (location: MapLocation) => {
    if (connection.from && !connection.to) {
      if (location === connection.from) {
        return
      }
      setConnection((oldConnection) => {
        return { from: oldConnection.from, to: location }
      })
      if (allowConnectingLocations) {
        // Open sheet to add location details
        setAddNewConnectionSheetIsOpen(true)
      }
    } else {
      setConnection({ from: location, to: null })
    }
  }

  const getMidpoint = (
    from: MapLocation,
    to: MapLocation
  ): LatLngExpression => {
    const lat = (from.lat + to.lat) / 2
    const lng = (from.lng + to.lng) / 2
    return [lat, lng]
  }

  return (
    <Card
      className={`p-1 box-shadow 0.3s ease-in-out relative ${
        mapViewerMode !== MapViewerMode.NORMAL
          ? 'ring-4 ring-blue-500 shadow-lg'
          : ''
      }`}
    >
      <MapContainer
        center={marker ? [marker.lat, marker.lng] : [61.52, 23.69]}
        zoom={10}
        style={{
          height: customHeight ?? '75vh',
          width: '100%',
          zIndex: 0,
        }}
        id="map-viewer-container"
      >
        {displayMapMoreButton && (
          <div className="leaflet-control top-20 left-2 z-[1000]">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="px-2" variant={'outline'}>
                  <Ellipsis />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {mapViewerMode === MapViewerMode.NORMAL && (
                  <DropdownMenuItem
                    onClick={() => {
                      if (setSelectedLocation) {
                        setSelectedLocation(null)
                      }
                      setMapViewerMode(MapViewerMode.ADDING_LOCATION_CONNECTION)
                      toast.info(i18n.t('selectLocationsToConnect'), {
                        position: 'top-center',
                      })
                    }}
                  >
                    {i18n.t('createConnection')}
                  </DropdownMenuItem>
                )}
                {mapViewerMode !== MapViewerMode.NORMAL && (
                  <DropdownMenuItem
                    onClick={() => {
                      setMapViewerMode(MapViewerMode.NORMAL)
                      setConnection({ from: null, to: null })
                    }}
                  >
                    {i18n.t('backToNormalMode')}
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    setDisplayConnections(!displayConnections)
                  }}
                >
                  {displayConnections
                    ? i18n.t('closeDisplayConnections')
                    : i18n.t('displayConnections')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {imageUrl && bounds && (
          <ImageOverlay url={imageUrl} bounds={bounds} opacity={1} />
        )}
        {locations.map((location) => {
          const selected =
            location.id === selectedLocation?.id ||
            location.id === connection.from?.id ||
            location.id === connection.to?.id
          return (
            <Marker
              key={location.id}
              position={[location.lat, location.lng]}
              icon={selected ? mapPinIconSelected : mapPinIcon}
              eventHandlers={{
                click: () => {
                  if (
                    mapViewerMode === MapViewerMode.ADDING_LOCATION_CONNECTION
                  ) {
                    handleSelectConnectionTargetLocation(location)
                  } else if (setSelectedLocation) {
                    setSelectedLocation(location)
                  }
                },
              }}
            >
              {selected && (
                <Tooltip direction="top" offset={[0, -10]} permanent>
                  {location.title}
                </Tooltip>
              )}
            </Marker>
          )
        })}
        {!preventSingleLocationSheetOpen && setLocations && (
          <SingleLocationView
            setLocations={setLocations}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        )}

        {marker && setLocations && (
          <Marker position={[marker.lat, marker.lng]} icon={mapPinIconPlus}>
            <Popup>
              <Formik
                initialValues={{
                  map_location_title: '',
                }}
                onSubmit={handleAddNewLocationSubmit}
                validationSchema={validationSchemaAddMapLocation}
              >
                {({ isSubmitting }) => (
                  <Form className="flex flex-col gap-2">
                    <h2>{i18n.t('addNewMapLocation')}</h2>
                    <Label htmlFor="map_location_title">
                      {i18n.t('mapLocationTitle')}
                    </Label>
                    <Field
                      type="text"
                      id="map_location_title"
                      name="map_location_title"
                      placeholder={i18n.t('mapLocationTitle')}
                      as={Input}
                    />
                    <FormErrorMessage name="map_location_title" />
                    <div>
                      {i18n.t('latitude')} {marker.lat}
                    </div>
                    <div>
                      {i18n.t('longitude')} {marker.lng}
                    </div>
                    <PendingSubmitButton
                      buttonText={i18n.t('save')}
                      isSubmitting={isSubmitting}
                    />
                  </Form>
                )}
              </Formik>
            </Popup>
          </Marker>
        )}

        {/* Render Connections */}
        {mapViewerMode === MapViewerMode.ADDING_LOCATION_CONNECTION &&
          connection.from &&
          connection.to && (
            <Polyline
              positions={[
                [connection.from.lat, connection.from.lng] as LatLngExpression,
                [connection.to.lat, connection.to.lng] as LatLngExpression,
              ]}
              color="blue"
              smoothFactor={4}
            />
          )}

        {mapViewerMode === MapViewerMode.ADDING_LOCATION_CONNECTION &&
          allowConnectingLocations &&
          connection.from &&
          connection.to && (
            <AddNewLocationConnection
              isOpen={addNewConnectionSheetIsOpen}
              setIsOpen={setAddNewConnectionSheetIsOpen}
              connection={connection as { from: MapLocation; to: MapLocation }}
              setConnection={setConnection}
              setVisualConnections={setVisualConnections}
            />
          )}

        {displayConnections &&
          visualConnections.map((conn) => {
            // Get all connections related to this marker's location
            const relatedConnections = visualConnections.filter(
              (c) =>
                (c.map_location_from.id === conn.map_location_from.id &&
                  c.map_location_to.id === conn.map_location_to.id) ||
                (c.map_location_from.id === conn.map_location_to.id &&
                  c.map_location_to.id === conn.map_location_from.id)
            )

            return (
              <div key={conn.id}>
                <Polyline
                  positions={[
                    [
                      conn.map_location_from.lat,
                      conn.map_location_from.lng,
                    ] as LatLngExpression,
                    [
                      conn.map_location_to.lat,
                      conn.map_location_to.lng,
                    ] as LatLngExpression,
                  ]}
                  color="blue"
                  smoothFactor={4}
                />
                {/* Marker with Popup displaying all related connections */}
                <Marker
                  position={getMidpoint(
                    conn.map_location_from,
                    conn.map_location_to
                  )}
                  icon={connectionInfoIcon}
                >
                  <Popup>
                    <Label className="font-bold">
                      {i18n.t('allConnectionsHere')}
                    </Label>
                    <Table className="text-xs">
                      <TableBody>
                        {relatedConnections.map((c) => (
                          <TableRow
                            className="cursor-pointer p-0"
                            key={c.id}
                            onClick={() => {
                              setSelectedConnection(c)
                            }}
                          >
                            <TableCell className="p-2">
                              {c.completion_status ===
                              LocationConnectionCompletionStatus.COMPLETE ? (
                                <Check className="h-5 w-5 text-green-500" />
                              ) : (
                                <Clock className="h-5 w-5 text-orange-400" />
                              )}
                            </TableCell>
                            <TableCell className="p-2">
                              {c.map_location_from.title}
                            </TableCell>
                            <TableCell className="flex flex-col items-center justify-center p-2">
                              <ArrowRight className="h-5 w-5" />
                              {c.date_and_time &&
                                formatDateLocale(c.date_and_time)}
                            </TableCell>
                            <TableCell className="p-2">
                              {c.map_location_to.title}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Popup>
                </Marker>
              </div>
            )
          })}
        {selectedConnection && (
          <SingleLocationConnection
            selectedConnection={selectedConnection}
            setSelectedConnection={setSelectedConnection}
            setVisualConnections={setVisualConnections}
          />
        )}
        <MapClickHandler />
      </MapContainer>
    </Card>
  )
}
