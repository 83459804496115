import React from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb'
import { Link, useLocation } from 'react-router-dom'
import { Project } from 'src/lib/types'
import i18n from 'src/i18n'
import { useTranslation } from 'react-i18next'

interface Props {
  project: Project
}

const BreadcrumbsSingleProject = ({ project }: Props) => {
  const location = useLocation()
  const path = location.pathname
  const { t } = useTranslation()

  const projectMainViewPath = t('paths.projects') + '/' + project.id
  const projectInventarionViewPath =
    projectMainViewPath + '/' + t('paths.inventarion')
  const projectInformationViewPath =
    projectMainViewPath + '/' + t('paths.infosection')
  const projectParticipantsViewPath =
    projectMainViewPath + '/' + t('paths.projectParticipants')
  const projectDataViewPath = projectMainViewPath + '/' + t('paths.projectData')
  const projectSettingsViewPath =
    projectMainViewPath + '/' + t('paths.settings')
  const projectNotificationsViewPath =
    projectMainViewPath + '/' + t('paths.notifications')

  const projectTerminationViewPath =
    projectSettingsViewPath + '/' + t('paths.endProject')

  const projectParticipantGroupsPath =
    projectParticipantsViewPath + '/' + i18n.t('paths.groups')

  const projectHistoryViewPath = projectMainViewPath + '/' + t('paths.history')

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={i18n.t('paths.mainscreen')}>{i18n.t('mainscreen')}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          {path === projectMainViewPath ? (
            <BreadcrumbPage>{project.name}</BreadcrumbPage>
          ) : (
            <BreadcrumbLink asChild>
              <Link to={projectMainViewPath}>{project.name}</Link>
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
        {path === projectMainViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('mainView')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.includes(projectInventarionViewPath) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('inventarion')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectInformationViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectInformation')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectNotificationsViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('notifications.title')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.startsWith(projectNotificationsViewPath) &&
          path !== projectNotificationsViewPath && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbLink asChild>
                <Link to={projectNotificationsViewPath}>
                  {t('notifications.title')}
                </Link>
              </BreadcrumbLink>
              <BreadcrumbSeparator />
              {path.includes(t('paths.editNotificationSetting')) && (
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {t('notifications.modifyNotificationSetting')}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              )}
              {path.includes(t('paths.addNewNotificationSetting')) && (
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {t('addNewNotificationSetting')}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              )}
            </>
          )}
        {path == projectParticipantsViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectParticipants')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.includes(projectParticipantGroupsPath) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbLink asChild>
              <Link to={projectParticipantsViewPath}>
                {t('projectParticipants')}
              </Link>
            </BreadcrumbLink>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('groups')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectDataViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('data.title')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectSettingsViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectSettings')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path === projectHistoryViewPath && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('projectHistory')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
        {path.includes(projectTerminationViewPath) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbLink asChild>
              <Link to={projectSettingsViewPath}>{t('projectSettings')}</Link>
            </BreadcrumbLink>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('endProject')}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default BreadcrumbsSingleProject
