import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../ui/sheet'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import { Field, Form, Formik } from 'formik'
import { Textarea } from '../ui/textarea'
import { Button } from '../ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { Input } from '../ui/input'
import { Checkbox } from '../ui/checkbox'
import { validationSchemaAddNewLocationConnection } from 'src/lib/validationSchemas'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import i18n from 'src/i18n'
import { displayResponseErrorMessage } from 'src/lib/utils'
import locationConnectionService from 'src/services/LocationConnections/locationConnectionService'
import {
  Connection,
  Item,
  LocationConnection,
  MapLocation,
} from 'src/lib/types'
import { useAppContext } from 'src/context/AppProvider'
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Label } from '../ui/label'
import { ChevronsDown, ChevronsUp } from 'lucide-react'
import { toast } from 'react-toastify'
import itemsService from 'src/services/Items/items'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/fi'
import clsx from 'clsx'
import FormField from '../Forms/FormField'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  connection: { from: MapLocation; to: MapLocation }
  setConnection: Dispatch<SetStateAction<Connection>>
  setVisualConnections: Dispatch<SetStateAction<LocationConnection[]>>
}

interface FormValues {
  description: string
}

const AddNewLocationConnection = ({
  isOpen,
  setIsOpen,
  connection,
  setConnection,
  setVisualConnections,
}: Props) => {
  const { currentProject } = useAppContext()

  const [selectedMaterials, setSelectedMaterials] = useState<{
    [key: string]: number
  }>({})
  const [selectableItems, setSelectableItems] = useState<Item[]>([])
  const [isExpanded, setIsExpanded] = useState(true)
  const [date, setDate] = useState<Dayjs | null>(null)

  const fetchLocationItems = async () => {
    try {
      const response = await itemsService.getItemsByMapLocationId(
        connection.from.id,
        currentProject.id
      )
      setSelectableItems(response)
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    fetchLocationItems()
  }, [])

  const handleSubmit = async (formValues: FormValues) => {
    if (!connection.from || !connection.to) {
      return
    }

    try {
      const response = await locationConnectionService.addNewLocationConnection(
        {
          items: selectedMaterials,
          description: formValues.description,
          from_location_id: connection.from.id,
          to_location_id: connection.to.id,
          date_and_time: date?.toDate(),
        },
        currentProject.id
      )
      if (response.status === 200) {
        const createdConnection = response.data as LocationConnection
        toast.success(i18n.t('successGeneric'))
        setIsOpen(false)
        setConnection({ from: null, to: null })
        setVisualConnections((oldConnections) =>
          oldConnections.concat(createdConnection)
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleSelectAllConnectableItems = (checked: boolean) => {
    setSelectedMaterials(
      checked
        ? Object.fromEntries(selectableItems.map((item) => [item.id, 1])) // Select all
        : {} // Deselect all
    )
  }

  const allConnectableItemsSelected =
    selectableItems.length > 0 &&
    selectableItems.every((item) => selectedMaterials[item.id] >= 1)

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        if (open) {
          setIsOpen(true)
        } else {
          setIsOpen(false)
        }
      }}
      modal={false}
    >
      <SheetContent
        onInteractOutside={(e) => e.preventDefault()}
        side="bottom"
        className={`z-40 py-0 transition-height duration-300 ease-in-out ${
          isExpanded ? 'h-4/5' : 'h-1/2'
        } pointer-events-auto sm:ml-14`}
      >
        <SheetHeader className="flex flex-row items-center justify-between w-full mb-1 gap-1 flex-wrap">
          <SheetTitle>{i18n.t('addNewLocationConnection')}</SheetTitle>
          <div className="flex-1 flex justify-center">
            <Button
              variant="outline"
              onClick={() => setIsExpanded(!isExpanded)}
              className="px-2 rounded-full"
            >
              {isExpanded ? (
                <>
                  <ChevronsDown className="w-5 h-5 mr-2" />
                  {i18n.t('collapse')}
                </>
              ) : (
                <>
                  <ChevronsUp className="w-5 h-5 mr-2" />
                  {i18n.t('expand')}
                </>
              )}
            </Button>
          </div>
        </SheetHeader>

        {/* Tabs for Description and Material Connection */}

        <Formik
          initialValues={{ description: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaAddNewLocationConnection}
        >
          {({ isSubmitting, errors, touched }) => (
            <Tabs defaultValue="description" className="h-full">
              <TabsList className="flex items-center justify-center flex-wrap h-auto space-y-1">
                <TabsTrigger
                  value="description"
                  className={clsx(
                    errors.description &&
                      touched.description &&
                      'border-2 border-red-500'
                  )}
                >
                  {i18n.t('general')}
                </TabsTrigger>
                <TabsTrigger value="materials">
                  {i18n.t('connectItems')}
                </TabsTrigger>
              </TabsList>
              <Form className="h-full">
                {/* Description Tab */}
                <TabsContent
                  value="description"
                  className="flex flex-col gap-2"
                >
                  <Label>{i18n.t('direction')}</Label>
                  <p>
                    {connection.from.title} → {connection.to.title}
                  </p>
                  <Label>{i18n.t('dateAndTime')}</Label>
                  <div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fi"
                    >
                      <DateTimeField
                        disablePast
                        onChange={(val) => setDate(val)}
                        timezone="UTC"
                        value={date}
                      />
                    </LocalizationProvider>
                  </div>
                  <Label>{i18n.t('description')}</Label>
                  <FormField
                    as={Textarea}
                    name="description"
                    placeholder={i18n.t('descriptionPlaceHolder') + '...'}
                  />
                </TabsContent>
                {/* Materials Tab */}
                <TabsContent value="materials" className="h-full">
                  <div className="h-3/5 overflow-auto">
                    <div className="flex gap-2">
                      <Checkbox
                        checked={allConnectableItemsSelected}
                        onCheckedChange={(checked) =>
                          handleSelectAllConnectableItems(checked as boolean)
                        }
                      />
                      <Label>{i18n.t('selectAll')}</Label>
                    </div>
                    {selectableItems.length > 0 ? (
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>{i18n.t('select')}</TableHead>
                            <TableHead>{i18n.t('itemName')}</TableHead>

                            <TableHead>{i18n.t('category')}</TableHead>
                            <TableHead>
                              {i18n.t('itemAmount')} ({i18n.t('availableItems')}
                              /{i18n.t('reservedItems')})
                            </TableHead>
                            <TableHead>{i18n.t('connectionAmount')}</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {selectableItems.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>
                                <Checkbox
                                  checked={Boolean(selectedMaterials[item.id])}
                                  onCheckedChange={(checked) => {
                                    setSelectedMaterials((prev) =>
                                      checked
                                        ? { ...prev, [item.id]: 1 }
                                        : Object.fromEntries(
                                            Object.entries(prev).filter(
                                              ([key]) => key !== String(item.id)
                                            )
                                          )
                                    )
                                  }}
                                />
                              </TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.category}</TableCell>
                              <TableCell>
                                {item.amount} ({item.available_amount}/
                                {item.reserved_amount}) {item.unit}
                              </TableCell>

                              <TableCell>
                                <Input
                                  type="number"
                                  step="any"
                                  min={0}
                                  max={item.available_amount}
                                  disabled={!selectedMaterials[item.id]}
                                  value={selectedMaterials[item.id] ?? ''}
                                  onChange={(e) =>
                                    setSelectedMaterials((prev) => ({
                                      ...prev,
                                      [item.id]:
                                        parseFloat(e.target.value) || 0,
                                    }))
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p>{i18n.t('noItemsFound')}</p>
                    )}
                  </div>
                </TabsContent>
                <div className="absolute right-2 bottom-4 z-10">
                  <PendingSubmitButton
                    isSubmitting={isSubmitting}
                    buttonText={i18n.t('saveLocationConnection')}
                  />
                </div>
              </Form>
            </Tabs>
          )}
        </Formik>
      </SheetContent>
    </Sheet>
  )
}

export default AddNewLocationConnection
