import { Field, ErrorMessage, useField } from 'formik'
import clsx from 'clsx'
import { Label } from '../ui/label'

interface Props {
  name: string
  label?: string
  as?: React.ElementType
  type?: string
  placeholder?: string
  className?: string
}

const FormField = ({
  name,
  label,
  as = 'input',
  type = 'text',
  placeholder,
  className,
}: Props) => {
  const [field, meta] = useField(name)
  const isError = meta.touched && meta.error

  return (
    <div className="flex flex-col gap-1">
      {label && <Label htmlFor={name}>{label}</Label>}
      <Field
        {...field}
        as={as}
        type={type}
        name={name}
        placeholder={placeholder}
        className={clsx('w-full', className, isError && 'border-red-500')}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm"
      />
    </div>
  )
}

export default FormField
