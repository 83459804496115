import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import {
  AdditionalInfoFieldType,
  Item,
  ItemLastUpdateType,
} from 'src/lib/types'
import i18n from 'src/i18n'
import {
  displayResponseErrorMessage,
  formatDateLocaleExact,
} from 'src/lib/utils'
import ManageItemsButton from '../Buttons/ManageItemsButton'
import ShowLocationOnMapDialog from './ShowLocationOnMapDialog'
import AddOrUpdateItemDialog from '../Bubbles/AddOrUpdateItemDialog'
import SingleUserInfoDialog from '../Users/SingleUserInfoDialog'
import { useAppContext } from 'src/context/AppProvider'
import itemsService from 'src/services/Items/items'
import { toast } from 'react-toastify'
import AllItemTagsDialog from '../Tags/AllItemTagsDialog'
import ItemTag from '../Tags/ItemTag'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet'
import { Separator } from '../ui/separator'
import { Card, CardContent } from '../ui/card'
import { ExternalLink } from 'lucide-react'
import { iconAndTextStyling } from 'src/constants'
import ManageAdditionalInfoButton from '../AdditionalInfos/ManageAdditionalInfoButton'
import { Label } from '../ui/label'

interface Props {
  items: Item[]
  setItems: Dispatch<SetStateAction<Item[]>>
  showMapButton?: boolean
  displayLatestChangeInfo?: boolean
  displayLocation?: boolean
  tableDivClassName?: string
  fetchItems?: () => Promise<void>
}

const ItemsTable = ({
  items,
  setItems,
  showMapButton,
  displayLatestChangeInfo,
  displayLocation,
  tableDivClassName,
  fetchItems,
}: Props) => {
  const { currentUser, currentProject } = useAppContext()

  const [sheetItemData, setSheetItemData] = useState<Item | null>()

  const handleDeleteTagFromItem = async (itemId: string, tagId: string) => {
    try {
      const response = await itemsService.deleteTagFromItem(
        itemId,
        tagId,
        currentProject.id
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) =>
          oldItems.map((oldItem) =>
            oldItem.id === itemId
              ? {
                  ...oldItem,
                  tags: oldItem.tags?.filter((tag) => tag.id !== tagId),
                }
              : oldItem
          )
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <>
      <Table tableDivClassName={tableDivClassName}>
        <TableHeader>
          <TableRow>
            <TableHead>{i18n.t('product')}</TableHead>
            <TableHead>
              {i18n.t('amount')} ({i18n.t('availableItems')}/
              {i18n.t('reservedItems')})
            </TableHead>
            {displayLatestChangeInfo && (
              <TableHead>{i18n.t('change')}</TableHead>
            )}
            <TableHead>{i18n.t('unit')}</TableHead>
            <TableHead>{i18n.t('category')}</TableHead>
            <TableHead>{i18n.t('tags')}</TableHead>
            {displayLocation && <TableHead>{i18n.t('location')}</TableHead>}
            <TableHead>{i18n.t('manage')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <Sheet key={item.id + item.updated_at} modal={false}>
              <SheetTrigger asChild>
                <TableRow
                  data-testid={`items-table-row-${item.name}`}
                  className="cursor-pointer"
                >
                  <TableCell className="font-medium">{item.name}</TableCell>
                  <TableCell>
                    {item.amount}{' '}
                    {item.available_amount != null &&
                      item.reserved_amount != null && (
                        <>
                          ({item.available_amount}/{item.reserved_amount})
                        </>
                      )}
                  </TableCell>
                  {displayLatestChangeInfo && (
                    <TableCell data-testid={`latest-change-info-${item.name}`}>
                      {(item.last_update_type ===
                        ItemLastUpdateType.INCREASE_AMOUNT ||
                        item.last_update_type ===
                          ItemLastUpdateType.DECREASE_AMOUNT ||
                        item.last_update_type ===
                          ItemLastUpdateType.ADD_WASTE ||
                        item.last_update_type === ItemLastUpdateType.MOVE) &&
                      item.last_update_amount
                        ? `${item.last_update_amount > 0 ? '+' : ''}${item.last_update_amount} ${
                            item.last_update_type ===
                            ItemLastUpdateType.ADD_WASTE
                              ? `(${i18n.t('addedWaste')})`
                              : item.last_update_type ===
                                  ItemLastUpdateType.MOVE
                                ? `(${i18n.t('itemsMoved')})`
                                : ''
                          }`
                        : item.last_update_type === ItemLastUpdateType.CREATION
                          ? `${i18n.t('itemCreated')}`
                          : item.last_update_type === ItemLastUpdateType.MOVE
                            ? `${i18n.t('itemsMoved')}`
                            : item.last_update_type ===
                                ItemLastUpdateType.CHANGE_NAME
                              ? `${i18n.t('nameChanged')}`
                              : item.last_update_type ===
                                  ItemLastUpdateType.CHANGE_CATEGORY
                                ? `${i18n.t('categoryChanged')}`
                                : item.last_update_type ===
                                    ItemLastUpdateType.CHANGE_UNIT
                                  ? `${i18n.t('unitChanged')}`
                                  : null}
                    </TableCell>
                  )}
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>
                    <div className="flex flex-col gap-1">
                      {item.tags?.slice(0, 3).map((tag) => {
                        return (
                          <div key={tag.id}>
                            <ItemTag
                              item={item}
                              tag={tag}
                              handleDeleteTagFromItem={handleDeleteTagFromItem}
                            />
                          </div>
                        )
                      })}
                      {item.tags && item.tags.length > 3 && (
                        <AllItemTagsDialog
                          item={item}
                          handleDeleteTagFromItem={handleDeleteTagFromItem}
                        />
                      )}
                    </div>
                  </TableCell>
                  {displayLocation && (
                    <TableCell>{item.location_title}</TableCell>
                  )}
                  <TableCell
                    className="flex gap-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.amount != 0 && (
                      <AddOrUpdateItemDialog item={item} setItems={setItems} />
                    )}
                    {showMapButton && <ShowLocationOnMapDialog item={item} />}
                    <ManageItemsButton
                      item={item}
                      setItems={setItems}
                      fetchItems={fetchItems}
                    />
                  </TableCell>
                </TableRow>
              </SheetTrigger>
              <SheetContent className="overflow-auto">
                <SheetHeader>
                  <SheetTitle>{item.name}</SheetTitle>
                </SheetHeader>
                {/* Item Basic Info */}
                <div className="space-y-4 text-sm">
                  <Separator />
                  <Card>
                    <CardContent className="p-0 space-y-2">
                      <div className="flex justify-between">
                        <Label>{i18n.t('category')}:</Label>
                        <span>{item.category || i18n.t('notFoundNA')}</span>
                      </div>
                      {item.location_title && (
                        <div className="flex justify-between">
                          <Label>{i18n.t('location')}:</Label>
                          <span>{item.location_title}</span>
                        </div>
                      )}
                      <div className="flex justify-between">
                        <Label>{i18n.t('amount')}:</Label>
                        <span>
                          {item.amount} {item.unit}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <Label>{i18n.t('availableItems')}:</Label>
                        <span>
                          {item.available_amount} {item.unit}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <Label>{i18n.t('reservedItems')}:</Label>
                        <span>
                          {item.reserved_amount} {item.unit}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <Label>{i18n.t('lastModified')}:</Label>
                        <span>
                          {formatDateLocaleExact(
                            item.updated_at.toString(),
                            currentUser
                          )}
                        </span>
                      </div>
                    </CardContent>
                  </Card>

                  <Separator />

                  {/* Latest Editor */}
                  <div className="flex flex-wrap gap-2">
                    <p className="font-semibold">{i18n.t('lastModifiedBy')}:</p>
                    {item.latest_editor_email ? (
                      <SingleUserInfoDialog email={item.latest_editor_email} />
                    ) : (
                      <p className="text-muted-foreground">
                        {i18n.t('notFoundNA')}
                      </p>
                    )}
                  </div>

                  <Separator />

                  {/* Tags Section */}
                  {item.tags && item.tags.length > 0 && (
                    <>
                      <Label>{i18n.t('tags')}:</Label>
                      {item.tags?.map((tag) => (
                        <div key={tag.id}>
                          <ItemTag
                            item={item}
                            tag={tag}
                            handleDeleteTagFromItem={handleDeleteTagFromItem}
                          />
                        </div>
                      ))}

                      <Separator />
                    </>
                  )}

                  {/* Additional Info Fields */}
                  {item.additional_info_fields?.map((additionalInfo) => (
                    <div key={additionalInfo.id}>
                      <div className="flex flex-wrap gap-2 justify-between">
                        <div>
                          <p className="font-semibold">{additionalInfo.key}:</p>
                          {additionalInfo.type ===
                          AdditionalInfoFieldType.LINK ? (
                            <a
                              href={additionalInfo.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={
                                'text-blue-500 hover:underline flex items-center gap-1'
                              }
                            >
                              {i18n.t('openLink')}{' '}
                              <ExternalLink className="w-5 h-5" />
                            </a>
                          ) : (
                            <p
                              className={
                                additionalInfo.type ===
                                AdditionalInfoFieldType.MULTILINE_STRING
                                  ? 'whitespace-break-spaces'
                                  : ''
                              }
                            >
                              {additionalInfo.value}
                            </p>
                          )}
                        </div>
                        <ManageAdditionalInfoButton
                          item={item}
                          additionalInfo={additionalInfo}
                          setItems={setItems}
                        />
                      </div>
                      <Separator className="mt-2" />
                    </div>
                  ))}
                </div>
              </SheetContent>
            </Sheet>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ItemsTable
