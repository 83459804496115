import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar/Navbar'
import RegisterView from './views/Register/RegisterView'
import EmailVerificationView from './views/Register/EmailVerificationView'
import MainScreenView from './views/MainScreen/MainScreenView'
import SingleProjectView from './views/SingleProject/SingleProjectView'
import AddNewProjectView from './views/AddNewProject/AddNewProjectView'
import ProjectInventarionView from './views/SingleProject/ProjectInventarionView'
import { useTranslation } from 'react-i18next'
import ProjectHistoryView from './views/SingleProject/ProjectHistoryView'
import ProfileView from './views/Profile/ProfileView'
import ProjectParticipantsView from './views/SingleProject/ProjectParticipantsView'
import ProjectInfoSectionView from './views/SingleProject/ProjectInfoSectionView'
import { useAuth } from './context/authContext'
import ProfileLayout from './Layouts/ProfileLayout'
import ProfileSettingsView from './views/Profile/ProfileSettingsView'
import ForgotPasswordView from './views/ForgotPassword/ForgotPasswordView'
import ChangePasswordView from './views/ChangePasswordView/ChangePasswordView'
import UserProvider from './context/UserProvider'
import ProjectLayout from './Layouts/ProjectLayout'
import CreateCustomershipView from './views/CreateCustomershipView/CreateCustomershipView'
import CustomershipPortalMainView from './views/CustomershipPortal/CustomershipPortalMainView'
import CustomershipsLayout from './Layouts/CustomershipsLayout'
import SingleCustomershipView from './views/CustomershipPortal/SingleCustomershipView'
import InviteUsersToCustomershipView from './views/CustomershipPortal/InviteUsersToCustomershipView'
import CustomershipParticipantsView from './views/CustomershipPortal/CustomershipParticipantsView'
import ProjectSettingsView from './views/SingleProject/ProjectSettingsView'
import EndProjectView from './views/SingleProject/EndProjectView'
import ModifyCustomershipView from './views/CustomershipPortal/ModifyCustomershipView'
import SingleCustomershipLayout from './Layouts/SingleCustomershipLayout'
import AdminPanelLayout from './Layouts/AdminPanelLayout'
import AdminPanelMainView from './views/AdminPanel/AdminPanelMainView'
import SmallLoadingCircleOnly from './components/Loading/SmallLoadingCircle'
import TermsOfServiceView from './views/TermsOfService/TermsOfServiceView'
import LoginForm from './components/Login/LoginForm'
import ProjectDataView from './views/SingleProject/ProjectDataView'
import ProjectNotificationsView from './views/SingleProject/ProjectNotificationsView'
import AddNewNotificationSettingView from './views/Notifications/AddNewNotificationSettingView'
import ProjectParticipantGroupsView from './views/SingleProject/ProjectParticipantGroupsView'
import ModifyNotificationSettingView from './components/Notifications/ModifyNotificationSettingView'

function App() {
  const { t } = useTranslation()
  const { isLoggedIn, languageIsSet, handleLogin, handleLogout } = useAuth()

  if (!languageIsSet) {
    return <SmallLoadingCircleOnly />
  }

  return (
    <div>
      <Navbar onLogoutSuccess={handleLogout} />
      <Routes>
        <Route element={<UserProvider />}>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to={t('paths.mainscreen')} />
              ) : (
                <LoginForm onLoginSuccess={handleLogin} />
              )
            }
          />
          <Route
            path={t('paths.customershipPortal')}
            element={<CustomershipsLayout />}
          >
            <Route path="" element={<CustomershipPortalMainView />} />
            <Route
              path={t('paths.ownCustomerships') + '/:customershipId'}
              element={<SingleCustomershipLayout />}
            >
              <Route path={t('paths.manageCustomership')}>
                <Route path="" element={<SingleCustomershipView />} />
                <Route
                  path={t('paths.modifyCustomership')}
                  element={<ModifyCustomershipView />}
                />
                <Route
                  path={t('paths.inviteUsers')}
                  element={<InviteUsersToCustomershipView />}
                />
                <Route
                  path={t('paths.customershipParticipants')}
                  element={<CustomershipParticipantsView />}
                />
              </Route>
            </Route>
          </Route>
          <Route path={t('paths.mainscreen')} element={<MainScreenView />} />
          <Route path={t('paths.register')} element={<RegisterView />} />
          <Route
            path={t('paths.verifyEmail') + '/:token'}
            element={<EmailVerificationView />}
          />

          <Route path={t('paths.profile')} element={<ProfileLayout />}>
            <Route path="" element={<ProfileView />} />
            <Route
              path={t('paths.settings')}
              element={<ProfileSettingsView />}
            />
          </Route>
          <Route path={t('paths.projects')}>
            <Route path=":projectId" element={<ProjectLayout />}>
              <Route path="" element={<SingleProjectView />} />
              <Route path={t('paths.notifications')}>
                <Route path="" element={<ProjectNotificationsView />} />
                <Route
                  path={t('paths.addNewNotificationSetting')}
                  element={<AddNewNotificationSettingView />}
                />
                <Route path=":notificationSettingId">
                  <Route
                    path={t('paths.editNotificationSetting')}
                    element={<ModifyNotificationSettingView />}
                  />
                </Route>
              </Route>
              <Route
                path={t('paths.inventarion')}
                element={<ProjectInventarionView />}
              />
              <Route
                path={t('paths.history')}
                element={<ProjectHistoryView />}
              />
              <Route
                path={t('paths.infosection')}
                element={<ProjectInfoSectionView />}
              />
              <Route path={t('paths.projectParticipants')}>
                <Route path="" element={<ProjectParticipantsView />} />
                <Route
                  path={t('paths.groups')}
                  element={<ProjectParticipantGroupsView />}
                />
              </Route>
              <Route
                path={t('paths.projectData')}
                element={<ProjectDataView />}
              />
              <Route path={t('paths.settings')}>
                <Route path={''} element={<ProjectSettingsView />} />
                <Route
                  path={t('paths.endProject')}
                  element={<EndProjectView />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path={t('paths.forgotpassword')}
            element={<ForgotPasswordView />}
          />
          <Route
            path={t('paths.changepassword') + '/:token'}
            element={<ChangePasswordView />}
          />
        </Route>
        <Route path="/adminpanel" element={<AdminPanelLayout />}>
          <Route path="" element={<AdminPanelMainView />} />

          <Route
            path={t('paths.addNewProject')}
            element={<AddNewProjectView />}
          />

          <Route
            path={t('paths.createCustomership')}
            element={<CreateCustomershipView />}
          />
        </Route>
        <Route
          path={t('paths.termsOfService')}
          element={<TermsOfServiceView />}
        />
      </Routes>
      <ToastContainer />
    </div>
  )
}

export default App
