import { Blueprint, Item, MapLocation, MapViewerMode } from 'src/lib/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '../ui/sheet'
import AddOrUpdateItemDialog from '../Bubbles/AddOrUpdateItemDialog'
import { displayResponseErrorMessage } from 'src/lib/utils'
import i18n from 'src/i18n'
import ItemsTable from '../Items/ItemsTable'
import { Button } from '../ui/button'
import { ChevronsDown, ChevronsUp, RefreshCcw, TrashIcon } from 'lucide-react'
import { useAppContext } from 'src/context/AppProvider'
import blueprintsService from 'src/services/Blueprints/blueprints'
import BlueprintDisplay from '../Blueprints/BlueprintDisplay'
import itemsService from 'src/services/Items/items'
import { iconAndTextStyling } from 'src/constants'
import mapLocationsService from 'src/services/MapLocations/mapLocationsService'
import { toast } from 'react-toastify'
import { useConfirmDialogUnStableTarget } from '../ui/confirmDialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'

interface Props {
  setLocations: Dispatch<SetStateAction<MapLocation[]>>
  selectedLocation: MapLocation | null
  setSelectedLocation?: Dispatch<SetStateAction<MapLocation | null>>
}

enum LocationViewType {
  ITEMS = 'ITEMS',
  BLUEPRINTS = 'BLUEPRINTS',
}

const SingleLocationView = ({
  setLocations,
  selectedLocation,
  setSelectedLocation,
}: Props) => {
  const [items, setItems] = useState<Item[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [blueprints, setBlueprints] = useState<Blueprint[]>([])
  const [selectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(
    null
  )
  const [locationViewType, setLocationViewType] = useState<LocationViewType>(
    LocationViewType.ITEMS
  )
  const { prompt, result, reset } = useConfirmDialogUnStableTarget()
  const { currentProject } = useAppContext()

  useEffect(() => {
    if (result) {
      deleteMapLocation()
      reset()
    }
  }, [result, reset])

  const fetchItems = async () => {
    if (!selectedLocation) {
      return
    }
    try {
      setLoading(true)
      const responseItems = await itemsService.getItemsByMapLocationId(
        selectedLocation.id,
        currentProject.id
      )
      setItems(responseItems)
    } catch (error) {
      displayResponseErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchBlueprints = async () => {
    if (!selectedLocation) {
      return
    }
    try {
      setLoading(true)
      const response =
        await blueprintsService.getBlueprintsByMapLocationIdAndProjectId(
          currentProject.id,
          selectedLocation.id
        )
      if (response.status === 200) {
        const fetchedBlueprints = response.data
        setBlueprints(fetchedBlueprints)
        setSelectedBlueprint(fetchedBlueprints[0])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSelection = (value: string) => {
    const selected = blueprints.find((blueprint) => blueprint.id === value)
    setSelectedBlueprint(selected || null)
  }

  const deleteMapLocation = async () => {
    if (!selectedLocation || !setSelectedLocation) {
      return
    }
    try {
      const response = await mapLocationsService.deleteMapLocationById(
        selectedLocation.id,
        currentProject.id
      )
      if (response.status === 200) {
        setLocations((oldLocations) =>
          oldLocations.filter((location) => location.id !== response.data)
        )
        setSelectedLocation(null)
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleDeleteMapLocation = async () => {
    if (!(items.length === 0 && blueprints.length === 0)) {
      toast.error(i18n.t('locationCannotBeDeleted'))
      return
    }
    prompt({
      title: i18n.t('confirmDelete'),
    })
  }

  const initSingleLocation = () => {
    if (selectedLocation) {
      if (locationViewType === LocationViewType.ITEMS) {
        fetchItems()
      } else if (locationViewType === LocationViewType.BLUEPRINTS) {
        fetchBlueprints()
      }
    }
  }

  useEffect(() => {
    initSingleLocation()
  }, [locationViewType, selectedLocation])

  return (
    <Sheet
      open={!!selectedLocation}
      onOpenChange={(open) => {
        if (open) {
          // On open
        } else {
          // On close
          if (setSelectedLocation) {
            setSelectedLocation(null)
          }
        }
      }}
      modal={false}
    >
      <SheetContent
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
        side="bottom"
        className={`z-40 py-0 transition-height duration-300 ease-in-out ${
          isExpanded ? 'h-4/5' : 'h-1/2'
        } pointer-events-auto sm:ml-14`}
      >
        {selectedLocation && (
          <>
            {/* Header */}
            <SheetHeader className="flex flex-row items-center justify-between w-full mb-1 gap-1 flex-wrap">
              <SheetTitle>{selectedLocation.title}</SheetTitle>
              <div className="flex gap-2 flex-wrap">
                {items.length === 0 && blueprints.length === 0 && (
                  <Button
                    variant={'destructive'}
                    className={iconAndTextStyling}
                    size={'sm'}
                    onClick={handleDeleteMapLocation}
                  >
                    <TrashIcon />
                    {i18n.t('delete')}
                  </Button>
                )}
                <div className="w-fit">
                  <Select
                    onValueChange={(value) =>
                      setLocationViewType(value as LocationViewType)
                    }
                    value={locationViewType}
                  >
                    <SelectTrigger data-testid="select-move-level-trigger">
                      <SelectValue placeholder={i18n.t('locationviewtype')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem
                        key={LocationViewType.ITEMS}
                        value={LocationViewType.ITEMS}
                        data-testid={`select-locationviewtype-${LocationViewType.ITEMS}`}
                      >
                        {i18n.t(`locationViewTypes.${LocationViewType.ITEMS}`)}
                      </SelectItem>
                      <SelectItem
                        key={LocationViewType.BLUEPRINTS}
                        value={LocationViewType.BLUEPRINTS}
                        data-testid={`select-locationviewtype-${LocationViewType.BLUEPRINTS}`}
                      >
                        {i18n.t(
                          `locationViewTypes.${LocationViewType.BLUEPRINTS}`
                        )}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Button
                  size={'sm'}
                  variant={'secondary'}
                  onClick={initSingleLocation}
                >
                  <RefreshCcw />
                </Button>
              </div>
              <div className="flex-1 flex justify-center">
                <Button
                  variant="outline"
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="px-2 rounded-full"
                >
                  {isExpanded ? (
                    <>
                      <ChevronsDown className="w-5 h-5 mr-2" />
                      {i18n.t('collapse')}
                    </>
                  ) : (
                    <>
                      <ChevronsUp className="w-5 h-5 mr-2" />
                      {i18n.t('expand')}
                    </>
                  )}
                </Button>
              </div>
            </SheetHeader>
            <div className="absolute bottom-4 z-10">
              <AddOrUpdateItemDialog
                mapLocationId={selectedLocation.id}
                setItems={setItems}
              />
            </div>
            {loading ? (
              <SmallLoadingCircleOnly />
            ) : locationViewType === LocationViewType.ITEMS ? (
              <div className="h-4/5 overflow-auto">
                <ItemsTable
                  items={items}
                  setItems={setItems}
                  fetchItems={fetchItems}
                />
              </div>
            ) : locationViewType === LocationViewType.BLUEPRINTS ? (
              <BlueprintDisplay
                handleSelection={handleSelection}
                blueprints={blueprints}
                selectedBlueprint={selectedBlueprint}
                setBlueprints={setBlueprints}
                setSelectedBlueprint={setSelectedBlueprint}
                mapLocationId={selectedLocation.id}
              />
            ) : null}
          </>
        )}
      </SheetContent>
    </Sheet>
  )
}
export default SingleLocationView
