import React, { Dispatch, SetStateAction } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Button } from '../ui/button'
import { iconAndTextStyling } from 'src/constants'
import { Ellipsis } from 'lucide-react'
import i18n from 'src/i18n'
import { AdditionalInfoField, Item } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import itemsService from 'src/services/Items/items'
import { useAppContext } from 'src/context/AppProvider'
import { useConfirmaDialogStableTarget } from '../ui/confirmDialog'
import { toast } from 'react-toastify'
import AddOrUpdateAdditionalInfoFieldDialog from './AddOrUpdateAdditionalInfoFieldDialog'

interface Props {
  item: Item
  setItems: Dispatch<SetStateAction<Item[]>>
  additionalInfo: AdditionalInfoField
}

const ManageAdditionalInfoButton = ({
  item,
  setItems,
  additionalInfo,
}: Props) => {
  const { currentProject } = useAppContext()
  const confirm = useConfirmaDialogStableTarget()
  const handleDelete = async () => {
    const confirmed = await confirm({
      title: i18n.t('confirmDelete'),
    })

    if (!confirmed) {
      return
    }
    try {
      await itemsService.deleteAdditionalInfoFieldFromItem(
        item.id,
        additionalInfo.id,
        currentProject.id
      )
      toast.success(i18n.t('successGeneric'))
      setItems((oldItems) =>
        oldItems.map((oldItem) =>
          oldItem.id === item.id
            ? {
                ...oldItem,
                additional_info_fields: oldItem.additional_info_fields?.filter(
                  (field) => field.id !== additionalInfo.id
                ),
              }
            : oldItem
        )
      )
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={'ghost'} className={iconAndTextStyling}>
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{i18n.t('manage')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleDelete}>
          {i18n.t('delete')}
        </DropdownMenuItem>
        <AddOrUpdateAdditionalInfoFieldDialog
          item={item}
          setItems={setItems}
          additionalInfoField={additionalInfo}
          renderInDropDownMenu
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ManageAdditionalInfoButton
