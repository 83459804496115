import React from 'react'
import i18n from 'src/i18n'

interface FilePreviewTableProps {
  previewData: any[]
  isPreview: boolean
}

const FilePreviewTable: React.FC<FilePreviewTableProps> = ({
  previewData,
  isPreview,
}) => {
  if (!Array.isArray(previewData) || previewData.length === 0) {
    return <p className="text-sm text-gray-500"></p>
  }
  // Extract headers dynamically, but exclude "id"
  const headers = Object.keys(previewData[0]).filter((key) => key !== 'id')

  return (
    <div className="p-4">
      {isPreview ? (
        headers.length > 0 ? (
          <div className="overflow-auto max-h-96">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {previewData.map((row: any, rowIndex: number) => (
                  <tr key={rowIndex}>
                    {headers.map((header, cellIndex) => (
                      <td
                        key={cellIndex}
                        className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                      >
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-sm text-gray-500">
            {i18n.t('data.emptyFileError')}
          </p>
        )
      ) : (
        <p className="text-sm text-gray-500">
          {i18n.t('data.noPreviewAvailable')}
        </p>
      )}
    </div>
  )
}

export default FilePreviewTable
