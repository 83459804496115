import React, { useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import { displayResponseErrorMessage } from 'src/lib/utils'
import usersService from 'src/services/Users/users'
import { ForeignUserData } from 'src/lib/types'
import i18n from 'src/i18n'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { useParams } from 'react-router'
import { Separator } from '../ui/separator'
import { AxiosResponse } from 'axios'

interface Props {
  email: string
}

const SingleUserInfoDialog = ({ email }: Props) => {
  const { projectId, customershipId } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useState<ForeignUserData>()

  const fetchUserData = async () => {
    try {
      setLoading(true)
      let response: AxiosResponse | undefined
      if (projectId && !customershipId) {
        response = await usersService.getProjectUserInfoDataByEmail(
          email,
          projectId
        )
      } else if (customershipId && !projectId) {
        response = await usersService.getCustomershipUserInfoDataByEmail(
          email,
          customershipId
        )
      }
      if (!response) {
        throw new Error('Response failed')
      }
      if (response.status === 200) {
        setUser(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog onOpenChange={async (open) => open && (await fetchUserData())}>
      <DialogTrigger asChild>
        <p className="underline cursor-pointer">{email}</p>
      </DialogTrigger>
      <DialogContent>
        {loading ? (
          <SmallLoadingCircleOnly />
        ) : user ? (
          <DialogHeader>
            <DialogTitle>{user.userData.email}</DialogTitle>
            <Separator />
            <DialogDescription>
              {i18n.t('phone')}: {user.userData.phone}
              <br />
              {i18n.t('organization')}: {user.userData.organization ?? '-'}
            </DialogDescription>
          </DialogHeader>
        ) : (
          <div>{i18n.t('errorFetchingUserData')}</div>
        )}
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">{i18n.t('close')}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SingleUserInfoDialog
