import { Notification, NotificationStatus } from 'src/lib/types'
import i18n from 'src/i18n'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { BellDot, CheckIcon } from 'lucide-react'
import ManageNotificationButton from './ManageNotificationButton'
import { Dispatch, SetStateAction } from 'react'
import { formatDateAsFinnishLocale } from 'src/lib/utils'

interface Props {
  notifications: Notification[]
  setNotifications: Dispatch<SetStateAction<Notification[]>>
}

const NotificationsTable = ({ notifications, setNotifications }: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{i18n.t('status')}</TableHead>
          <TableHead>{i18n.t('itemName')}</TableHead>
          <TableHead>{i18n.t('minThresholdAmount')}</TableHead>
          <TableHead>{i18n.t('amount')}</TableHead>
          <TableHead>{i18n.t('notifications.scope')}</TableHead>
          <TableHead>{i18n.t('createdAt')}</TableHead>
          <TableHead>{i18n.t('manage')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {notifications.map((notification) => (
          <TableRow key={notification.id}>
            <TableCell>
              {notification.status === NotificationStatus.DONE ? (
                <CheckIcon className="text-green-500" />
              ) : (
                <BellDot className="text-blue-500" />
              )}
            </TableCell>
            <TableCell>{notification.item_name}</TableCell>
            <TableCell>
              {notification.min_stock_threshold} {notification.unit}
            </TableCell>
            <TableCell>
              {notification.current_amount}
              {notification.unit}
            </TableCell>
            <TableCell>
              {notification.map_location_name ??
                i18n.t('notifications.entireProject')}
            </TableCell>
            <TableCell>
              {formatDateAsFinnishLocale(notification.created_at)}
            </TableCell>
            <TableCell>
              <ManageNotificationButton
                notification={notification}
                setNotifications={setNotifications}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default NotificationsTable
