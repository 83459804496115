import React from 'react'
import i18n from 'src/i18n'
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { Button } from 'src/components/ui/button'

export const ProjectDataInfo = () => {
  return (
    <Card className="my-2" data-testid="project-info-card">
      <CardHeader>
        <CardTitle className="pb-4">{i18n.t('info.title')}</CardTitle>
        <CardDescription className="whitespace-pre-wrap space-y-6">
          {/* === Imports Section === */}
          <div className="max-w-[50%] space-y-4">
            <div>
              <strong className="block mb-1">
                {i18n.t('info.imports.title')}
              </strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t('info.imports.description')
                    .replace(/\n/g, '<br />'),
                }}
              />
              <div className="mt-2">
                <Button asChild>
                  <a href="/import_template.xlsx" download>
                    {i18n.t('info.imports.downloadTemplate')}
                  </a>
                </Button>
              </div>
            </div>
            <div>
              <strong className="block mb-1">
                {i18n.t('info.imports.usageTitle')}
              </strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t('info.imports.usageDescription')
                    .replace(/\n/g, '<br />'),
                }}
              />
            </div>
          </div>

          {/* === Divider === */}
          <hr className="my-4 border-gray-300" />

          {/* === Data Charts Section === */}
          <div className="max-w-[50%] space-y-4">
            <div>
              <strong className="block mb-1">
                {i18n.t('info.charts.title')}
              </strong>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t('info.charts.description')
                    .replace(/\n/g, '<br />'),
                }}
              />
            </div>
          </div>
        </CardDescription>
      </CardHeader>
    </Card>
  )
}
