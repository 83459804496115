import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import i18n from 'src/i18n'
import ErrorPage from '../ErrorPage/ErrorPage'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { useAppContext } from 'src/context/AppProvider'
import projectsService from 'src/services/Projects/projects'
import { Project, UserProjectRoles } from 'src/lib/types'
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from 'src/components/ui/tabs'
import { isProjectActive } from 'src/lib/utils'
import { Upload, ChartNoAxesCombined, Info } from 'lucide-react'
import { labelTextStyling } from 'src/constants'
import { ProjectDataDashboard } from 'src/components/ProjectData/ProjectDataDashboard'
import { ImportProjectData } from 'src/components/ProjectData/ImportProjectData'
import { ProjectDataInfo } from 'src/components/ProjectData/ProjectDataInfo'

const ProjectDataView = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState<Project | null>(null)
  const { currentUser, currentProject } = useAppContext()

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const response = await projectsService.getProjectById(projectId)
          if (response.status === 200) {
            setProject(response.data)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchProject()
  }, [projectId])

  if (!project) {
    return <SmallLoadingCircleOnly />
  }
  if (!currentProject) {
    return <ErrorPage />
  }
  const projectIsActive = isProjectActive(project)

  return (
    <div className="mx-2">
      <h1 className="mb-4">{i18n.t('data.title')}</h1>
      {projectIsActive &&
        currentUser.project_role === UserProjectRoles.ADMIN && (
          <Tabs defaultValue="dashboard">
            <TabsList className="inline-grid grid-cols-3 gap-2">
              <TabsTrigger value="dashboard" className={labelTextStyling}>
                <ChartNoAxesCombined />
              </TabsTrigger>
              <TabsTrigger value="import" className={labelTextStyling}>
                <Upload />
              </TabsTrigger>
              <TabsTrigger value="info" className={labelTextStyling}>
                <Info />
              </TabsTrigger>
            </TabsList>

            <TabsContent value="dashboard">
              <ProjectDataDashboard />
            </TabsContent>

            <TabsContent value="import">
              <ImportProjectData projectId={currentProject.id} />
            </TabsContent>

            <TabsContent value="info">
              <ProjectDataInfo />
            </TabsContent>
          </Tabs>
        )}
    </div>
  )
}

export default ProjectDataView
