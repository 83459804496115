import { Home, LogIn } from 'lucide-react'
import { Link } from 'react-router-dom'
import BackButton from 'src/components/Buttons/BackButton'
import SendChangePasswordMailForm from 'src/components/Forms/SendChangePasswordMailForm'
import { Button } from 'src/components/ui/button'
import { iconAndTextStyling } from 'src/constants'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import { currentUserDataIsDefined } from 'src/lib/utils'

const ForgotPasswordView = () => {
  const { currentUser } = useAppContext()
  return (
    <div className="m-2 flex flex-col items-center">
      <div className="flex flex-row gap-2 flex-wrap mb-2">
        <BackButton />
        <Link to={'/'}>
          <Button className={iconAndTextStyling} variant={'secondary'}>
            {currentUserDataIsDefined(currentUser) ? (
              <>
                <Home /> {i18n.t('backToMainscreen')}
              </>
            ) : (
              <>
                <LogIn /> {i18n.t('backToLoginPage')}
              </>
            )}
          </Button>
        </Link>
      </div>
      <SendChangePasswordMailForm />
    </div>
  )
}

export default ForgotPasswordView
