import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/participant-groups'

const addNewParticipantGroup = async (
  data: {
    name: string
  },
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/projects/${project_id}/add-new-group`,
    data
  )
  return response
}

const getAllGroupsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/projects/${project_id}/get-all-groups`
  )
  return response
}

const getGroupById = async (
  project_id: string,
  group_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/${group_id}/projects/${project_id}`
  )
  return response
}

const addUsersToGroup = async (
  project_id: string,
  group_id: string,
  userIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${group_id}/projects/${project_id}/add-users`,
    {
      user_ids: userIds,
    }
  )
  return response
}

const deleteUsersFromGroup = async (
  project_id: string,
  group_id: string,
  userIds: string[]
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${group_id}/projects/${project_id}/delete-users`,
    {
      user_ids: userIds,
    }
  )
  return response
}

const deleteGroup = async (
  project_id: string,
  group_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/${group_id}/projects/${project_id}`
  )
  return response
}

const updateGroupData = async (
  project_id: string,
  group_id: string,
  data: {
    name: string
  }
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${group_id}/projects/${project_id}/update-group`,
    data
  )
  return response
}

export default {
  addNewParticipantGroup,
  getAllGroupsByProjectId,
  getGroupById,
  addUsersToGroup,
  deleteUsersFromGroup,
  deleteGroup,
  updateGroupData,
}
