import { Form, Formik } from 'formik'
import { Plus, Settings } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import NotificationsTable from 'src/components/Notifications/NotificationsTable'
import { Button } from 'src/components/ui/button'
import { Card, CardContent } from 'src/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/components/ui/tabs'
import { useAppContext } from 'src/context/AppProvider'
import i18n from 'src/i18n'
import {
  Notification,
  NotificationSetting,
  NotificationStatus,
  UserProjectRoles,
} from 'src/lib/types'
import {
  displayResponseErrorMessage,
  formatDateAsFinnishLocale,
} from 'src/lib/utils'
import notificationsService from 'src/services/Notifications/notificationsService'

const ProjectNotificationsView = () => {
  const { currentProject, setRenderRefreshAlert, currentUser } = useAppContext()
  const [checking, setChecking] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [notificationSettings, setNotificationSettings] = useState<
    NotificationSetting[]
  >([])
  const [isNotificationsCheckDialogOpen, setIsNotificationsCheckDialogOpen] =
    useState<boolean>(false)

  const fetchNotificationSettings = async () => {
    try {
      const response =
        await notificationsService.getAllUsersNotificationSettingsByProjectId(
          currentProject.id
        )
      if (response.status === 200) {
        setNotificationSettings(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const fetchNotifications = async () => {
    try {
      const response =
        await notificationsService.getAllUsersNotificationsByProjectId(
          currentProject.id
        )
      if (response.status === 200) {
        setNotifications(response.data)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  useEffect(() => {
    fetchNotificationSettings()
    fetchNotifications()
  }, [])

  const handleCheckNotifications = async () => {
    try {
      const response = await notificationsService.checkProjectsNotifications(
        currentProject.id
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    } finally {
      setIsNotificationsCheckDialogOpen(false)
      setRenderRefreshAlert(true)
    }
  }

  const newNotifications = notifications.filter(
    (n) => n.status === NotificationStatus.NEW
  )
  const doneNotifications = notifications.filter(
    (n) => n.status === NotificationStatus.DONE
  )

  return (
    <div className="mx-2">
      <Tabs defaultValue="unread">
        <TabsList className="flex items-center justify-center flex-wrap h-auto space-y-1">
          <TabsTrigger value="unread">
            {i18n.t('notifications.new')}
          </TabsTrigger>
          <TabsTrigger value="ready">
            {i18n.t('notifications.done')}
          </TabsTrigger>
          <TabsTrigger value="settings">
            {i18n.t('notifications.notificationSettings')}
          </TabsTrigger>
        </TabsList>
        {currentUser.project_role === UserProjectRoles.ADMIN && (
          <div className="flex flex-wrap gap-2 mt-2">
            <Link to={i18n.t('paths.addNewNotificationSetting')}>
              <Button
                variant={'secondary'}
                data-testid="invite-users-to-customership-link-button"
                className={'w-full'}
              >
                <Plus /> {i18n.t('addNewNotificationSetting')}
              </Button>
            </Link>
            <Dialog
              open={isNotificationsCheckDialogOpen}
              onOpenChange={(open) => {
                setIsNotificationsCheckDialogOpen(open)
              }}
            >
              <DialogTrigger asChild>
                <Button>{i18n.t('notifications.checkNotifications')}</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    {i18n.t('notifications.checkNotifications')}
                  </DialogTitle>
                  <DialogDescription>
                    <strong>
                      {i18n.t('notifications.latestNotificationCheckDoneAt')}
                    </strong>
                    :{' '}
                    {currentProject.latest_notification_check_at
                      ? formatDateAsFinnishLocale(
                          currentProject.latest_notification_check_at
                        )
                      : '-'}
                  </DialogDescription>
                </DialogHeader>
                <div>
                  <Formik
                    onSubmit={async (values, { setSubmitting }) => {
                      setChecking(true) // Start loading state
                      await handleCheckNotifications() // Call your function
                      setChecking(false) // Stop loading state
                      setSubmitting(false)
                    }}
                    initialValues={{}} // Ensure Formik initializes correctly
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Button
                          type="submit"
                          disabled={isSubmitting || checking}
                        >
                          {checking
                            ? i18n.t('notifications.checking')
                            : i18n.t('notifications.startCheckNotifications')}
                        </Button>
                        {checking && (
                          <p>{i18n.t('notifications.checkInProgress')}</p>
                        )}{' '}
                        {/* Show message while running */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
        <TabsContent value="unread">
          {newNotifications.length > 0 ? (
            <NotificationsTable
              notifications={newNotifications}
              setNotifications={setNotifications}
            />
          ) : (
            <p className="text-center">
              {i18n.t('notifications.noNewNotificationsFound')}
            </p>
          )}
        </TabsContent>

        <TabsContent value="ready">
          {doneNotifications.length > 0 ? (
            <NotificationsTable
              notifications={doneNotifications}
              setNotifications={setNotifications}
            />
          ) : (
            <p className="text-center">
              {i18n.t('notifications.noDoneNotificationsFound')}
            </p>
          )}
        </TabsContent>

        {/* Notification Settings */}
        <TabsContent value="settings">
          {notificationSettings.length > 0 ? (
            notificationSettings.map((setting) => (
              <Card
                key={setting.id}
                className="border border-gray-200 shadow-sm mt-4"
              >
                <CardContent className="p-4 flex flex-wrap gap-2 justify-between">
                  <div>
                    <h3 className="text-lg font-semibold">
                      {setting.item_name}
                    </h3>
                    <p className="text-sm">
                      <strong>{i18n.t('minThresholdAmount')}:</strong>{' '}
                      {setting.min_stock_threshold} {setting.unit}
                    </p>
                    <p className="text-sm">
                      <strong>{i18n.t('category')}:</strong> {setting.category}
                    </p>
                  </div>
                  {currentUser.project_role === UserProjectRoles.ADMIN && (
                    <div>
                      <Link
                        to={
                          i18n.t('paths.projects') +
                          `/${currentProject.id}/` +
                          i18n.t('paths.notifications') +
                          `/${setting.id}/` +
                          i18n.t('paths.editNotificationSetting')
                        }
                      >
                        <Button variant={'secondary'}>
                          <Settings />
                        </Button>
                      </Link>
                    </div>
                  )}
                </CardContent>
              </Card>
            ))
          ) : (
            <p className="text-center">
              {i18n.t('notifications.noNotificationSettingsFound')}
            </p>
          )}
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default ProjectNotificationsView
