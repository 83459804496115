import { Item, Tag } from 'src/lib/types'
import { Badge } from '../ui/badge'
import { X } from 'lucide-react'
import i18n from 'src/i18n'

interface Props {
  item: Item
  tag: Tag
  handleDeleteTagFromItem: (itemId: string, tagId: string) => void
}

const ItemTag = ({ item, tag, handleDeleteTagFromItem }: Props) => {
  return (
    <Badge
      key={tag.id}
      variant={'secondary'}
      className="max-w-fit"
      data-testid={`item-tag-${tag.key}-${tag.value}`}
    >
      {tag.key}
      {tag.value ? `: ${tag.value}` : ''}
      <button
        data-testid={`item-tag-${tag.key}-${tag.value}-delete-button`}
        className="hover:bg-red-300 rounded-md ml-1"
        onClick={() => handleDeleteTagFromItem(item.id, tag.id)}
        title={i18n.t('deleteTagFromItem')}
      >
        <X />
      </button>
    </Badge>
  )
}

export default ItemTag
