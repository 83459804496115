import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/tags'

const getAllTagsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(`${baseUrl}/projects/${project_id}`)
  return response
}

export default { getAllTagsByProjectId }
