import { useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { Button } from '../ui/button'
import { MapIcon } from 'lucide-react'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import { useSearchParams } from 'react-router-dom'
import itemsService from 'src/services/Items/items'
import { iconAndTextStyling } from 'src/constants'
import MapViewer from '../MapViewer/MapViewer'
import { MapLocation } from 'src/lib/types'

interface Props {
  projectId: string
}

const ShowAllLocationsDialog = ({ projectId }: Props) => {
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') ?? ''
  const category = searchParams.get('category') ?? ''
  const unit = searchParams.get('unit') ?? ''

  const [locations, setLocations] = useState<MapLocation[]>([])

  const fetchData = async () => {
    try {
      const response = await itemsService.getAllLocationsData(
        {
          search,
          category: category !== 'null' ? category : '',
          unit: unit !== 'null' ? unit : '',
        },
        projectId
      )
      if (response.status === 200) {
        setLocations(response.data)
      } else {
        toast.error(i18n.t('errorGeneric'))
      }
    } catch (error) {
      toast.error(i18n.t('errorGeneric'))
    }
  }

  return (
    <Dialog
      onOpenChange={async (open) => {
        if (open) {
          await fetchData()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant={'secondary'} className={iconAndTextStyling}>
          <MapIcon />
          {i18n.t('showAllLocations')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <MapViewer
          locations={locations}
          selectedLocation={null}
          preventSingleLocationSheetOpen
        />
      </DialogContent>
    </Dialog>
  )
}

export default ShowAllLocationsDialog
