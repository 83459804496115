import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'

const baseUrl = '/api/map-locations'

export interface AddNewMapLocationData {
  lat: number
  lng: number
  title?: string
}

const addNewMapLocation = async (
  locationData: AddNewMapLocationData,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/projects/${project_id}/add-new-map-location`,
    locationData
  )
  return response
}

const getAllMapLocationsByProjectId = async (
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/projects/${project_id}/get-all-locations`
  )
  return response
}

const deleteMapLocationById = async (
  map_location_id: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.delete(
    `${baseUrl}/${map_location_id}/projects/${project_id}`
  )
  return response
}

const getMapLocationById = async (
  project_id: string,
  map_location_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/${map_location_id}/projects/${project_id}`
  )
  return response
}

export default {
  addNewMapLocation,
  getAllMapLocationsByProjectId,
  deleteMapLocationById,
  getMapLocationById,
}
